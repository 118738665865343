import _ from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { fetchData, validateForm, intlTel_phone, validatePhone } from "../../components/Helper";

function AccountEdit() {
    const history = useHistory()
    const userId = useParams().user_id
    const accountType = useParams().account_type
    const [accountData, setAccountData] = useState()
    const [modules, setModules] = useState()
    const [users, setUsers] = useState()
    const [module_ids, setModule_ids] = useState([])
    const [image, setImage] = useState();
    const [profile, setProfile] = useState()
    const [intlTel, setIntlTel] = useState()

    useEffect(() => {
        fetchData(`get-profile/${userId}`, 'GET', '', true, false, (res) => {
            setProfile(res.data)
            setAccountData({
                user_id: res.data.id,
                name: res.data.name,
                email: res.data.email,
                country: res.data.user_details.country,
                rating: res.data.user_details.rating,
                experience: res.data.user_details.experience,
                description: res.data.user_details.brief,
                status: res.data.status,
                appearance: res.data.user_details.appearance,
                user_type: accountType, 
            }) 
            setImage(res.data.image)

            setIntlTel(intlTel_phone('#phone', '#country', res.data.user_details.country))
        }, (err) => {});

        if(accountType === 'admin'){
            fetchData('get-modules-with-permission', 'post', {'user_id': userId}, true, false, (res) => {
                if(res.status && res.data){
                    setModules('')
                    setModules(res.data)
                    let module_ids = []
                    res.data.modules.forEach(module => {
                        if(module.checked){
                            module_ids.push(module.id)
                        }
                    })

                    setModule_ids(module_ids)
                }
            }, (err) => {})
        }

        if(accountType === 'student' || accountType === 'assistant'){
            let role_id = accountType === 'student' ? 5 : 4
            fetchData(`get-users/${role_id}`, 'GET', '', true, false, (res) => {
                if(res.status && res.data){
                    setUsers(res.data)
                }
            }, (err) => {})
        }
    }, [userId, accountType])

    const handleInputChange = (e) => {
        setAccountData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            module_ids.push(+e.target.value)
        } else {
            module_ids.splice(module_ids.indexOf(+e.target.value), 1)
        }

        setModule_ids(module_ids)
    }

    const saveData = (e, status) => {
        if(validateForm(e) && validatePhone(intlTel, '#phone')){
            let account = {...accountData, 'status':status}
            if(accountType === 'admin'){
                account = {...account, 'modules':module_ids}
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(account)) {
                formData.append(key, value)
            }
            formData.append('image', image)
            formData.append('phone', intlTel.getNumber())

            fetchData('account-update', 'POST', formData, true, true, (res) => {
                if(res.status){
                    setAccountData({country:'us', user_type: accountType})
                    history.push(`/accounts/${accountType}/list`)
                }
            }, (err) => {})
        }
    }
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | { _.upperFirst(accountType) } Account Edit</title>
        </Helmet>
        { profile ? 
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2">
                        <h5 className="m-0 heading-lines fw-medium pb-2">{ _.upperFirst(accountType) } Account Edit</h5>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/accounts/${profile.role.slug}/list`} className="btn btn-blue mb-1" title={`${ _.upperFirst(accountType) } List`}>{ _.upperFirst(accountType) } List</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                            <form action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    { accountType === 'student' || accountType === 'assistant' ? 
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="adminname" className="col-sm-3 col-xxl-2 col-form-label">{ accountType === 'student' ? 'Parent Link' : 'Link To Teacher' } <sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <select className="form-select" name="link_id" onChange={handleInputChange} required>
                                                <option value="">{ accountType === 'student' ? 'Please select a parent' : 'Please select a teacher' }</option>
                                                { users ? (
                                                    users.map((user, i) => {
                                                        return <option key={i} value={user.id} selected={profile.associated_with === user.id ? true : false}>{`${user.name} <${user.email}>`}</option>
                                                    })
                                                ) : ''}
                                            </select>
                                        </div>
                                    </div>
                                    : '' }
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="name" className="col-sm-3 col-xxl-2 col-form-label">Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="text" className="form-control" id="name" name="name" onChange={handleInputChange} required defaultValue={profile ? profile.name : ''}/>
                                        </div>
                                    </div>
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="email" className="col-sm-3 col-xxl-2 col-form-label">Email<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="text" className="form-control" id="email" name="email" readOnly={ profile && profile.status === 'draft' ? false : true } defaultValue={profile ? profile.email : ''} required onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="country" className="col-sm-3 col-xxl-2 col-form-label">Country<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <select className="form-select" name="country" id="country" onChange={handleInputChange} required>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="phone" className="col-sm-3 col-xxl-2 col-form-label">Whatsapp<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5 form-flag-height38">
                                            <input type="tel" className="form-control" id="phone" autoComplete="off" name="phone" data-intl-tel-input-id="0" required defaultValue={profile ? '+' + profile.user_details.phone : ''}/>
                                        </div>
                                    </div>
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="password" className="col-sm-3 col-xxl-2 col-form-label">Password</label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="password" className="form-control" id="password" name="password" onChange={handleInputChange}/>
                                        </div>
                                    </div>
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="confirm_password" className="col-sm-3 col-xxl-2 col-form-label">Confirm Password</label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="password" className="form-control" id="confirm_password" password="confirm_password" name="confirm_password" onChange={handleInputChange}/>
                                            <span className="text-success fs-12px">Leave password field blank, if you do not want to change.</span>
                                        </div>
                                    </div>
                                     
                                    { accountType === 'teacher' ? 
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="email" className="col-sm-3 col-xxl-2 col-form-label">Experience<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="number" step="0.25" className="form-control" min="0" id="experience" name="experience" onChange={handleInputChange}  defaultValue={profile ? profile.user_details.experience : ''}/>
                                            </div>
                                        </div>
                                    : ''}
                                    { accountType === 'teacher' ? 
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="email" className="col-sm-3 col-xxl-2 col-form-label">Rating<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="number" step='0.01' className="form-control" id="rating" name="rating" onChange={handleInputChange}  defaultValue={profile ? profile.user_details.rating : ''}/>
                                            </div>
                                        </div>
                                    :''}

                                    { accountType === 'teacher' ? 
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="email" className="col-sm-3 col-xxl-2 col-form-label">Brief<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <textarea className="form-control" id="description" name="description" onChange={handleInputChange}  defaultValue={profile ? profile.user_details.brief : ''}></textarea>
                                        </div>
                                    </div>
                                    : '' } 
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="email" className="col-sm-3 col-xxl-2 col-form-label">Upload Image</label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="file" className="form-control" id="image" name="image" onChange={(e) => setImage(e.target.files[0])} accept="image/png, image/jpeg, image/jpg" />
                                        </div>
                                    </div>

                                    { accountType === 'teacher' ? 
                                    <div className="mb-4 row mx-0">
                                            <label htmlFor="frontend" className="col-sm-3 col-xxl-2 col-form-label">Appearance on front end<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="form-check form-check-inline mt-2">
                                                    <input className="form-check-input" type="radio" name="appearance" value="yes" id="yes" checked={accountData && accountData.appearance === "yes" ? true : false} onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="appearance" value="no" id="no" checked={accountData && accountData.appearance === "no" ? true : false} onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="no">
                                                        No
                                                    </label>
                                                </div>  
                                            </div>                                          
                                    </div>
                                    : '' }
                                    {profile && profile.status !== 'draft' ?
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="status" className="col-sm-3 col-xxl-2 col-form-label">Status<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <select className="form-select" name="status" id="status" onChange={handleInputChange}>
                                                <option value="active" selected={profile && profile.status === 'active' ? true : false}>Active</option>
                                                <option value="inactive" selected={profile && profile.status === 'inactive' ? true : false}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    : '' }
                                    { accountType === 'admin' ? 
                                    <div className="mb-4 row mx-0">
                                        <label htmlFor="adminroles" className="col-sm-3 col-xxl-2 col-form-label">Permissions</label>
                                        <div className="col-sm-9">
                                            <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4">
                                                { modules ? modules.modules.map((module, i) => (
                                                    <div className="col pt-2" key={i}>
                                                        <label className="form-check">
                                                            <input className="form-check-input" name="modules" type="checkbox" value={ module.id } onChange={handleCheckboxChange} defaultChecked={ module.checked }/>
                                                            <div className="form-check-label">
                                                                { module.name }
                                                            </div>
                                                        </label>
                                                    </div>
                                                )) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    : '' }

                                    <div className="row mx-0">
                                        <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                            { profile && profile.status === 'draft' ? <>
                                            <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={(e) => saveData(e, 'active')} title="Publish">Publish</button>
                                            <button type="button" className="btn btn-outline-success" onClick={(e) => saveData(e, 'draft')} title="Save Draft">Save Draft</button>
                                            </> : <>
                                            <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={(e) => saveData(e, accountData.status)} title="Update">Update</button>
                                            </> }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        : '' }
        </>
    )
}

export default AccountEdit
