import ReactDOM from 'react-dom';
import { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router"
import { fetchData, validateForm, initialFormState } from "../../components/Helper"
import Datatables, { reloadUrlDataTable, reloadDataTable } from '../../components/Datatables';
import DateRangeFilter from '../Filter';
import { times } from 'lodash';

function  Recordings(props) {
    const history = useHistory()
    const courseId = useParams().course_id
    const [listName, setListName] = useState('Draft')
    const [classList, setClassList] = useState()
    const [opPerform, setOpPerform] = useState('add')
    const [disableField, setDisableField] = useState(false)
    const [recordingData, setRecordingData] = useState({
        'recording_name': '',
        'class_id': '',
    });
    const [recording, setRecording] = useState();
    const [class_id, setClass_id] = useState();
    const [recording_name, setRecording_name] = useState();

    const initialState = (type) => {
        if(type === 'recording') {
            initialFormState("recordingForm", recordingData)
            setOpPerform('add')
            setDisableField(false)
            setRecording('')
        }
    }

    const [recording_dt] = useState({
        dt_url: `recording/${courseId}`,
        dt_name: 'recording',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"classId", name:`course_classes.class_id`, title:"Class ID"},
            { data:"name", name:`name`, class:"text-nowrap", title:"Recording Name"},
            { data:"recording", name:`recording`, class:"text-break", title:"Recording"},
            { data:"status", name:'status',  title:"Status" },
            { data:"created_at", name:'created_at',  title:"Recording Created Date", class:"text-nowrap" },
            { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    { rowData.status === "draft" ? <span className="text-primary fw-medium">Draft</span> : '' }
                    </>, td)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>

                        { rowData.status === 'inactive' ? 
                        <button type="button" className="btn btn-success py-2px m-2px w-100px" title="Activate" onClick={ () => handleChangeStatus('active', rowData.id, 'recording')}>
                                Activate
                        </button>
                        : '' }

                        { rowData.status === 'active' ?
                        <button type="button" className="btn btn-danger py-2px m-2px w-100px" title="Deactivate" onClick={ () => handleChangeStatus('inactive', rowData.id, 'recording')}>
                            Deactivate
                        </button>
                        : '' }

                        { rowData.status !== 'draft' ?
                        <button type="button" className="btn btn-primary py-2px m-2px" title="View" data-bs-toggle="modal" data-bs-target="#addrecordingModal" onClick={() => openEditClassModal(rowData.id, true, 'recording')}>
                            View
                        </button>
                        : '' }

                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" data-bs-toggle="modal" data-bs-target="#addrecordingModal" onClick={() => openEditClassModal(rowData.id, false, 'recording')}>
                            Edit
                        </button>

                        { rowData.status === 'draft' ?
                        <button type="button" className="btn btn-danger py-2px m-2px" title="Delete" onClick={ () => handleChangeStatus('delete', rowData.id, 'recording')}>
                            Delete
                        </button>
                        : '' }
                        
                                                    
                    </>, td)
                },
            },

        ],

        dt_paging: true,
        dt_createdRow: '',
        dt_order: [[ 5, 'desc' ]],
        dt_filter: () => {
            ReactDOM.render(
                <>
                    <div className="d-inline-block align-top me-2">
                        <DateRangeFilter daterange="All" name="r_daterange" maxDate={new Date()} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getDateRange} key={times()}/>
                    </div>
                </>, document.querySelector('#wt_datatable_recording_wrapper .dt-custom-filter')
            )
        }
    })

    const getDateRange = (startDate, endDate) => {
        reloadUrlDataTable(recording_dt, `recording/${courseId}?startDate=${startDate}&endDate=${endDate}`)
    }

    const getClassData = (status) => {
        if(listName === 'Draft') {
            setListName('List')
            let status_q = `status_q=${status}`
            reloadUrlDataTable(recording_dt, `recording/${courseId}?${status_q}`)
        }else {
            setListName('Draft')
            reloadUrlDataTable(recording_dt, `recording/${courseId}`)
        }
    }

    const handleChangeStatus = (status, id, type = false) => {
        if(type === 'recording') {
            fetchData(`recording-change-status`, 'POST', {'id': id, 'status': status, 'course_id': courseId, 'pathname': history.location.pathname}, true, false, (res) => {
                reloadDataTable(recording_dt)
            }, (err) => {})
        }

    }

    const saveData = (status, modal_type) => {
        if(modal_type === 'recording') {
            const formData = new FormData();
            formData.append('recording', recording)
            formData.append('status', status)
            formData.append('course_id', courseId)
            formData.append('recording_name', recording_name)
            formData.append('classId', class_id)
            formData.append('pathname', history.location.pathname)
                fetchData('add-recording', 'POST', formData, true, true, (res) => {
                    if(res.status){
                        initialState('recording')
                        document.getElementById('recordingModal').click()
                        reloadUrlDataTable(recording_dt, `recording/${courseId}`)
                    }
            }, (err) => {})
        }
    }

    const updateData = (status, modal_type) => {
        if(modal_type === 'recording') {
            const formData = new FormData();
            formData.append('recording', recording)
            formData.append('status', status)
            formData.append('recording_name', recording_name)
            formData.append('classId', class_id)
            formData.append('pathname', history.location.pathname)
            fetchData(`recording-update/${recordingData.id}`, 'POST', formData, true, true, (res) => {
                if(res.status){
                    document.getElementById('recordingModal').click()
                    reloadUrlDataTable(recording_dt, `recording/${courseId}`)
                }
        }, (err) => {})
        }
        
    }

    const openEditClassModal = (id, set, type='') => {
        if(type === 'recording'){
            initialState('recording')
            openFormModal('recording')
            setDisableField(set)
            fetchData(`recording-update/${id}`, 'GET', '', true, false, (res) => { 
                if(res.status){
                    setRecordingData({
                        id: res.data.id,
                        class_id: res.data.class_id,
                        recording_name: res.data.name,
                    })
                }
                setClass_id(res.data.class_id)
                setOpPerform(res.data.status)
                setRecording(res.data.recording)
                setRecording_name(res.data.name)
            }, (err) => {})
        }
    }  

    const handelRecordingSubmitForm = (e) => {
        if(validateForm(e, 'recordingForm')){
            if(opPerform !== 'add') {
                updateData(' ', 'recording')
            }else {
                saveData('active', 'recording')
            }
        }
    }

    const handelRecordingDraftSubmitForm = (e) => {
        if(validateForm(e, 'recordingForm')){
            if(opPerform !== 'add') {
                updateData('draft', 'recording')
            }else {
                saveData('draft', 'recording')
            }
        }
    }
    const handelRecordingPublishSubmitForm = (e) => {
        if(validateForm(e, 'recordingForm')) { 
            updateData('active', 'recording') 
        }
    }

    const openFormModal = (modal_name) => {
        initialState(modal_name)
        if(modal_name === 'recording') {
            fetchData(`class-list/${courseId}`, 'GET', '', true, false, (res) => { 
                if(res.status){
                    setClassList(res.data)
                }
            }, (err) => {})
        }
    }

    useEffect(() => {
        // if(props.loadComponent === 'recording'){
            reloadDataTable(recording_dt)
        // }
    }, [recording_dt])

    return(
        <>
            <style>
                {`
                .tb-60px-200px-200px-300px table thead tr th:nth-child(1){
                    width: 60px!important;
                }
                .tb-60px-200px-200px-300px table thead tr th:nth-child(2){
                    width: 200px!important;
                }
                .tb-60px-200px-200px-300px table thead tr th:nth-child(3){
                    width: 200px!important;
                }
                .tb-60px-200px-200px-300px table thead tr th:nth-child(4){
                    width: 300px!important;
                }
                `}
            </style>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                <div className="text-orange mb-1"></div>
                <div className="">
                    <button className="btn btn-success mb-1 me-2" data-bs-toggle="modal" data-bs-target="#addrecordingModal" onClick={() => openFormModal('recording')}>Add Recording</button>
                    <button className="btn btn-warning px-3 mb-1" onClick={() => getClassData('draft')}>Recording {listName}</button>
                </div>
            </div>
            <div className="table-responsive p-1 tb-60px-200px-200px-300px">
                <Datatables dt_name="recording"/>
            </div> 

            <div className="modal fade" id="addrecordingModal" tabIndex="-1" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">{opPerform === 'add' ? "Add" : disableField ? "Show" : "Update"} Recording</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id='recordingModal' aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <form action="#" method="post" className="needs-validation" id="recordingForm" noValidate>
                                <div className="row mx-0 mb-4">
                                    <label htmlFor="class_id" className="col-sm-4 col-form-label">Reference Class<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <select className="form-select" name="classId" id="classId" value={class_id} onChange={(e) => setClass_id(e.target.value)} disabled={disableField}  required>
                                            <option value=''>--select--</option>
                                            {
                                                classList ? classList.map((list, key) => {
                                                    return (
                                                        <option key={key} value={list.id}>{list.class_id}</option>
                                                    )
                                                }) : ''
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-4">
                                    <label htmlFor="recording_name" className="col-sm-4 col-form-label">Recording Name<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="text" className="form-control" id="recording_name" name="recording_name" defaultValue={recordingData.recording_name}   onChange={(e) => setRecording_name(e.target.value)} disabled={disableField} required />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-4">
                                    <label htmlFor="zoom_link" className="col-sm-4 col-form-label">Recording Link<sup className='text-danger fw-bold fs-15px'>{disableField ? '' : '*'}</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="url" className="form-control" autoComplete="off" id="recording" name="recording" defaultValue={recording} onChange={(e) => setRecording(e.target.value)} readOnly={disableField} required />
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-sm-8 offset-sm-4">
                                        { disableField ? '' : (
                                            opPerform === 'draft' ? <>
                                            <button type="button" className="btn btn-blue w-120px px-4 me-2"  onClick={handelRecordingPublishSubmitForm}>Publish</button>
                                            </> :  <>
                                            <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={handelRecordingSubmitForm}>{opPerform === 'add' ? "Add" : "Update"}</button>
                                            </> 
                                        )
                                        }
                                        { disableField ? '' : (
                                            opPerform === 'draft' || opPerform === 'add' ? <>
                                            <button type="button" className="btn btn-outline-success" onClick={handelRecordingDraftSubmitForm}>Save Draft</button>
                                            </> : ''
                                        )
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recordings