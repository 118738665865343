import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react"
import Datatables, { reloadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { useParams, useHistory } from 'react-router';
import { fetchData, validateForm, initialFormState } from '../../components/Helper';
import $ from 'jquery';
import { Helmet } from 'react-helmet';

function TeacherCourses() {
    const history = useHistory()
    const teacherId = useParams().user_id
    const [courseId, setCourseId] = useState(0)
    const [percentage, setPercentage] = useState({
        class_platform_percent: '',
        class_teacher_percent: '',
        course_platform_percent: '',
        course_teacher_percent: '',
    })
    
    const [enroll_student_dt] = useState({
        dt_url: `teacher-course?teacher_id=${teacherId}&tab=enrolled_student`,
        dt_name: 'teacher_course',
        
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title: "Course Title"},
            { data:"status", name:'status', title: "Status"},
            { data:"created_at", name:'created_at', title: "Course Created Date"},
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    { rowData.status === "draft" ? <span className="text-primary fw-medium">Draft</span> : '' }
                    </>, td)
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        { rowData.tab === 'enrolled_student' ? 
                            <button type="button" className="btn btn-primary py-2px m-2px" title="Students" data-bs-toggle="modal" data-bs-target="#enrolledstudent_modal" onClick={() => showModal(rowData.id, 'student')}>
                                Students
                            </button>
                        : ''}
                        { rowData.tab === 'activity' ? 
                            <button type="button" className="btn btn-success py-2px m-2px" title="View" data-bs-toggle="modal" data-bs-target="#activityview_modal" onClick={() => showModal(rowData.id, 'activity')}>
                                View
                            </button>
                        : ''}
                        { rowData.tab === 'payments' ? <>
                            <button type="button" className="btn btn-dark py-2px m-2px" title="Transactions" data-bs-toggle="modal" data-bs-target="#transactions_modal" onClick={() => showModal(rowData.id, 'transaction')}>
                                Transactions
                            </button>
                            <button type="button" className="btn btn-success py-2px m-2px" title="Set Percentage" data-bs-toggle="modal" data-bs-target="#setpercentage_modal" onClick={() => showModal(rowData.id, 'percentage')}>
                                Set Percentage
                            </button>
                        </> : ''}

                    </>, td)
                },
            },

        ],

        dt_paging: true,
        dt_createdRow: ''
    })
    
    
    
 
    useEffect(() => {
        reloadDataTable(enroll_student_dt)

        document.querySelectorAll('button[data-bs-toggle="tab"]').forEach((e)=> {
            e.addEventListener('show.bs.tab', function (event) {
                reloadUrlDataTable(enroll_student_dt, `teacher-course?teacher_id=${teacherId}&tab=${event.target.dataset.tablename}`)
            })
        })
    }, [enroll_student_dt, teacherId])

    const showModal = (id, btn) => {
        if(btn === 'activity') {
            let course_details_dt = {
                dt_url: `activity-detail-list/${id}?type=course`,
                dt_name: 'activity-log_course_details',
                
                dt_column: [
                    { data:"id", name:'id', title: "#Action Id"},
                    { data:"name", name:'users.name', title: "Name"},
                    { data:"email", name:'users.email', title: "Email"},
                    { data:"role", name:'roles.name', title: "User Type"},
                    { data:"message", name:'message', title: "Action Done", class:"mmw-200px"},
                    { data:"created_at", name:'created_at', title: "Time of Action"},
                ],
        
                dt_paging: true,
                dt_createdRow: ''
            }
            reloadUrlDataTable(course_details_dt, `activity-detail-list/${id}?type=course`)
        }else if(btn === 'transaction') {
            let  transaction_details_dt = {
                dt_url: `payments?category=${id}`,
                dt_name: 'transaction_details',
                
                dt_column: [
                    { data:"date", name:'payment_date', title: "Date/Time"},
                    { data:"transaction_id", name:'transaction_id', title: "Trans ID", class: 'text-nowrap'},
                    { data:"name", name:'users.name', title: "Student Name", class: 'text-nowrap'},
                    { data:"payment_type", name:'payment_type', title: "Trans Type", class: 'text-capitalize text-nowrap'},
                    { data:"payment_method", name:'payment_method', title: "Method", class: 'text-capitalize'},
                    { data:"join_type", name:'student_courses.join_type', title: "Join As", class: 'text-capitalize text-nowrap'},
                    { data:"amount", name:'amount', title: "Amount (EGP)", class: 'text-nowrap'},
                    { data:"p_amount", name:'p_amount', title: "Platform(%)"},
                    { data:"t_amount", name:'t_amount', title: "Teacher(%)"},
                    { data:"description", name:'description', title: "Description", class:"mmw-200px"},
                ]
            }
            reloadUrlDataTable(transaction_details_dt, `payments?category=${id}`)
        }else if (btn === 'percentage') {
            initialFormState("percentForm", percentage)
            setCourseId(id)
            fetchData(`set-percent/${id}`, 'get', '', true, false, (res) => {
                if(res.status){
                    setPercentage({
                        class_platform_percent: res.data.class_platform_percent,
                        class_teacher_percent: res.data.class_teacher_percent,
                        course_platform_percent: res.data.course_platform_percent,
                        course_teacher_percent: res.data.course_teacher_percent,
                    })
                }
            }, (err) => {})
        }else if(btn === 'student') {
            let enroll_course_student_dt = {
                dt_url: `get-enroll-student-of-course/${id}`,
                dt_name: 'enroll_course_student',
                
                dt_column: [
                    { data:"id", name:'users.id', title: "#ID"},
                    { data:"name", name:'users.name', title: "Student Name"},
                    { data:"email", name:'users.email', title: "Student Email"},
                    { data:"join_type", name:'student_courses.join_type', title: "Join As"},
                    { data:"created_at", name:'student_courses.created_at', title: "Joined Date"},
                    { data:"withdraw_date", name:'student_courses.withdraw_date', title: "Withdrawal Date"},
                ],
        
                dt_paging: true,
                dt_createdRow: ''
            }
            reloadUrlDataTable(enroll_course_student_dt, `get-enroll-student-of-course/${id}`)
        }
    }  
    const handleInputChange = (e) => {
        document.getElementById(e.target.name).classList.remove('is-invalid');
        setPercentage(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmitForm = (e) => {
        if(validateForm(e)) {
            let formData = {...percentage, 'pathname': history.location.pathname}
            fetchData(`set-percent/${courseId}`, 'POST', formData, true, false, (res) => {
                if(res.status){
                    $("#setpercentage_modal").modal('hide'); 
                    reloadDataTable(enroll_student_dt)
                }
            }, (err) => {})
        }
    }
    
    return (
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Taught Courses</title>
            </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Taught Courses</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to={`/accounts/teacher/list`} className="btn btn-blue mb-1 me-1" title='Teacher List'>Teacher List</Link>
                            <Link to={`/accounts/teacher/profile/${teacherId}`} className="btn btn-orange px-3 mb-1" title='Profile'>Profile</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue active fw-medium" id="home-tab" data-bs-toggle="tab" data-bs-target="#enrolled_student" type="button" role="tab" aria-controls="home" aria-selected="true" data-tablename="enrolled_student" title='Enrolled Student'>Enrolled Student</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" id="profile-tab" data-bs-toggle="tab" data-bs-target="#payments" type="button" role="tab" aria-controls="profile" aria-selected="false" data-tablename="payments" title='Payments'>Payments</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" id="contact-tab" data-bs-toggle="tab" data-bs-target="#activity" type="button" role="tab" aria-controls="contact" aria-selected="false" data-tablename="activity" title='Activity'>Activity</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border border-top-0">
                                        <div className="tab-pane fade p-3 show active" role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="teacher_course"/> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="enrolledstudent_modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title fw-bold">Enrolled Student</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive p-1">
                                <Datatables dt_name="enroll_course_student"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="transactions_modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title fw-bold">Transactions</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive p-1">
                                <Datatables dt_name="transaction_details"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="setpercentage_modal" tabIndex="-1" data-bs-backdrop="static" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title fw-bold">Set Percentage</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body pt-md-4">
                            <form action="#" method="post" id="percentForm" className="needs-validation" noValidate>
                                <div className="row g-md-5 mx-md-0">
                                    <div className="col-6 border-end">
                                        <h5 className="mb-3 fw-medium">Course</h5>
                                        <label className="mb-3">
                                            <div className="d-block">Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></div>
                                            <div className="input-group">
                                                <input type="text" maxLength="3" className="form-control" defaultValue={percentage.course_teacher_percent} id="course_teacher_percent" name="course_teacher_percent" onChange={handleInputChange} placeholder="" required />
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </label>
                                        <label className="mb-1">
                                            <div className="d-block">Platform<sup className='text-danger fw-bold fs-15px'>*</sup></div>
                                            <div className="input-group">
                                                <input type="text" maxLength="3" className="form-control" defaultValue={percentage.course_platform_percent} id="course_platform_percent" name="course_platform_percent" onChange={handleInputChange} placeholder="" required />
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <h5 className="mb-3 fw-medium">Class</h5>
                                        <label className="mb-3">
                                            <div className="d-block">Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></div>
                                            <div className="input-group">
                                                <input type="text" maxLength="3" className="form-control" defaultValue={percentage.class_teacher_percent} id="class_teacher_percent" name="class_teacher_percent" onChange={handleInputChange} placeholder="" required />
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </label>
                                        <label className="mb-1">
                                            <div className="d-block">Platform<sup className='text-danger fw-bold fs-15px'>*</sup></div>
                                            <div className="input-group">
                                                <input type="text" maxLength="3" className="form-control" defaultValue={percentage.class_platform_percent} id="class_platform_percent" name="class_platform_percent" onChange={handleInputChange} placeholder="" required />
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-12 mt-3 py-3 text-end">
                                        <button type="button" className="btn btn-light border mmw-120px me-1" data-bs-dismiss="modal" title='Cancel'>Cancel</button>
                                        <button type="button" className="btn btn-success mmw-120px" onClick={handleSubmitForm}>Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="activityview_modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title fw-bold">Activity View</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive p-1">
                                <Datatables dt_name="activity-log_course_details" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TeacherCourses
