import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { fetchData, validateForm } from '../components/Helper'

const ForgotPassword = () => {
        const [email, setEmail] = useState({})
        const history = useHistory();
        const sendData = (e) => {
            e.preventDefault()
            if(validateForm(e)){ 
                fetchData('forgot-password', 'POST', {email:email}, false, false, (res) => {
                    if(res.status){
                        setEmail({});
                        history.push('/login');
                    }
                }, (err) => {})
        
            }
        }
    return (
        <section className="login-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Forgot Password</title>
            </Helmet>
            <div className="container">
                <div className="row g-0 rounded-10 shadow overflow-hidden">
                    <div className="col-md-6 col-lg-5 bg-white d-flex align-items-center">
                        <form action="#" method="post" className="p-4 p-sm-5 w-100 needs-validation" noValidate>
                            <h4 className="heading-lines mb-4 mt-0">Forgot Password</h4>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="email" name="email" onChange={(e) =>setEmail(e.target.value)} placeholder="name@example.com" required/>
                                <label htmlFor="floatingInput">Email address</label>
                            </div>
                            <div className="mb-4">
                                <button type="submit" onClick={sendData} className="btn btn-blue btn-lg w-100" title='SUBMIT'>SUBMIT</button>
                            </div>
                            <div className="d-flex justify-content-between pb-3">
                                <Link to={`/login`} className="anchor-blueorange fw-medium" title='Login'>Login</Link>
                                <Link to={`/sign-up`} className="anchor-blueorange fw-medium" title='Sign Up'>Sign Up</Link>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-lg-7">
                        <img src="images/login.svg" className="w-100" alt="weteach"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword
