import { Link} from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchData, validateForm } from "../../components/Helper";
import { useHistory } from "react-router";
import _ from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";

function CourseCreate(){

    const history = useHistory()
    
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [classCheck, setClassCheck] = useState(true)
    const [courseCheck, setCourseCheck] = useState(false)

    const [teacher, setTeacher] = useState([])
    const [system, setSystem] = useState([])
    const [level, setLevel] = useState([])
    const [board, setBoard] = useState([])
    const [subject, setSubject] = useState([])
    const [image, setImage] = useState()
    const [data, setData] = useState({
        name: '',
        teacher: '',
        description: '',
        system: '',
        level: '',
        subject: '',
        board: '',
        appearance_front_end: 'no',
        class_price: '',
        course_price: '',
        features: 'no',
        pathname: history.location.pathname

    })


    useEffect(() => {
        fetchData(`get-users/4`, 'GET', '', true, false, (res) => {
            setTeacher(res.data)
        }, (err) => {})
        fetchData(`educational-classification-list/system`, 'GET', '', true, false, (res) => {
            setSystem(res.data)
        }, (err) => {})
        fetchData(`educational-classification-list/level`, 'GET', '', true, false, (res) => {
            setLevel(res.data)
        }, (err) => {})
        fetchData(`educational-classification-list/board`, 'GET', '', true, false, (res) => {
            setBoard(res.data)
        }, (err) => {})
        fetchData(`educational-classification-list/subject`, 'GET', '', true, false, (res) => {
            setSubject(res.data)
        }, (err) => {})

    }, [])

    const handleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    const handelSubmitForm = (e) => {
          if(validateForm(e)){
            saveData('active')
          }
    }
    const handelDraftSubmitForm = (e) => {
          if(validateForm(e)){
            saveData('draft')
          }
    }
    const saveData = (status) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value)
          }
        formData.append('poster', image)
        formData.append('status', status)
        formData.append('class', classCheck)
        formData.append('course', courseCheck)
        formData.append('payment_date', paymentDate.toISOString())

        fetchData('course-create', 'POST', formData, true, true, (res) => {
            if(res.status){
                history.push(`/course/list`)
            }
        }, (err) => {})
    }


    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Create Course</title>
            </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Create Course</h5>
                        </div>
                        <div className="col-auto mb-2">
                        <Link to={`/course/list`} className="btn btn-blue mb-1 me-2" title="Course List">Course List</Link>
                        <Link to={`/course/list/draft`} className="btn btn-warning px-3 mb-1" title="Draft Course">Draft Course</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body p-sm-4 p-xl-5">
                                    <form action="#" method="post" className="needs-validation" noValidate>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="name" className="col-sm-3 col-xxl-2 col-form-label">Course Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="name" defaultValue="" name="name" onChange={handleInputChange} required/>
                                            </div>
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="poster" className="col-sm-3 col-xxl-2 col-form-label">Course Poster<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="file" className="form-control" id="poster" name="poster" defaultValue="" onChange={(e) => setImage(e.target.files[0])} accept="image/png, image/jpeg, image/jpg" required/>
                                            </div>
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="teacher" className="col-sm-3 col-xxl-2 col-form-label">Assign to Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="teacher" id="teacher" onChange={handleInputChange} required>
                                                    <option value="">- select -</option>
                                                    {
                                                        _.isEmpty(teacher) ? '' : 
                                                        teacher.map((teacher_detail) => {
                                                            return (<option key={teacher_detail.id} value={teacher_detail.id}>{teacher_detail.name}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="description" className="col-sm-3 col-xxl-2 col-form-label">Description<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <textarea rows="4" className="form-control" id="description" name="description" onChange={handleInputChange} required></textarea>
                                            </div>
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="pricing" className="col-sm-3 col-xxl-2 col-form-label">Pricing</label>
                                            <div className="col-sm-9 col-xxl-10">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <label className="form-check minw-120px me-3">
                                                        <input className="form-check-input" type="checkbox" defaultChecked defaultValue="" onClick={() => setClassCheck(!classCheck)}/>
                                                        <div className="form-check-label" htmlFor="class">
                                                            Class Price{classCheck ? <sup className='text-danger fw-bold fs-15px'>*</sup> : ''}
                                                        </div>
                                                    </label>
                                                    <input type="number" min="0" className="form-control w-auto" placeholder="Class Price" id="class_price" name="class_price" onChange={handleInputChange} required={classCheck} disabled={!classCheck}/>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center mt-2">
                                                    <label className="form-check minw-120px me-3">
                                                        <input className="form-check-input" type="checkbox" defaultValue=""  onClick={() => setCourseCheck(!courseCheck)}/>
                                                        <div className="form-check-label" htmlFor="course">
                                                            Course Price{courseCheck ? <sup className='text-danger fw-bold fs-15px'>*</sup> : ''}
                                                        </div>
                                                    </label>
                                                    <div className="d-flex flex-wrap">
                                                        <input type="number" min="0" className="form-control w-auto my-2px me-2" placeholder="Course Price" id="course_price" name="course_price" onChange={handleInputChange} required={courseCheck} disabled={!courseCheck}/>
                                                        <div className="my-2px">
                                                            <DatePicker 
                                                                selected={paymentDate} 
                                                                onChange={(date) => setPaymentDate(date)} 
                                                                className="form-control"
                                                                minDate={new Date()}
                                                                timeInputLabel="Time:"
                                                                dateFormat="MMM dd, yyyy h:mm aa"
                                                                showTimeInput
                                                                onChangeRaw={(e) => e.preventDefault()}
                                                                disabled={!courseCheck}
                                                            />
                                                            <div className="position-absolute fs-13px b-0 text-orange">Due of 2nd payment date.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="system" className="col-sm-3 col-xxl-2 col-form-label">System<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <select className="form-select" name="system" id="system" onChange={handleInputChange} required>
                                                <option value=''>- select -</option>
                                                {
                                                    _.isEmpty(system) ? '' : 
                                                    system.map((system_detail) => {
                                                        return (<option key={system_detail.id} value={system_detail.id}>{system_detail.name}</option>)
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="level" className="col-sm-3 col-xxl-2 col-form-label">Level<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <select className="form-select" name="level" id="level" onChange={handleInputChange} required>
                                                <option value="">- select -</option>
                                                {
                                                    _.isEmpty(level) ? '' : 
                                                    level.map((level_detail) => {
                                                        return (<option key={level_detail.id} value={level_detail.id}>{level_detail.name}</option>)
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="subject" className="col-sm-3 col-xxl-2 col-form-label">Subject<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="subject" id="subject" onChange={handleInputChange} required>
                                                    <option value="">- select -</option>
                                                    {
                                                        _.isEmpty(subject) ? '' : 
                                                        subject.map((subject_detail) => {
                                                            return (<option key={subject_detail.id} value={subject_detail.id}>{subject_detail.name}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="board" className="col-sm-3 col-xxl-2 col-form-label">Examination Board<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="board" id="board" onChange={handleInputChange} required>
                                                    <option value=''>- select -</option>
                                                    {
                                                        _.isEmpty(board) ? '' : 
                                                        board.map((board_detail) => {
                                                            return (<option key={board_detail.id} value={board_detail.id}>{board_detail.name}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="frontend" className="col-sm-3 col-xxl-2 col-form-label">Appearance on front end<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="form-check form-check-inline mt-2">
                                                    <input className="form-check-input" type="radio" name="appearance_front_end" value="yes" id="yes" onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="appearance_front_end" value="no" id="no" defaultChecked onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="no">
                                                        No
                                                    </label>
                                                </div>  
                                            </div>                                          
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="features" className="col-sm-3 col-xxl-2 col-form-label">Features<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="form-check form-check-inline mt-2">
                                                    <input className="form-check-input" type="radio" name="features" value="yes" id="yes" onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="features" value="no" id="no" defaultChecked onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="no">
                                                        No
                                                    </label>
                                                </div>  
                                            </div>                                          
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                                <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={handelSubmitForm} title="Create">Create</button>
                                                <button type="button" className="btn btn-outline-success" onClick={handelDraftSubmitForm} title="Save Draft">Save Draft</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CourseCreate
