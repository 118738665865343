import { Link } from "react-router-dom";
import Settings from '../../components/course/Settings';
import ClassLinks from '../../components/course/ClassLink';
import Attendance from '../../components/course/Attendance';
import Recordings from '../../components/course/Recordings';
import EnrollStudents from '../../components/course/EnrollStudents';
import { useState } from "react";
import { Helmet } from "react-helmet";

function  CourseView() {
    const [loadComponent, setLoadComponent] = useState(false)
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Single Course Details</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Single Course Details</h5>
                        </div>
                        <div className="col-auto mb-2">
                        <Link to={`/course/list`} className="btn btn-blue mb-1 me-1" title="Course List">Course List</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue active fw-medium" data-bs-toggle="tab" data-bs-target="#classlinkBox" type="button" role="tab" title="Class Links">Class Links</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" data-bs-toggle="tab" data-bs-target="#attendanceBox" type="button" role="tab" onClick={() => setLoadComponent('attendance')} title="Attendance">Attendance</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" data-bs-toggle="tab" data-bs-target="#recordingsBox" type="button" role="tab" onClick={() => setLoadComponent('recording')} title="Recordings">Recordings</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" data-bs-toggle="tab" data-bs-target="#enrolledstBox" type="button" role="tab" onClick={() => setLoadComponent('enroll_student')} title="Enrolled Studen">Enrolled Student</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" data-bs-toggle="tab" data-bs-target="#settingstBox" type="button" role="tab" aria-selected="true" onClick={() => setLoadComponent('timing')} title="Setting">Setting</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border p-3 border-top-0">
                                        <div className="tab-pane fade show active" id="classlinkBox" role="tabpanel">
                                            <ClassLinks/>
                                        </div>
                                        <div className="tab-pane fade" id="attendanceBox" role="tabpanel">  
                                            <Attendance loadComponent={loadComponent}/>
                                        </div>
                                        <div className="tab-pane fade" id="recordingsBox" role="tabpanel">
                                            <Recordings loadComponent={loadComponent}/>
                                        </div>
                                        <div className="tab-pane fade" id="enrolledstBox" role="tabpanel">
                                            <EnrollStudents loadComponent={loadComponent}/>
                                        </div>
                                        <div className="tab-pane fade" id="settingstBox" role="tabpanel">
                                            <Settings loadComponent={loadComponent}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CourseView