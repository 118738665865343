import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import { useEffect, useState } from 'react';
import { fetchData, scrollbarSetToTop } from '../components/Helper';
import $ from 'jquery';
import { Helmet } from 'react-helmet';

function TimeTable(){
    const [events, setEvents] = useState()
    useEffect(() => {
        scrollbarSetToTop()
        fetchData('get-timetable', 'GET', '', false, false, (res) => {
            if(res.status && res.data){
                setEvents(res.data)
            }
        }, (err) => {})
    }, [])

    const opendetailModal = (getid) => {
        $("#coursedetailsModal").modal("show");
        // if(getid !== 0){
        //     fetchData(`get-timetable_timing/${getid}`, 'GET', '', false, false, (res) => {
        //         if(res.data){
        //             $('.set_coursetiming').html('');
        //             if(res.data.length > 0){
        //                 res.data.map((course_timing, key) => $(".set_coursetiming").append(`<li key = ${key}>${course_timing.name} ${course_timing.start_time} To ${course_timing.end_time} </li>`));
        //             }else{
        //                 $(".set_coursetiming").html('<li>No timing created yet</li>')
        //             }
        //         }
        //     }, (err) => {})
        // }
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Time Table</title>
        </Helmet>
        <section className="bg-blue py-4 position-relative overflow-hidden">
            <img src="images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="weteach"/>
            <div className="container position-relative zindex-2">
                <div className="row align-items-center">
                    <div className="col">
                        <h2 className="heading-lines text-white m-0 pt-3">Time Table</h2>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-3">
            <div className="container">
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12 mb-5">
                        <div id='calendar'></div>
                        { events ?
                        <FullCalendar
                            buttonText={{today: 'Today', month: 'Month', week: 'Week', day: 'Day', list: 'List'}}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                            headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                            }} 
                            initialView='timeGridDay'
                            dayMaxEvents={true}  
                            eventClick={(arg) => {
                                    let type = arg.event.extendedProps.course_id === 0 ? 'Event' : 'Course';
                                    opendetailModal(arg.event.extendedProps.course_id);
                                    $('.set_typename').html(type);
                                    $('.set_titlename').html(arg.event.title);
                                    $('.set_description').html(arg.event.extendedProps.description);
                                    $('.set_eventdatetime').html(arg.event.extendedProps.event_timing);
                                    $('.set_coursetiming').html(arg.event.extendedProps.event_timing);
                                    $('#showEventTime, #showCourseTime').addClass('d-none');
                                    type === 'Event'? $('#showEventTime').removeClass('d-none') : $('#showCourseTime').removeClass('d-none')
                                }
                            }
                            initialEvents={events}
                            slotMinTime="00:00:00"
                            slotMaxTime="24:00:00"
                        />
                        :
                        '' }
                    </div>
                </div>
            </div>
        </section>


        {/* Start | Course Details Modal */}
        <div className="modal fade" id="coursedetailsModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content overflow-hidden border-0 shadow-lg">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title fw-medium pt-1" id="exampleModalLabel">
                            <span className="set_typename"></span> Details
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                    </div>
                    <div className="modal-body">
                        <div className="alert alert-info rounded mb-2">
                            <div className="fs-13px text-uppercase mb-1"><span className="set_typename"></span> Title</div>
                            <div className="text-dark fw-medium set_titlename"></div>
                        </div>
                        <div className="alert alert-success rounded mb-2">
                            <div className="fs-13px text-uppercase mb-1"><span className="set_typename"></span> Description</div>
                            <div className="text-dark fw-medium set_description"></div>
                        </div>
                        <div className="alert alert-warning rounded mb-2 d-none" id="showCourseTime">
                            <div className="fs-13px text-uppercase mb-1"><span className="set_typename"></span> Timing</div>
                            <div className="text-dark fw-medium set_coursetiming"></div>
                        </div>
                        <div className="alert alert-danger rounded mb-0 d-none" id="showEventTime">
                            <div className="fs-13px text-uppercase mb-1"><span className="set_typename"></span> Date</div>
                            <div className="text-dark fw-medium set_eventdatetime">...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End | Course Details Modal */}
        </>
    )
}

export default TimeTable