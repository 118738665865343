import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch } from "react-router-dom";

import AboutUs from '../modules/AboutUs';
import AccountCreate from '../modules/account/AccountCreate';
import AccountEdit from '../modules/account/AccountEdit';
import AccountList from '../modules/account/AccountList';
import AccountView from '../modules/account/AccountView';
import ContactUs from '../modules/ContactUs';
import Course from '../modules/Course';
import EduClsList from '../modules/educls/EduClsList';
import EduClsCreate from '../modules/educls/EduClsCreate';
import Dashboard from '../modules/Dashboard';
import Home from '../modules/Home';
import JoinCourse from '../modules/JoinCourse';
import Login from '../modules/Login';
import ScheduleCreate from '../modules/schedule/ScheduleCreate';
import ScheduleEdit from '../modules/schedule/ScheduleEdit';
import ScheduleList from '../modules/schedule/ScheduleList';
import SignUp from '../modules/SignUp';
import Teacher from '../modules/Teacher';
import TeacherInfo from '../modules/TeacherInfo';
import TimeTable from '../modules/TimeTable';
import Context from './Context';
import ContactList from '../modules/contact/ContactList';
import { fetchData } from './Helper';
import FrontendLayout, { BackendLayout, FrontendProfileLayout } from './Layout';
import EduClsEdit from '../modules/educls/EduClsEdit';
import CourseList from '../modules/course/CourseList';
import CourseCreate from '../modules/course/CourseCreate';
import CourseEdit from '../modules/course/CourseEdit';
import FeedbackList from '../modules/feedback/FeedbackList';
import FeedbackCreate from '../modules/feedback/FeedbackCreate';
import FeedbackEdit from '../modules/feedback/FeedbackEdit';
import NotificationList from '../modules/notification/NotificationList';
import ActivityList from '../modules/notification/ActivityLogList';
import CourseView from '../modules/course/CourseView';
import ForgotPassword from '../modules/ForgotPassword';
import ResetPassword from '../modules/ResetPassword';
import Payment from '../modules/Payment';
import TeacherCourses from '../modules/account/TeacherCourses';
import NotFound from './NotFound';
import ProfileChangePassword from '../modules/myaccount/ProfileChangePassword';
import VerifyEmail from '../modules/VerifyEmail';
import Privacy from '../modules/Privacy';
import TermCondition from '../modules/TermsCondition';
import Setting from '../modules/cms/Setting';
import Faq from '../modules/Faq';
import BusinessModel from '../modules/BusinessModel';
import VerifyPayment from '../modules/VerifyPayment';

export default function Routing () {
    const [context, setContext] = useState();
    const [token] = useState(localStorage.getItem('wt-token'));
    useEffect(() => {

        fetchData('get-site-data', 'get', '', false, false, (res) => {
            if(res.status){
                setContext(prevState => ({
                    ...prevState,
                    site: res.data
                }))
            }
        }, (err) => {}, false, false)

        if(token){
            document.getElementById('loader').classList.remove('active')
            fetchData('get-auth-user-info', 'get', '', true, false, (res) => {
                if(res.status){
                    document.getElementById('loader').classList.add('active')
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data
                    }))
                }
            }, (err) => {}, false, false)
        }
    }, [token]);

    return (
        <>  
            <div className="loader active" id="loader">
                <img className="loader-zoomer" src="/images/loader.png" alt="" />
            </div>
            <Context.Provider value={[context, setContext]}>
            <BrowserRouter>
                <Switch>
                    <FrontendLayout exact path="/"><Home/></FrontendLayout>
                    <FrontendLayout exact path="/home"><Home/></FrontendLayout>
                    <FrontendLayout exact path="/courses"><Course/></FrontendLayout>
                    <FrontendLayout exact path="/time-table"><TimeTable/></FrontendLayout>
                    <FrontendLayout exact path="/teachers"><Teacher/></FrontendLayout>
                    <FrontendLayout exact path="/about-us"><AboutUs/></FrontendLayout>
                    <FrontendLayout exact path="/contact-us"><ContactUs/></FrontendLayout>
                    <FrontendLayout exact path="/login"><Login/></FrontendLayout>
                    <FrontendLayout exact path="/sign-up"><SignUp/></FrontendLayout>
                    <FrontendLayout exact path="/course/:course_id/join"><JoinCourse/></FrontendLayout>
                    <FrontendLayout exact path="/teacher-info/:teacher_id"><TeacherInfo/></FrontendLayout>
                    <FrontendLayout exact path="/forgot-password"><ForgotPassword/></FrontendLayout>
                    <FrontendLayout exact path="/reset-password/:token/:email"><ResetPassword/></FrontendLayout>
                    <FrontendLayout exact path="/verify-email"><VerifyEmail/></FrontendLayout>
                    <FrontendLayout exact path="/verify-payment"><VerifyPayment/></FrontendLayout>
                    <FrontendLayout exact path="/terms-and-conditions"><TermCondition/></FrontendLayout>
                    <FrontendLayout exact path="/privacy-policy"><Privacy/></FrontendLayout>
                    <FrontendLayout exact path="/faq"><Faq/></FrontendLayout>
                    <FrontendLayout exact path="/weteach-Website-business-model-8fz475af94ht23w"><BusinessModel/></FrontendLayout>
                    
                    <BackendLayout module={1} exact path="/dashboard"><Dashboard/></BackendLayout>
                    <BackendLayout module={2} exact path="/accounts/:account_type(admin|teacher|student|parent|assistant)/list/:list_type(draft)?"><AccountList/></BackendLayout>
                    <BackendLayout module={2} exact path="/accounts/:account_type(admin|teacher|student|parent|assistant)/create"><AccountCreate/></BackendLayout>
                    <BackendLayout module={2} exact path="/accounts/:account_type(admin|teacher|student|parent|assistant)/profile/:user_id"><AccountView/></BackendLayout>
                    <BackendLayout module={2} exact path="/accounts/:account_type(admin|teacher|student|parent|assistant)/edit/:user_id"><AccountEdit/></BackendLayout>
                    <BackendLayout module={2} exact path="/accounts/teacher/courses/:user_id"><TeacherCourses/></BackendLayout>   
                    <BackendLayout module={9} exact path="/educational-classification/:eduCls_type(system|level|board|subject)/list"><EduClsList/></BackendLayout>
                    <BackendLayout module={9} exact path="/educational-classification/:eduCls_type(system|level|board|subject)/create"><EduClsCreate/></BackendLayout>
                    <BackendLayout module={9} exact path="/educational-classification/:eduCls_type(system|level|board|subject)/edit/:id"><EduClsEdit/></BackendLayout>   
                    <BackendLayout module={8} exact path="/course/list"><CourseList/></BackendLayout>
                    <BackendLayout module={8} exact path="/course/create"><CourseCreate/></BackendLayout>
                    <BackendLayout module={8} exact path="/course/details/:course_id"><CourseView/></BackendLayout>
                    <BackendLayout module={8} exact path="/course/list/:list_type(draft)?"><CourseList/></BackendLayout>
                    <BackendLayout module={8} exact path="/course/(draft/)?edit/:course_id"><CourseEdit/></BackendLayout>
                    <BackendLayout module={15} exact path="/contact/list"><ContactList/></BackendLayout>
                    <BackendLayout module={10} exact path="/schedule/list"><ScheduleList/></BackendLayout>
                    <BackendLayout module={10} exact path="/schedule/create"><ScheduleCreate/></BackendLayout>
                    <BackendLayout module={10} exact path="/schedule/edit/:schedule_id"><ScheduleEdit/></BackendLayout>
                    <BackendLayout module={14} exact path="/feedback/list"><FeedbackList/></BackendLayout>
                    <BackendLayout module={14} exact path="/feedback/create"><FeedbackCreate/></BackendLayout>
                    <BackendLayout module={14} exact path="/feedback/edit/:id"><FeedbackEdit/></BackendLayout>
                    <BackendLayout module={13} exact path="/notification/:list_type(autofi|manufi|auto-message|recipient-list|recipient)?/list"><NotificationList/></BackendLayout>
                    <BackendLayout module={12} exact path="/activity/:list_type(account|course)?/list"><ActivityList/></BackendLayout>
                    <BackendLayout module={11} exact path="/payments"><Payment/></BackendLayout>
                    { context && context.auth && (context.auth.role_id === 1 || context.auth.role_id === 2) ?
                    <BackendLayout exact path="/change-password"><ProfileChangePassword/></BackendLayout> :
                    <FrontendProfileLayout exact path="/change-password"><ProfileChangePassword/></FrontendProfileLayout> }
                    
                    <BackendLayout exact path="/settings"><Setting/></BackendLayout>

                    <FrontendProfileLayout exact path="/:front_route(my-profile|my-subjects|my-schedule|my-wallet|library|recordings|recording|assignments|assignment|quizzes|quizz|performance|timing|my-groups|my-students|my-student|notes|note)/:details?/:user_id?/:course_id?"/>

                    <FrontendLayout><NotFound/></FrontendLayout>
                </Switch>
            </BrowserRouter>
            </Context.Provider>
        </>
    )
}
