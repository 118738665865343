import ReactDOM from 'react-dom';
import { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router"
import Datatables, { reloadDataTable } from '../../components/Datatables';
import { fetchData } from '../Helper';

function  EnrollStudents(props) {
    const courseId = useParams().course_id
    const history = useHistory()
    const [enroll_student_dt] = useState({
        dt_url: `get-enroll-student-of-course/${courseId}`,
        dt_name: 'enroll_student',
        dt_column: [
            { data:"id", name:'users.id', title: "#ID"},
            { data:"name", name:`users.name`, title:" Student Name"},
            { data:"email", name:'users.email', title:"Student Email"},
            { data:"created_at", name:'student_courses.created_at', title:"Joined Date"},
            { data:"withdraw_date", name:'student_courses.withdraw_date', title:"Withdrawal Date"},
            { data:"join_type", name:'student_courses.join_type', title:"Join As"},
            { data:"status", name:'student_courses.status', title:"Status"},
            { data:"student_course_id", title: 'Action', sortable:false, searchable:false, class:"text-nowrap text-center"}
        ],

        dt_column_defs: [
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : <span className="text-danger fw-medium">Withdraw</span> }
                    </>, td)
                },
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <button type="button" className={`btn btn-${rowData.status === 'active' ? 'danger' : 'success'} py-2px m-2px`} title={rowData.status === 'active' ? 'Withdraw' : 'Active'} onClick={() => withdrawStudent(rowData.student_course_id)}>{rowData.status === 'active' ? 'Withdraw' : 'Activate'}</button>
                        <button type="button" className={`btn btn-blue py-2px m-2px`} title="Change Join Type" onClick={() => changeJoinType(rowData.student_course_id)}>Change Join Type</button>
                    </>, td)
                },
            }, 
        ]
    })

    const withdrawStudent = (id) => {
        fetchData(`withdraw-student/${id}`, 'POST', {pathname: history.location.pathname}, true, false, (res) => {
            reloadDataTable(enroll_student_dt)
        }, (err) => {})
    }

    const changeJoinType = (id) => {
        fetchData(`change-course-join-type/${id}`, 'POST', {pathname: history.location.pathname}, true, false, (res) => {
            reloadDataTable(enroll_student_dt)
        }, (err) => {})
    }

    useEffect(() => {
        if(props.loadComponent === 'enroll_student'){
            reloadDataTable(enroll_student_dt)
        }
    })

    return(
        <>
            <div className="table-responsive p-1">
                    <Datatables dt_name="enroll_student"/>
            </div>
        </>
    )
}

export default EnrollStudents
