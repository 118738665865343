import ReactDOM from 'react-dom';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import Datatables, { reloadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { fetchData } from '../../components/Helper';
import $ from 'jquery'
import { Helmet } from 'react-helmet';

let profilePath = '';

function AccountList(){
    const history = useHistory()
    const accountType = useParams().account_type
    const listType = useParams().list_type
    const [modules, setModules] = useState()
    const [module_ids, setModule_ids] = useState([])

    const [dt] = useState({
        dt_url: `account-list?type=${accountType}&status_q=${listType ? listType : ''}`,
        dt_name: 'account_list',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Name"},
            { data:"email", name:'email', title:"Email"},
            { data:"user_details.phone", name:'userDetails.phone', title:"Phone No.", class:"text-nowrap"},
            { data:"status", name:'status',  title:"Status" },
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(`+${rowData.user_details.phone }`, td)
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    { rowData.status === "pending" ? <span className="text-warning fw-medium">Pending</span> : '' }
                    { rowData.status === "draft" ? <span className="text-warning fw-medium">Draft</span> : '' }
                    </>, td)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        { rowData.status === 'inactive' || rowData.status === 'pending' ? 
                        <button type="button" className="btn btn-success py-2px m-2px w-100px" title="Activate" onClick={ () => handleChangeStatus('active', rowData.id)}>
                                Activate
                        </button>
                        : '' }

                        { rowData.status === 'active' ?
                        <button type="button" className="btn btn-danger py-2px m-2px w-100px" title="Deactivate" onClick={ () => handleChangeStatus('inactive', rowData.id)}>
                            Deactivate
                        </button>
                        : '' }

                        { rowData.role_id === 2 && rowData.status !== 'draft' ?
                        <button type="button" className="btn btn-primary py-2px m-2px" title="Permission" data-bs-toggle="modal" data-bs-target="#permissionModal" onClick={() => handlePermission(rowData.id)}>
                            Permission
                        </button>
                        : '' }

                        { rowData.role_id !== 2 ?
                        <button type="button" className="btn btn-primary py-2px m-2px" title="View Profile" onClick={() => handleViewProfile(rowData.id)}>
                            View Profile
                        </button>
                        : '' }

                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" onClick={() => handleEditProfile(rowData.id)}>
                            Edit
                        </button>

                        { rowData.status === 'draft' ?
                        <button type="button" className="btn btn-danger py-2px m-2px w-100px" title="Delete" onClick={ () => handleChangeStatus('delete', rowData.id)}>
                            Delete
                        </button>
                        : '' }
                                                    
                    </>, td)
                },
            },
        ]
    })

    useEffect(() => {
        profilePath = accountType
        let status_q = listType ? `&status_q=${listType}` : ''
        
        reloadUrlDataTable(dt, `account-list?type=${accountType}${status_q}`)
    }, [dt, accountType, listType])

    const handleChangeStatus = (status, id) => {
        fetchData(`account/change-status`, 'POST', {'id': id, 'status': status}, true, false, (res) => {
            reloadDataTable(dt)
        }, (err) => {})
    }

    const handleViewProfile = (user_id) => {
        history.push(`/accounts/${profilePath}/profile/${user_id}`) 
    }   
    
    const handleEditProfile = (user_id) => {
        history.push(`/accounts/${profilePath}/edit/${user_id}`) 
    }   

    const handlePermission = (user_id) => {
        fetchData('get-modules-with-permission', 'post', {'user_id': user_id}, true, false, (res) => {
            if(res.status && res.data){
                setModules('')
                setModules(res.data)
                let module_ids = []
                res.data.modules.forEach(module => {
                    if(module.checked){
                        module_ids.push(module.id)
                    }
                })

                setModule_ids(module_ids)
            }
        }, (err) => {})
    }

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            module_ids.push(+e.target.value)
        } else {
            module_ids.splice(module_ids.indexOf(+e.target.value), 1)
        }

        setModule_ids(module_ids)
    }

    const handleSavePermission = () => {
        let account = {'user_id': modules.user_id, 'modules':module_ids}
        
        fetchData('update-permission', 'POST', account, true, false, (res) => {
            if(res.status){
                $('#permissionModal').modal('hide')
            }
        }, (err) => {})
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | { _.upperFirst(accountType) } Accounts</title>
        </Helmet>
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2">
                        <h5 className="m-0 heading-lines fw-medium pb-2">{ _.upperFirst(accountType) } Accounts</h5>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/accounts/${accountType}/create`} className="btn btn-blue px-3 mb-1 me-2" title={`Create ${ _.upperFirst(accountType) }`}>Create { _.upperFirst(accountType) }</Link>
                        <Link to={`/accounts/${accountType}/list${listType === 'draft' ? '': '/draft'}`} className="btn btn-warning px-3 mb-1" title={`${ _.upperFirst(accountType) } ${listType === 'draft' ? 'List': 'Drafts'}`}>{ _.upperFirst(accountType) } {listType === 'draft' ? 'List': 'Drafts'}</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                                <Datatables dt_name="account_list"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="modal fade" id="permissionModal" tabIndex="-1" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">Permissions</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                    </div>
                    <div className="modal-body p-sm-4">
                        { modules ? modules.modules.map((module, i) => (
                            <label className="form-check mb-2" key={i}>
                                <input className="form-check-input" name="modules" type="checkbox" value={ module.id } onChange={handleCheckboxChange} defaultChecked={module.checked}/>
                                <div className="form-check-label">
                                    { module.name }
                                </div>
                            </label>
                        )) : ''}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-blue w-100px" onClick={handleSavePermission} title='Save'>Save</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AccountList