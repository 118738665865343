import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TeacherCard from "../components/cards/Teacher";
import { fetchData, scrollbarSetToTop } from "../components/Helper";
import Pagination from "../components/Pagination";

function Teacher(){
    const [currentPage, setCurrentPage] = useState(1)
    const [teacher, setTeacher] = useState('')

    useEffect(() => {
        scrollbarSetToTop()
        async function getTeacher(){
            fetchData(`get-user-with-details/4/yes?page=${currentPage}`, 'GET', '', false, false, (res) => {
                if(res.status && res.data){
                    setTeacher(res.data)
                }
            }, (err) => {})
        }

        getTeacher()
    }, [currentPage]);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0)
	}
    
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Teachers</title>
        </Helmet>
        <section className="bg-blue py-4 position-relative overflow-hidden">
            <img src="images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="weteach"/>
            <div className="container position-relative zindex-2">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="heading-lines text-white m-0 pt-3">Our Staff</h2>
                    </div>
                </div>
            </div>
        </section>

        <section className="cources-section py-4">
            <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-4">
                { teacher.data && teacher.data.length ? 
                    teacher.data.map((item, i) => {
                        return (
                            <TeacherCard teacher={item} key={i}/>
                        )
                    })
                : 
                Array.from(Array(8)).map((c, index) => 
                <div className="col placeholder-wave" key={index}>
                    <div className="course-item h-100">
                        <div className="ratio ratio-4x3 bg-light">
                            <div className="w-100 h-100 img-cover placeholder"></div>
                        </div>
                        <span className="course-tag placeholder w-25"></span>
                        <div className="p-3">
                            <h6 className="course-heading placeholder w-50">&nbsp;</h6>
                            <p className="course-description placeholder"></p>
                            <p className="course-description placeholder"></p>
                            <p className="course-description placeholder w-50"></p>
                            <div className="pt-2 d-flex justify-content-between align-items-center">
                                <span className="btn btn-outline-blue placeholder w-25"></span>
                            </div>
                        </div>
                    </div>
                </div> )
                }
            </div>

            <Pagination paginate_data={teacher} onPageChange={handlePageClick}/>
            </div>
        </section>
        </>
    )
}

export default Teacher