import _ from "lodash";
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import bootstrap from 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';

const API_URL = process.env.REACT_APP_API_URL

export const showAlert = (data) => {
    if(data.message){
        let idGenerate = _.floor((_.random(0, 9) * 1000000) + 1)
        let alertType = (data.status === true) ? 'success' : 'danger'
        
        const htmlToast = `<div id="${idGenerate}" class="toast fade bg-white mb-2 border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000"><div class="d-flex bg-${alertType}">
                <div class="toast-body fw-medium text-white">
                    ${data.message}
                </div>
                <button type="button" class="btn-close ms-auto me-2 mt-2 min-wh-20px" data-bs-dismiss="toast" aria-label="Close"></button></div>
            </div>`;
        
        $('#toastContainer').html(htmlToast)
    
        let getIdToast = document.getElementById(idGenerate);
        let toast = new bootstrap.Toast(getIdToast);
        toast.show();
        getIdToast.addEventListener('hidden.bs.toast', function() {
            setTimeout(() => {
                this.remove();
            }, 700);
        });

    }else if(data.errors) {
        $('.form-control').removeClass('is-invalid');
        $('.invalid-feedback').remove();
        for(let key in data.errors){
            if(['password', 'student_password', 'parent_password', 'new_password'].includes(key) && !(data.errors[key][0].includes('greater'))){
                data.errors[key][0] = 'Your password should be minimum 8 characters and includes at least a number, uppercase and lower case letter and 1 symbol'
            }
            $(`#${key}`).addClass('is-invalid')
            $(`#${key}`).after(`<div class="invalid-feedback">${data.errors[key][0]}</div>`)
        }
    }
}

export const validateForm = (e, formId = '') => {
    // 'use strict'
    let forms =  formId === '' ? document.querySelectorAll('.needs-validation')
    : document.querySelectorAll(`#${formId}.needs-validation`)
    
    let error = 0;
    Array.prototype.slice.call(forms)
    .forEach(function (form) {
        if (!form.checkValidity()) {
            error++;
            e.preventDefault();
            e.stopPropagation();
        }
        form.classList.add('was-validated')
    })

    if(error){
        return false
    }else{
        return true
    }
}
export const fetchData = async (url, method, data, token, process, res, err, blob = false, loader = true) => {
    if(loader){
        loadingData(true)
    }
    let headers = {
        'Accept': 'application/json',
    }

    if(process){
        headers = {...headers, 'contentType': false, 'processData': false}
    }else{
        headers = {...headers, 'Content-Type': 'application/json'}
    }

    if(token){
        const TOKEN = localStorage.getItem("wt-token") ? localStorage.getItem("wt-token") : ''
        headers = {...headers, 'Authorization': 'Bearer ' + TOKEN}
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if(data){
        request = {...request, 'body': process ? data : JSON.stringify(data)}
    }
    
    await fetch(`${API_URL}${url}`, request).then((response) => blob ? response.blob() : response.json()).then((json) => {
        loadingData()
        if(json.message === "Unauthenticated."){
            localStorage.removeItem("wt-token")
            window.location.href = '/login'
        }else{
            showAlert(json)
            res(json)
        }
    }).catch((error) => {
        loadingData()
        //err(error);
    });
}

export const checkFile = (e) => {
    const fileExt = ['jpeg', 'jpg', 'png'];
    const files = e.target.files[0];
    let ext = files.name.split('.').pop();
    if (!fileExt.includes(ext.toLowerCase())) {
        alert('Please upload file in pdf, jpeg, jpg, png format.');
        return ''
    } else {
        return files
    }
}

export const getWeekDays = (week_day) =>{
    let date = new Date(week_day);
    let day = date.toLocaleString('en-us', {weekday: 'long'}); 
    return day;
}

export const intlTel_phone = (phone_id, country_id, country_code, dropdown = false) => {
    // Initialise the IntlTelInput
    const input = document.querySelector(phone_id);
    let iti_phone = intlTelInput(input, {
        formatOnDisplay: true,
        separateDialCode: true,
        allowDropdown:dropdown
    });
    // Load the utils JS
    window.intlTelInputGlobals.loadUtils("/static/js/intlTelInput-utils.js")
    // Create Instance of IntlTelInput
    var iti = window.intlTelInputGlobals.getInstance(document.querySelector(phone_id));

    if(country_id){
        // Populate the country dropdown
        for (var i = 0; i < window.intlTelInputGlobals.getCountryData().length; i++) {
            var country = window.intlTelInputGlobals.getCountryData()[i];
            var optionNode = document.createElement("option");
            optionNode.value = country.iso2;
            var textNode = document.createTextNode(country.name);
            optionNode.appendChild(textNode);
            document.querySelector(country_id).appendChild(optionNode);
        }

        $(country_id).val(country_code);
        iti.setCountry(country_code);

        $(country_id).on('change', function() {
            iti.setCountry(this.value);
            validatePhone(iti_phone, phone_id)
        });
    }
    
    input.addEventListener('keyup', function() {
        validatePhone(iti_phone, phone_id)
    });

    input.addEventListener('change', function() {
        validatePhone(iti_phone, phone_id)
    });

    return iti_phone
}

export const validatePhone = (iti_phone, phone_id) => {
    let errorMap = ["Phone number is invalid.", "Country code is invalid.", "Phone number is too short.", "Phone number is too long.", "Phone number is invalid."];
    
    $(phone_id).removeClass('is-invalid');
    $(phone_id).siblings('.invalid-feedback').remove();
    if (!iti_phone.isValidNumber()) {
        var errorCode = iti_phone.getValidationError()
        $(phone_id).addClass('is-invalid')
        $(phone_id).after(`<div class="invalid-feedback">${errorMap[errorCode] ? errorMap[errorCode] : 'Phone number is invalid.'}</div>`)
        return false;
    }else{
        return true;
    }
}

export const generateId = (length) => {
    let result           = '';
    let characters       = 'abcdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ1234567890';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const initialFormState = (formId, setData) => {
    [...document.querySelectorAll(`#${formId} .form-control, #${formId} .form-select`)].forEach((elm)=>{
        elm.classList.remove('is-invalid');
    });
    document.getElementById(formId).classList.remove('was-validated')
    document.getElementById(formId).reset()
    for(let key in setData){
        setData[key] = ''
    }
    return setData;
}

export const scrollbarSetToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
    });
}

export const downloadFile = (blobImg, name) => {
    let url = window.URL.createObjectURL(new Blob([blobImg]));
    let ext = _.split(blobImg.type, '/')[1]
    if(ext.match('spreadsheetml')){
        ext = 'xlsx'
    }else if(ext.match('wordprocessingml')){
        ext = 'docx'
    }
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name+'.'+ext);
    document.body.appendChild(link);
    link.click();
}
export const decryptCode = (code) => {
    let str = '';
    // code = code.split("").reverse().join("");
    code = _.join( _.reverse( _.split(code, '') ), '');
    code = code.match(/.{1,3}/g)
    for(let i = 0; i < _.size(code); i++){
        if(i % 2 !== 0){
            str += String.fromCharCode(code[i])
        }
    }
    return str.split("").reverse().join("");
}

export const loadingData = (active) => {
    if(active){
        document.querySelector('body').classList.add('loading-data');
    }else{
        document.querySelector('body').classList.remove('loading-data');
    }
}

export const ModalSection = (props) => {
    return (
        <div className="modal fade" id={ props.modal_id } tabIndex="-1" aria-labelledby={ props.modal_id } aria-hidden="true" data-bs-backdrop="static">
            <div className={`modal-dialog modal-dialog-scrollable modal-${ props.size }`}>
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">{ props.title }</h5>
                        {props.func ? <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ props.func }></button> 
                        : <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>}
                    </div>
                    <div className="modal-body p-sm-4">{ props.children }</div>
                </div>
            </div>
        </div>
    )
}
