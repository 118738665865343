import { useLocation } from 'react-router';
import { useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Context from "./Context";

function FrontendSidebar(props){
    const location = useLocation()
    const history = useHistory()

    return(
        <>
            { props.role === 5 || props.role === 6 ?
                <ul className="dropdown-menu show border-0 rounded-10 shadow-sm left-aside p-3 mb-4">
                    <li>
                        <h6 className="dropdown-header text-orange">My Panel</h6>
                    </li>
                    <li>
                        <NavLink className="dropdown-item rounded " to={{pathname: '/my-profile', state: history.location.state }} title="My Account">
                            <i className="bi-person-circle"></i>
                            My Account
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('timing/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/my-subjects', state: history.location.state }} title="My Subject">
                            <i className="bi-layers"></i>
                            My Subject
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="dropdown-item rounded" to={{pathname:'/my-schedule', state: history.location.state }} title="My Schedule">
                            <i className="bi-calendar4-week"></i>
                            My Schedule
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="dropdown-item rounded" to={{pathname: '/my-wallet', state: history.location.state }} title="My Wallet">
                        <i className="bi-wallet"></i>
                        My Wallet
                        </NavLink>
                    </li>
                    <li>
                        <hr className="dropdown-divider opacity-10"/>
                    </li>
                    <li>
                        <h6 className="dropdown-header text-orange">Materials</h6>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('library/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/library', state: history.location.state }} title="Library">
                        <i className="bi-book"></i>
                            Library
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('recording/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/recordings', state: history.location.state }} title="Recordings">
                        <i className="bi-record-circle"></i>
                            Recordings
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('assignment/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/assignments', state: history.location.state }} title="Assignments">
                        <i className="bi-file-text"></i>
                            Assignments
                        </NavLink>
                    </li>
                    
                    <li>
                        <hr className="dropdown-divider opacity-10"/>
                    </li>
                    <li>
                        <h6 className="dropdown-header text-orange">Mocks</h6>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('quizz/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/quizzes', state: history.location.state }} title="Quizzes">
                            <i className="bi-puzzle bi-1x"></i>
                            Quizzes
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('performance/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/performance', state: history.location.state }} title="Performance">
                            <i className="bi-graph-up"></i>
                            Performance
                        </NavLink>
                    </li> 
                </ul>
                :  
                <ul className="dropdown-menu show border-0 rounded-10 shadow-sm left-aside p-3 mb-4">
                    <li>
                        <h6 className="dropdown-header text-orange">My Account</h6>
                    </li>
                    <li>
                        <NavLink className="dropdown-item rounded " to={{pathname: '/my-profile', state: history.location.state }} title="My Profile">
                            <i className="bi-person-circle"></i>
                            My Profile
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('timing/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/my-groups', state: history.location.state }} title="My Groups">
                            <i className="bi-layers"></i>
                            My Groups
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="dropdown-item rounded" to={{pathname: '/my-schedule', state: history.location.state }} title="My Schedule">
                            <i className="bi-calendar4-week"></i>
                            My Schedule
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('my-student') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/my-students', state: history.location.state }} title="My Students">
                            <i className="bi-person-badge"></i>
                            My Students
                        </NavLink>
                    </li>
                    
                    <li>
                        <hr className="dropdown-divider opacity-10"/>
                    </li>
                    <li>
                        <h6 className="dropdown-header text-orange">Materials</h6>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('assignment/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/assignments', state: history.location.state }} title="Assignments">
                            <i className="bi-file-text"></i>
                            Assignments
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('quizz/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/quizzes', state: history.location.state }} title="Quizzes">
                            <i className="bi-puzzle bi-1x"></i>
                            Quizzes
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('note/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'}  to={{pathname: '/notes', state: history.location.state }} title="Notes">
                            <i className="bi-journal-text"></i>
                            Notes
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className={location.pathname.match('performance/details') ? 'dropdown-item rounded active' : 'dropdown-item rounded'} to={{pathname: '/performance', state: history.location.state }} title="Performance">
                            <i className="bi-graph-up"></i>
                            Performance
                        </NavLink>
                    </li>
                </ul> 
            }
       </>
    )
}

function BackendSidebar(props){
    const [context] = useContext(Context)
    const location = useLocation()


    return(
        <aside className="admin-aside">
            <div className="admin-logobox">
                <Link to="/" className="admin-brandbox" title="We Teach">
                    <img src={context.site.logo} alt="We Teach - Logo"/>
                </Link>
                <button type="button" className="btn btn-close mmwh-36px border-0 p-0 align-items-center justify-content-center" id="menuClose" title="Menu Close"></button>
                <hr />
            </div>
            <div className="admin-navbox" id="navtoggle">
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(1))) ? 
                <div className="accordion-item">
                    <NavLink className="main-link active1 collapsed" to="/dashboard" title="Dashboard">
                        <i className="bi-speedometer" role="img" aria-label="Speedometer"></i>
                        Dashboard
                    </NavLink>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(2))) ? 
                <div className="accordion-item">
                    <a className={location.pathname.match('/accounts/*') ? "main-link" : "main-link collapsed"} data-bs-toggle="collapse" href="#submenu1" aria-expanded={location.pathname.match('/accounts/*') ? 'true' : 'false'} title="Accounts">
                        <i className="bi-person-circle" role="img" aria-label="Person"></i>
                        Accounts
                    </a>
                    <div id="submenu1" className={location.pathname.match('/accounts/*') ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#navtoggle">
                        <NavLink className={location.pathname.match('/accounts/admin/*') ? 'sub-link active' : 'sub-link'} to="/accounts/admin/list" title="Admins">Admins</NavLink>
                        <NavLink className={location.pathname.match('/accounts/teacher/*') ? 'sub-link active' : 'sub-link'} to="/accounts/teacher/list" title="Teachers">Teachers</NavLink>
                        <NavLink className={location.pathname.match('/accounts/student/*') ? 'sub-link active' : 'sub-link'} to="/accounts/student/list" title="Students">Students</NavLink>
                        <NavLink className={location.pathname.match('/accounts/parent/*') ? 'sub-link active' : 'sub-link'} to="/accounts/parent/list" title="Parents">Parents</NavLink>
                        <NavLink className={location.pathname.match('/accounts/assistant/*') ? 'sub-link active' : 'sub-link'} to="/accounts/assistant/list" title="Assistant">Assistant</NavLink>
                    </div>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(8))) ? 
                <div className="accordion-item">
                    <a className={location.pathname.match('/course') && !location.pathname.match('/activity') && !location.pathname.match('/notification') && !location.pathname.match('/accounts') ? "main-link" : "main-link collapsed"} data-bs-toggle="collapse" href="#submenu2" aria-expanded={location.pathname.match('/course') && !location.pathname.match('/activity') && !location.pathname.match('/notification') && !location.pathname.match('/accounts') ? 'true' : 'false'} title="Courses">
                        <i className="bi-book" role="img" aria-label="Book"></i>
                        Courses
                    </a>
                    <div id="submenu2" className={location.pathname.match('/course') && !location.pathname.match('/activity') && !location.pathname.match('/notification') && !location.pathname.match('/accounts') ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#navtoggle">
                        <Link className={location.pathname.match('/course/create') ? 'sub-link active' : 'sub-link'} to="/course/create" title="Create Course">Create Course</Link>
                        <Link className={location.pathname === '/course/list' || location.pathname.match('/course/edit') || location.pathname.match('/course/details') ? 'sub-link active' : 'sub-link'} to="/course/list" title="Course List">Course List</Link>
                        <Link className={location.pathname === '/course/list/draft' || location.pathname.match('/course/draft/edit') ? 'sub-link active' : 'sub-link'} to="/course/list/draft" title="Draft Course">Draft Course</Link>
                    </div>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(9))) ? 
                <div className="accordion-item">
                    <NavLink className={location.pathname.match('/educational-classification/*') ? "main-link active collapsed" : "main-link collapsed"} to="/educational-classification/system/list" title="Educational Classification">
                        <i className="bi-grid" role="img" aria-label="Grid"></i>
                        Educational Classification
                    </NavLink>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(10))) ? 
                <div className="accordion-item"> 
                    <NavLink className={location.pathname.match('/schedule/*') ? "main-link active" : "main-link "}  to="/schedule/list" title="Schedules"> <i className="bi-calendar3" role="img" aria-label="Calendar"></i>
                    Schedules </NavLink>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(11))) ? 
                <div className="accordion-item">
                    <NavLink className={location.pathname.match('/payments') ? "main-link active collapsed" : "main-link collapsed"} to="/payments" title="Payments">
                    <i className="bi-credit-card" role="img" aria-label="Credit Card"></i>
                        Payments
                    </NavLink>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(12))) ? 
                <div className="accordion-item">
                    <NavLink className={location.pathname.match('/activity') ? "main-link active collapsed" : "main-link collapsed"} to="/activity/account/list" title="Activity Log">
                        <i className="bi-file-earmark-text" aria-label="File Earmark Text"></i>
                        Activity Log
                    </NavLink>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(13))) ? 
                <div className="accordion-item">
                    <NavLink className={location.pathname.match('/notification') ? "main-link active collapsed" : "main-link collapsed"} to="/notification/autofi/list" title="Notification Center">
                        <i className="bi-bell" role="img" aria-label="Bell"></i>
                        Notification Center
                    </NavLink>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(14))) ? 
                <div className="accordion-item"> 
                    <NavLink className={location.pathname.match('/feedback/*') ? "main-link active" : "main-link "}  to="/feedback/list" title="Feedback"> 
                       <i className="bi-chat-left-text" role="img" aria-label="Chat"></i>
                       Feedback
                    </NavLink>
                </div>
                : '' }
                { context && context.auth && (context.auth.role_id === 1 || (context.auth.role_id === 2 && context.auth.permission.includes(15))) ? 
                <div className="accordion-item">
                    <NavLink className="main-link" to="/contact/list" title="Contact Us">
                    <i className="bi-telephone" role="img" aria-label="Telephone"></i>
                        Contact Us
                    </NavLink>
                </div>
                : '' }
                {/* { context && context.auth && (context.auth.role_id === 1) ?
                <div className="accordion-item">
                    <a className={location.pathname.match('/site-management') ? "main-link" : "main-link collapsed"} data-bs-toggle="collapse" href="#sitemanagement" aria-expanded={location.pathname.match('/site-management') ? 'true' : 'false'} title="Courses">
                        <i className="bi-globe2" role="img" aria-label="Book"></i>
                        Site Management
                    </a>
                    <div id="sitemanagement" className={location.pathname.match('/site-management') ? 'accordion-collapse' : 'accordion-collapse collapse'} data-bs-parent="#navtoggle">
                        <Link className={location.pathname.match('/course/create') ? 'sub-link active' : 'sub-link'} to="/course/create">Content Management</Link>
                        <Link className={location.pathname === '/course/list' || location.pathname.match('/course/edit') || location.pathname.match('/course/details') ? 'sub-link active' : 'sub-link'} to="/course/list">Slider Management</Link>
                        <Link className={location.pathname === '/course/list/draft' || location.pathname.match('/course/draft/edit') ? 'sub-link active' : 'sub-link'} to="/course/list/draft">Site Settings</Link>
                    </div>
                </div>
                : '' } */}
            </div>
            <div className="admin-copyrightsbox">{context.site.copyright} | v2.2.0</div>
        </aside>
    )
}

export default FrontendSidebar
export { BackendSidebar }
