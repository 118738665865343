import React from 'react'
import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { fetchData, validateForm, showAlert } from "../../components/Helper";
import _ from 'lodash';
import { Helmet } from 'react-helmet';

function ScheduleEdit() {
    const history = useHistory()
    const id = useParams().schedule_id
    const [course, setCourse] = useState([]);
    const [teacher, setTeacher] = useState('')
    const [dateTime, setDateTime] = useState(new Date());
    const [courseId, setCourseId] = useState('')
    const [eventName, setEventName] = useState('')
    const [eventDescription, setEventDescription] = useState('')
    const [time, setTime] = useState('');
    const [timingday, setTimingday] = useState('');

    useEffect(() => {
        fetchData(`edit-schedule/${id}`, 'GET', '', true, false, (res) => {
            setCourseId(res.data.course_id)
            setEventName(res.data.event_name)
            setEventDescription(res.data.event_description)
            setTeacher(res.data.teacher_name)
            setDateTime(new Date(res.data.schedule_time))
            setTimingday(res.data.timing_day)
            fetchData(`get-timetable_timing/${res.data.course_id}`, 'GET', '', true, false, (res) => {
                setTime(res.data)
            }, (err) => {})
        }, (err) => {})

        fetchData('get-courses', 'GET', '', true, false, (res) => {
            setCourse(res.data)
        }, (err) => {})

    }, [id]);

    const courseSubmitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            let schedule_data = {
                id: id,
                scheduleType: 'course',
                course_id: courseId,
                schedule_time: dateTime,
                timing_day: timingday,
            }
            saveData(schedule_data)
        }
    }

    const eventSubmitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            let schedule_data = {
                id: id,
                scheduleType: 'event',
                event_name: eventName,
                event_description: eventDescription,
                schedule_time: dateTime
            }
            saveData(schedule_data)
        }
    }

    const saveData = (schedule_data) => {
        fetchData('update-schedule', 'POST', schedule_data, true, false, (res) => {
            if (res.status) {
                history.push('/schedule/list')
            }
        }, (err) => { })
    }


    const getCourseId = (e) => {
        let id = e.target.value;
        if (id) {
            fetchData(`get-teacher/${id}`, 'GET', '', true, false, (res) => {
                setTeacher(res.data.name)
            }, (err) => { })
            fetchData(`get-timetable_timing/${id}`, 'GET', '', true, false, (res) => {
                setTime(res.data)
                if(res.data.length > 0){
                    let selected_day = `${res.data[0].slug}-${res.data[0].day}-${res.data[0].start_time}`
                    setTimingday(selected_day)
                }else{
                    let msg = {message:'No timing available for this course. Please select any other course.', status:false}
                    showAlert(msg)
                    setTimingday('')
                }
            }, (err) => {})
        } else {
            setTeacher('')
        }
        setCourseId(e.target.value)
    }


    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Edit Schedule</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Edit Schedule</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to="/schedule/list" className="btn btn-orange mb-1" title='Create Schedule'>Schedule List</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">

                                        {eventName || courseId === '' ?
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link text-blue active fw-medium" data-bs-toggle="tab" data-bs-target="#tabpane_eventbox" type="button" role="tab" title='Event'>Event</button>
                                            </li>

                                            : <li className="nav-item" role="presentation">
                                                <button className="nav-link text-blue active fw-medium" data-bs-toggle="tab" data-bs-target="#tabpane_existingcoursebox" type="button" role="tab" title='Existing Course'>Existing Course</button>
                                            </li>
                                        }
                                    </ul>
                                    <div className="tab-content border border-top-0">

                                        {eventName || courseId === '' ?
                                            <div className="tab-pane fade p-3 show active" id="tabpane_eventbox" role="tabpanel">
                                                <form id="eventEditForm" className="needs-validation" noValidate>
                                                    <div className="mb-4 row mx-0">
                                                        <label htmlFor="event_name" className="col-sm-3 col-xxl-2 col-form-label">Event Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                            <input type="text" className="form-control" id="event_name" onChange={e => setEventName(e.target.value)} value={eventName} required />
                                                        </div>

                                                    </div>
                                                    <div className="mb-4 row mx-0">
                                                        <label htmlFor="event_description" className="col-sm-3 col-xxl-2 col-form-label">Event Description<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                            <textarea className="form-control" rows="4" name="event_description" onChange={e => setEventDescription(e.target.value)} value={eventDescription} required></textarea>
                                                        </div>
                                                        <div className="error event_description-error small text-danger px-2 py-1 d-none"></div>
                                                    </div>
                                                    <div className="mb-4 row mx-0">
                                                        <label htmlFor="adminemail" className="col-sm-3 col-xxl-2 col-form-label">Event Timing<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                            <DatePicker
                                                                selected={dateTime}
                                                                onChange={(date) => setDateTime(date)}
                                                                className="form-control"
                                                                minDate={new Date()}
                                                                timeInputLabel="Time:"
                                                                dateFormat="MMM dd, yyyy h:mm aa"
                                                                showTimeInput
                                                                onChangeRaw={(e) => e.preventDefault()}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0">
                                                        <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                                            <button type="button" id="eventEditBtn" onClick={eventSubmitForm} className="btn btn-blue w-150px px-4 me-2" title='Update Schedule'>Update Schedule</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            : <div className="tab-pane fade p-3 show active" id="tabpane_existingcoursebox" role="tabpanel">
                                                <form id="courseForm" className='needs-validation' noValidate>
                                                    <div className="mb-4 row mx-0">
                                                        <label htmlFor="courselevel" className="col-sm-3 col-xxl-2 col-form-label">Course<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                            <select className="form-select" name="course_id" id="courselevel" value={courseId} onChange={getCourseId} required>
                                                                {course ? course.map((item,i) => (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                )) : ''}
                                                            </select>
                                                        </div>
                                                        <div className="error course_id-error small text-danger px-2 py-1 d-none"></div>
                                                    </div>

                                                    <div className="mb-4 row mx-0">
                                                        <label htmlFor="courselevel" className="col-sm-3 col-xxl-2 col-form-label">Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                            <input type="text" className="form-control" placeholder="Teacher Name" value={teacher ? teacher : ''} readOnly />
                                                        </div>
                                                    </div>

                                                    <div className="mb-4 row mx-0">
                                                        <label htmlFor="adminemail" className="col-sm-3 col-xxl-2 col-form-label" >Course Timing<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                            <select className="form-select" name="course_id" id="courselevel" value={timingday} onChange={(e) => setTimingday(e.target.value)} required>
                                                                {time && time.length > 0 ? time.map((time_detail, i) => {
                                                                    return (<option key={i} value={`${time_detail.slug}-${time_detail.day}-${time_detail.start_time}`}>{`${time_detail.name} - ${_.capitalize(time_detail.day)} - ${time_detail.start_time}`}</option>)
                                                                    }) : <option value=''>-</option>
                                                                }
                                                            </select>
                                                            {/* <ul className="ps-18px mb-0 set_coursetiming">
                                                                {time && time.length > 0 ? time.map((time_detail, i) => {
                                                                    return (
                                                                        <li key={i}>{time_detail.start_time} To {time_detail.end_time}</li>
                                                                    )
                                                                })
                                                                    : 'No timing'
                                                                }
                                                            </ul> */}
                                                        </div>
                                                    </div>
                                                    <div className="mb-4 row mx-0">
                                                    <label htmlFor="adminemail" className="col-sm-3 col-xxl-2 col-form-label">Event Timing<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                        <DatePicker
                                                            selected={dateTime}
                                                            onChange={(date) => setDateTime(date)}
                                                            className="form-control"
                                                            minDate={new Date()}
                                                            timeInputLabel="Time:"
                                                            dateFormat="MMM dd, yyyy"
                                                            showTimeInput
                                                            onChangeRaw={(e) => e.preventDefault()}
                                                        />
                                                    </div>
                                                </div>

                                                    <div className="row mx-0">
                                                        <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                                            <button type="button" id="courseBtn" onClick={courseSubmitForm} className="btn btn-blue w-150px px-4 me-2" title='Update Schedule'>Update Schedule</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ScheduleEdit
