import React from 'react'
import { useEffect, useState, useContext } from "react"
import Context from "../../components/Context";
import { fetchData } from "../../components/Helper"
import $ from 'jquery'

function MySchedule(props) {
    const [context] = useContext(Context)
    const [day, setDay] = useState('Sunday');
    const [schedule, setSchedule] = useState('');

    useEffect(() => {
        document.getElementById('group-title').innerHTML = 'My Schedule';
        document.title = 'WeTeach | My Schedule';

        if (context && context.auth) {
            fetchData(`my-schedule/${props.student_id}?day=${day}`, 'GET', '', true, false, (res) => {
                setSchedule(res.data)
            }, (err) => { });
        }
    }, [context, day, props.student_id])

    $('.list-group-item-action').on('click', function () {
        $(".list-group-item-action").removeClass("active");
        $(this).addClass("active");
    });

    const getDay = (day) => {
        setDay(day)
    }

    return (
        <>
            <div className="card-body">
                <div className="row g-4">
                    <div className="col-auto">
                        <div className="list-group">
                            <button type="button" title='Sunday' onClick={() => getDay('Sunday')} className="list-group-item list-group-item-action active">Sunday</button>
                            <button type="button" title='Monday' onClick={() => getDay('Monday')} className="list-group-item list-group-item-action">Monday</button>
                            <button type="button" title='Tuesday' onClick={() => getDay('Tuesday')} className="list-group-item list-group-item-action">Tueseday</button>
                            <button type="button" title='Wednesday' onClick={() => getDay('Wednesday')} className="list-group-item list-group-item-action">Wednesday</button>
                            <button type="button" title='Thursday' onClick={() => getDay('Thursday')} className="list-group-item list-group-item-action">Thursday</button>
                            <button type="button" title='Friday' onClick={() => getDay('Friday')} className="list-group-item list-group-item-action">Friday</button>
                            <button type="button" title='Saturday' onClick={() => getDay('Saturday')} className="list-group-item list-group-item-action">Saturday</button>
                        </div>
                    </div>
                    <div className="col">
                        <ul>
                            {schedule && schedule.length > 0 ? schedule.map((schedule_details) => {
                                return (
                                    <> {schedule_details.course_id !== 0 ?
                                        <li className="mt-3 border-start border-1 bg-light p-2 anchor-blueorange" role="button">
                                            <div> {schedule_details.display_name}  {schedule_details.class_date}</div>
                                            <span className="fs-15px">{schedule_details.start_class_time} to {schedule_details.end_class_time}</span>
                                        </li>
                                        : ''}
                                        {schedule_details.course_id === 0 ?
                                            <li className="mt-3 border-start border-1 bg-light p-2 anchor-blueorange" role="button">
                                                <div>{schedule_details.event_name}  {schedule_details.schedule_time} </div>
                                            </li> : ''
                                        }
                                    </>
                                )
                            }) : <li className="border-start border-1 bg-light p-2 text-danger text-center" role="button">
                                <div> Schedule Not Found  </div>
                            </li>}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MySchedule
