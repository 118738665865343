import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router"
import { fetchData } from "../components/Helper"

function VerifyPayment(){
    const VerifyPaymentParams = useHistory();

    useEffect(() => {
        if(VerifyPaymentParams.location.search && VerifyPaymentParams.location.search.includes('stripe_session_id')){
            fetchData(`verify-stripe-payment${VerifyPaymentParams.location.search}`, 'GET', '', true, false, (res) => {
                VerifyPaymentParams.push('my-wallet')
            }, (err) => {});
        }
    }, [VerifyPaymentParams])
    
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>We Teach | Verify Payment</title>
        </Helmet>
        <section className="about-section py-5">
            <div className="container py-sm-4 py-5">
                <div className="row justify-content-center py-5">
                    <div className="col-sm-8 text-center">
                        <h2 className="fw-bold">Payment Verification</h2>
                        <p className="opacity-75">We are verifying your payment. Please do not press back button.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default VerifyPayment