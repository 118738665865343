import { useEffect, useState} from "react";
import { fetchData} from "../../components/Helper";
import $ from 'jquery'
window.jQuery = $
require('owl.carousel/dist/assets/owl.carousel.css');
require('owl.carousel');

function FeedbackCard(){
    const [feedback, setFeedback] = useState(''); 

    useEffect(() => {
        $('#feedbackslides').owlCarousel({
            stagePadding: 0,
            margin: 22,
            items: 1,
            loop: false,
            dots: false,
            nav: true,
            responsive: {
                300: {
                    dots: true,
                    nav: false,
                },
                480: {
                    items: 2,
                    dots: true,
                    nav: false,
                },
                768: {
                    items: 3
                },
                1199: {
                    items: 4
                }
            }
        });

        fetchData('feedback/yes', 'GET', '', false, false, (res) => {
            if(res.status && res.data){
                setFeedback(res.data)
                $('#feedbackslides').owlCarousel("destroy")
                $('#feedbackslides').owlCarousel({
                    stagePadding: 0,
                    margin: 22,
                    items: 1,
                    loop: false,
                    dots: false,
                    nav: true,
                    responsive: {
                        300: {
                            dots: true,
                            nav: false,
                        },
                        480: {
                            items: 2,
                            dots: true,
                            nav: false,
                        },
                        768: {
                            items: 3
                        },
                        1199: {
                            items: 4
                        }
                    }
                });
            }
        }, (err) => {})
   }, [])

    return(
        <>
            <div className="owl-carousel owl-arrow-dots" id="feedbackslides">
            { feedback && feedback.length ? feedback.map((feedback_details, i) => {
                return(
                        <div className="item">
                            <div className="feedback-item">
                                <div className="feedback-topbox">
                                    {feedback_details.feedback_description}
                                </div>
                                <div className="feedback-bottombox">
                                    <img src={feedback_details.feedback_image} className="mmw-80px" alt="weteach" />
                                    <h5>{feedback_details.teacher_name}</h5>
                                    <p>{feedback_details.course_name}</p>
                                </div>
                            </div>
                        </div>
                    )
            }) : 
                <div className="item placeholder-wave">
                    <div className="feedback-item">
                        <div className="feedback-topbox ">
                            <p className="placeholder"></p>
                            <p className="placeholder"></p>
                            <p className="placeholder w-50"></p>
                        </div>
                        <div className="feedback-bottombox">
                            <div className="mmw-80px placeholder" alt="weteach"></div>
                            <h5 className="placeholder">&nbsp;</h5>
                            <p className="placeholder"></p>
                        </div>
                    </div>
                </div> }
            </div>
        </>
    )
}

export default FeedbackCard