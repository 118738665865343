import ReactDOM from 'react-dom';
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import Datatables, { reloadUrlDataTable, reloadDataTable } from '../../components/Datatables';
import { fetchData, validateForm, ModalSection, initialFormState } from '../../components/Helper';
import $ from 'jquery';

function  RecipientList(props) {
    
    const history = useHistory()
    const listType = useParams().list_type
    var previousModalId = 0
    var courseId = 0
    const [listName, setListName] = useState('')
    const [uploadFile, setUploadFile] = useState('')
    const [users, setUsers] = useState('')
    const [editDataId, setEditDataId] = useState(false)
    const [draftList, setDraftList] = useState(false)
    
    const [list_dt] = useState({
        dt_url: `lists`,
        dt_name: 'list_table',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"created_at", name:'created_at', title: "Created At"},
            { data:"name", name:'name', title: "Name"},
            { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-nowrap text-start", width:160}
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        
                        <button type="button" className="btn btn-primary py-2px m-2px" title="View" onClick={() => listDetails(rowData.id, rowData.is_course)}>
                            View
                        </button>

                        {! rowData.pre_defined && <>
                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" data-bs-target="#createList" data-bs-toggle="modal" onClick={() => editList(rowData.id)}>
                            Edit
                        </button>

                        {rowData.status === 'draft' && <button type="button" className="btn btn-danger py-2px m-2px" title="Delete" onClick={() => deleteList(rowData.id)}>
                            Delete
                        </button>}
                        </>}
                    </>, td)
                }
            },
        ]
    })

    useEffect(() => {
        if(listType === 'recipient-list'){
            setDraftList(false)
            reloadUrlDataTable(list_dt, `lists`)
        }
        document.getElementById("createList").addEventListener('hidden.bs.modal', function (e) {
            document.querySelectorAll(".invalid-feedback").forEach(function(e){e.remove()})
            initialFormState("createListForm", {})
            setEditDataId(0)
            setUploadFile(false)
            setListName('')
            setUsers('')
        })
    }, [listType, list_dt])

    const listDetails = (id, course_id) => {
        if(course_id){
            previousModalId = id
            courseId = course_id
            $("#listDetails").modal('show');
            let list_details = {
                dt_url: `course-list?status_q=active`,
                dt_name: 'list_details',
                dt_column: [
                    { data:"id", name:'id', title: "#ID"},
                    { data:"name", name:'name', title:"Course Name"},
                    { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-end"}
                ],
                dt_column_defs: [
                    {
                        targets: 2,
                        createdCell: (td, cellData, rowData, row, col) => {
                            ReactDOM.render(<>
                                <button type="button" className="btn btn-primary py-2px m-2px" title="View" data-bs-target="#recipientListDetails" data-bs-toggle="modal" onClick={() => recipientListDetails(previousModalId, rowData.id)}>
                                    View
                                </button>                                      
                            </>, td)
                        }
                    },
                ]
            };
            reloadUrlDataTable(list_details, `course-list?status_q=active`)
        }else{
            previousModalId = 0
            courseId = 0
            $("#recipientListDetails").modal('show');
            recipientListDetails(id);
        }

    }

    const recipientListDetails = (list_id, course_id = 0) => {
        let recipient_list_details = {
            dt_url: `recipient-list-details/${list_id}/${course_id}`,
            dt_name: 'recipient_list_details',
            dt_column: [
                { data:"recipient_id", name:'recipient_id', title: "#ID"},
                { data:"name", name:'users.name', title: "Name"},
                { data:"email", name:'users.email', title: "Email"},
                { data:"user_type", name:'roles.name', title: "User Type"},
                { data:"created_at", name:"created_at", title:"Created At", searchable:false,},
                { data:"status", name:'status', title: "Status"},
                { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
            ],
            dt_column_defs: [
                {
                    targets: 5,
                    createdCell: (td, cellData, rowData, row, col) =>{
                        ReactDOM.render(<> 
                        { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                        { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                        </>, td)
                    },
                },
                {
                    targets: 6,
                    createdCell: (td, cellData, rowData, row, col) =>{
                        ReactDOM.render(<> 
                        {rowData.status === 'inactive' && 
                        <button type="button" className="btn btn-success py-2px m-2px w-100px" title="Activate" onClick={ () => handleChangeStatus('active', rowData.recipient_id)}>
                                Activate
                        </button>}

                        {rowData.status === 'active' &&
                        <button type="button" className="btn btn-warning py-2px m-2px w-100px" title="Deactivate" onClick={ () => handleChangeStatus('inactive', rowData.recipient_id)}>
                            Deactivate
                        </button>}

                        {! rowData.pre_defined && 
                        <button type="button" className="btn btn-danger py-2px m-2px" title="Delete" onClick={() => handleChangeStatus('delete', rowData.recipient_id)}>
                            Delete
                        </button>}
                        </>, td)
                    },
                },
            ]
        };
        reloadUrlDataTable(recipient_list_details, `recipient-list-details/${list_id}/${course_id}`)

        const handleChangeStatus = (status, id) => {
            fetchData(`recipient-list-change-status`, 'POST', {'id': id, 'status': status, 'pathname': history.location.pathname}, true, false, (res) => {
                reloadDataTable(recipient_list_details)
            }, (err) => {})
        }
    }

    const showPreviousModal = (id) => {
        if(previousModalId){
            $(id).modal('show'); 
            listDetails(previousModalId, courseId)
        }
    }

    const createOrUpdateList = (e, status = 'active') => {
        e.preventDefault();
        if(validateForm(e, "createListForm")){ 
            let url = editDataId ? `edit-list/${editDataId}` : `create-list`;
            const formData = new FormData();
            formData.append('name', listName)
            formData.append('users', users)
            formData.append('status', status)
            formData.append('pathname', history.location.pathname)
            fetchData(url, 'POST', formData , true, true, (res) => {
                if(res.status){
                    let url = draftList ? `lists?status_q=draft` : `lists`
                    reloadUrlDataTable(list_dt, url)
                    document.querySelector("#createList [data-bs-dismiss=modal]").click()
                }
            }, (err) => {})
        }
    }

    const editList = (id) => {
        fetchData(`edit-list/${id}`, 'GET', '' , true, false, (res) => {
            if(res.status){
                setEditDataId(id)
                setListName(res.data.name)
            }
        }, (err) => {})
    }

    const deleteList = (id) => {
        fetchData(`delete-list/${id}`, 'POST', {'pathname': history.location.pathname} , true, false, (res) => {
            if(res.status){
                reloadUrlDataTable(list_dt, `lists`)
            }
        }, (err) => {})
    }

    const lists = () => {
        let url = draftList ? `lists` : `lists?status_q=draft`
        setDraftList(! draftList)
        reloadUrlDataTable(list_dt, url)
    }

    


    return(
        <>
            <div className="table-responsive p-1">
                <div className="text-end">
                    <button className="btn btn-blue mb-2 me-2" data-bs-target="#createList" data-bs-toggle="modal">Create Recipient List</button>
                    <button className="btn btn-warning mb-2 mmw-170px" onClick={lists}>{draftList ? 'Recipient Lists' : 'Recipient Draft Lists'}</button>
                </div>
                <Datatables dt_name="list_table"/>
            </div>

            <ModalSection title="List Details" modal_id="listDetails" size="xl">
                <div className="table-responsive p-1">
                    <Datatables dt_name="list_details"/>
                </div>
            </ModalSection>

            <ModalSection title="Recipient List" modal_id="recipientListDetails" size="xl" func={() => showPreviousModal('#listDetails')}>
                <div className="table-responsive p-1">
                    <Datatables dt_name="recipient_list_details"/>
                </div>
            </ModalSection>

            <ModalSection title={editDataId ? "Update List" : "Create List"} modal_id="createList" size="lg">
                <form action="#" method="post" className="needs-validation" id="createListForm" noValidate>
                    <div className="row mx-0 mb-4">
                        <label htmlFor="name" className="col-sm-4 col-form-label">Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                            <input type="text" className="form-control" id="name" name="name" defaultValue={listName}  onChange={(e) => setListName(e.target.value)} required />
                        </div>
                    </div>
                    <div className="row mx-0">
                        <label htmlFor='users' className="col-sm-4 col-form-label">Email / Upload Excel</label>
                        <div className="col-sm-8 col-xxl-7">
                            <label><input type="radio" checked={!uploadFile} onClick={() => setUploadFile(false)} /> Email</label>
                            <label className="p-1 me-3"><input type="radio" checked={uploadFile} onClick={() => setUploadFile(true)} /> File</label>
                        </div>
                    </div>
                    <div className="row mx-0 mb-4">
                        <label className="col-sm-4 col-form-label"></label>
                        <div className="col-sm-8 col-xxl-7">
                            {uploadFile ? <input type='file' className="form-control" name='users' id='users' onChange={(e) => setUsers(e.target.files[0])}  accept=".xlsx, .xls"/>
                            : <input type='email' className="form-control" name='users' id='users' onChange={(e) => setUsers(e.target.value)} />}
                            {uploadFile && <span className="text-success fs-15px">Sample File <a href={process.env.REACT_APP_ASSET_URL+ 'sample.xlsx'} className="text-primary" download><i className="fa fa-download"></i> Download</a></span>}
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-sm-8 offset-sm-4">
                            {draftList ? <>
                                <button type="button" className="btn btn-blue me-2" onClick={createOrUpdateList}>{editDataId ? `Publish` : `Submit`}</button>
                                <button type="button" className="btn btn-outline-success" onClick={(e) => createOrUpdateList(e, 'draft')}>Save Draft</button>
                            </> : <>
                                <button type="button" className="btn btn-blue me-2" onClick={createOrUpdateList}>{editDataId ? `Update` : `Submit`}</button>
                                {! editDataId && <button type="button" className="btn btn-outline-success" onClick={(e) => createOrUpdateList(e, 'draft')}>Save Draft</button>}
                            </>}
                        </div>
                    </div>
                </form>
            </ModalSection>
        </>
    )
}

export default RecipientList
