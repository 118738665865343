import { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import FrontendFooter from "./Footer";
import Context from "./Context";
import FrontendHeader, { BackendHeader } from "./Header";
import FrontendSidebar, { BackendSidebar } from "./Sidebar";
import NotFound from "./NotFound";
import FrontComponent from "./FrontComponent";

function FrontendLayout(props){
    return(
        <>
            <FrontendHeader {...props}/>
            <Switch>
            <Route {...props}/>
            </Switch>
            <FrontendFooter/>
        </>
    )
}

function FrontendProfileLayout(props){
    const History = useHistory()
    const [context] = useContext(Context)
    const [token] = useState(localStorage.getItem('wt-token'));

    useEffect(() => {
        if (!token){
            History.push('/login')
        }
    }, [token, History]);

    return(
        <>
            { context && context.site && context.auth ? (context.auth.role_id !== 1 && context.auth.role_id !== 2) ?
            <>
            <FrontendHeader {...props}/>
            <section className="cources-section py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            { context.auth && <FrontendSidebar role={context.auth.role_id} /> }
                        </div>
                        { context.auth && <FrontComponent {...props}/> }
                    </div>
                </div>
            </section>
            <FrontendFooter/>
            </>
            :
            <>
            <FrontendHeader {...props}/>
            <NotFound/>
            <FrontendFooter/>
            </> : '' }
        </>
    )
}

function BackendLayout(props) {
    const History = useHistory()
    const [context] = useContext(Context)
    const [token] = useState(localStorage.getItem('wt-token'));

    useEffect(() => {
        if (!token){
            History.push('/login')
        }
    }, [token, History]);

    return (
        <>  
            { context && context.site && context.auth ? context.auth && (context.auth.role_id === 1 || ( context.auth.role_id === 2 && props.module && context.auth.permission.includes(props.module))) ?
            <>
            <BackendHeader/>
            <BackendSidebar {...props}/>
            <Switch>
            <Route {...props}/>
            </Switch>
            </>
            : 
            <>
            <FrontendHeader {...props}/>
            <NotFound/>
            <FrontendFooter/>
            </> : '' }
        </>
    )
}

export default FrontendLayout
export { BackendLayout, FrontendProfileLayout }