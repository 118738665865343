import 'datatables.net';
import $ from 'jquery';

const API_URL = process.env.REACT_APP_API_URL

export const initDataTable = (dt) => {
    $('#wt_datatable_'+dt.dt_name).DataTable({
        dom: "<'row mx-0'<'col-md-12 col-lg-2 px-0 text-center text-lg-start'l><'.dt-custom-filter col-md-12 col-lg-8 px-0 text-center text-lg-end'><'col-md-12 col-lg-2 px-0 text-center text-lg-end'f>>" +
            "<'row mx-0'<'col-sm-12 px-0'<'table-responsive position-relative my-2' tr>>>" +
            "<'row mx-0'<'col-md-12 col-lg-5 px-0 text-center text-lg-start'i><'col-md-12 col-lg-7 px-0 text-center text-lg-end'p>>",
        language: {
            searchPlaceholder: "Search..."
        },
        processing: true,
        serverSide: true,
        paging: dt.dt_paging,
        ajax: {
            url: API_URL+dt.dt_url,
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem("wt-token")
            },
            data: (d) => {
                d.s_filter = $('input[name=dt_filter_status]:checked').val();
            }
        },
        columns: dt.dt_column,
        columnDefs: dt.dt_column_defs, 
        order: dt.dt_order ? dt.dt_order : [[ 0, 'desc' ]],
        initComplete: dt.dt_filter,
        createdRow: dt.dt_createdRow
    })
}

export const reloadDataTable = (dt) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_'+dt.dt_name)) {
        $('#wt_datatable_'+dt.dt_name).DataTable().clear().draw(null, false)
    }else{
        initDataTable(dt)
    }
}

export const redrawDataTable = (dt) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_'+dt.dt_name)) {
        $('#wt_datatable_'+dt.dt_name).DataTable().clear().draw(null, false)
    }else{
        initDataTable(dt)
    }
}

export const reloadUrlDataTable = (dt, url) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_'+dt.dt_name) && url) {
        $('#wt_datatable_'+dt.dt_name).DataTable().clear().ajax.url(API_URL+url).load()
    }else{
        initDataTable(dt)
    }
}

function DataTables(props) {
    return (
        <>
        <table id={`wt_datatable_${props.dt_name}`} className="table table-hover align-middle w-100">
            <thead className="bg-light"></thead>
        </table>            
        </>
    )
}

export default DataTables