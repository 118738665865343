import { Link} from "react-router-dom";
import 'intl-tel-input/build/css/intlTelInput.css';
import { useHistory, useParams } from 'react-router';
import { useState, useEffect } from "react";
import _ from 'lodash';
import { fetchData, validateForm, intlTel_phone, validatePhone } from "../../components/Helper";
import { Helmet } from "react-helmet";
function FeedbackEdit() {

    const history = useHistory();
    const id = useParams().id
    const [course, setCourse] = useState([]); 
    const [teacher, setTeacher] = useState([]);
    const [courseId, setCourseId] = useState();
    const [teacherId, setTeacherId] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [image, setImage] = useState(); 
    const [comment, setComment] = useState('');
    const [intlTel, setIntlTel] = useState()
    const [features, setFeatures] = useState('no')
    const profile_path = `/accounts/teacher/profile/${teacherId}`

    useEffect(() => {
        fetchData('get-courses', 'GET', '', true, false, (res) => { 
            setCourse(res.data)   
        }, (err) => {}) 

        fetchData('teachers', 'GET', '', true, false, (res) => { 
            setTeacher(res.data)   
        }, (err) => {})
        
        fetchData(`feedback-update/${id}`, 'GET', '', true, false, (res) => {
            setTeacherId(res.data.teacher_id);
            setName(res.data.name)
            setEmail(res.data.email)
            setPhone(res.data.phone)
            setCourseId(res.data.course_id);
            setComment(res.data.description)
            setFeatures(res.data.features)

            setIntlTel(intlTel_phone('#phone', '', 'us', true))
        }, (err) => {})
    }, [id]);
    
    const saveFeedback = (e) => {
        if(validateForm(e) && validatePhone(intlTel, '#phone')){
            const formData = new FormData(); 
            formData.append('id',id)
            formData.append('name', name) 
            formData.append('email', email) 
            formData.append('phone', intlTel.getNumber()) 
            formData.append('comment', comment) 
            formData.append('teacher_id', teacherId) 
            formData.append('teacher_id', teacherId)  
            formData.append('course_id', courseId) 
            formData.append('picture', image)  
            formData.append('features', features) 
            
            fetchData(`feedback-update/${id}`, 'POST', formData, true, true, (res) => {
                if(res.status){
                    history.push(`/feedback/list`)
                }
            }, (err) => {})
    
        }
    } 

    const getTeacherId = (e) => { 
        let id = e.target.value; 
        if(id){
            fetchData(`get-course/${id}`, 'GET', '', true, false, (res) => {  
                setCourse(res.data)
            }, (err) => {
                
            }) 
        }else{
            setCourse('')
        }
        setTeacherId(e.target.value)
    }

    const backToProfile = () =>{
        history.push(`/accounts/teacher/profile/${teacherId}`) 
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Edit Feedback</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Edit Feedback</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to="/feedback/list" className="btn btn-blue mb-1 m-1" title="Feedback List">Feedback List</Link>
                            { history.location.state && history.location.state.prevPath === profile_path ?  <button className="btn btn-dark" onClick={backToProfile} title="Back to profile">Back to profile</button> : ''}  
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body p-sm-4 p-xl-5">
                                   <form action="#" className="p-4 p-sm-5 needs-validation" method="post" noValidate>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="adminname" className="col-sm-3 col-xxl-2 col-form-label">Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="name" value={name} onChange={e => setName(e.target.value)} required/>
                                            </div>
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="email" className="col-sm-3 col-xxl-2 col-form-label">Email<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required/>
                                            </div>
                                        </div> 
                                       
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="phone" className="col-sm-3 col-xxl-2 col-form-label">Phone<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5 form-flag-height38">
                                                <input type="phone" className="form-control" id="phone" autoComplete="off" name="phone" data-intl-tel-input-id="0" required defaultValue={phone ? '+' + phone : ''}/>
                                            </div>
                                        </div>

                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="adminemail" className="col-sm-3 col-xxl-2 col-form-label">Upload Pic/Avatar</label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="file" className="form-control" id="picture" name="image" defaultValue="" onChange={e => setImage(e.target.files[0])} accept="image/png, image/jpeg, image/jpg" />
                                            </div>
                                        </div>
                                         
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="adminrating" className="col-sm-3 col-xxl-2 col-form-label">Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <select className="form-select" id="rating" name="account_type" value={teacherId} onChange={getTeacherId} required>
                                                { 
                                                    _.isEmpty(teacher ) ? '' : 
                                                    teacher.map((teacher_type) => {
                                                        return (<option key={teacher_type.teacher_id} value={teacher_type.teacher_id}>{teacher_type.teacher_name}</option>)
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="adminpassword" className="col-sm-3 col-xxl-2 col-form-label">Course<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="course_id" id="courselevel" value={courseId} onChange={e => setCourseId(e.target.value)} required>
                                                    <option value="">---</option>
                                                        { 
                                                            _.isEmpty(course) ? '' : 
                                                            course.map((course_details) => {
                                                                return (<option key={course_details.id} value={course_details.id}>{course_details.name}</option>)
                                                            })
                                                        }
                                                       
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="adminemail" className="col-sm-3 col-xxl-2 col-form-label">Comment<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <textarea className="form-control" rows="3" placeholder="Write here..." id="comment" onChange={e => setComment(e.target.value)} value={comment} required></textarea>
                                            </div>
                                        </div>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="features" className="col-sm-3 col-xxl-2 col-form-label">Features<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="form-check form-check-inline mt-2">
                                                    <input className="form-check-input" type="radio" name="features" value="yes" id="yes" checked={features === "yes" ? true : false} onChange={e => setFeatures(e.target.value)}/>
                                                    <label className="form-check-label" htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="features" value="no" id="no" checked={features === "no" ? true : false} onChange={e => setFeatures(e.target.value)}/>
                                                    <label className="form-check-label" htmlFor="no">
                                                        No
                                                    </label>
                                                </div>  
                                            </div>                                          
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-sm-9 offset-sm-3 offset-xxl-2">
                                                <button type="button" className="btn btn-blue px-4 me-2" onClick={saveFeedback} title="Submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeedbackEdit
