import { useContext, useEffect } from "react";
import { useHistory } from 'react-router';
import { Link, NavLink, useLocation } from "react-router-dom";
import Context from "./Context";
import { fetchData } from "../components/Helper";


function FrontendHeader(){
    const History = useHistory()
    const [context, setContext] = useContext(Context)
    const location = useLocation()

    if(document.getElementById('navbarMenu')){
        document.getElementById('navbarMenu').classList.remove('show');
    }
    
    const handleLogout = () => {
        fetchData('logout', 'POST', '', true, false, (res) => {
            if(res.status){
                setContext(prevState => ({
                    ...prevState,
                    auth: ''
                }))
                localStorage.removeItem("wt-token");
                History.push("/")
            }
        }, (err) => {
        })
    }
    return(
        <nav className="navbar navbar-expand-xl navbar-light shadow-sm">
            <div className="container">
                <Link className="navbar-brand" to="/" title="We Teach">
                    <img src={context && context.site ? context.site.logo : 'images/logo.png'} alt="Logo - We Teach"/>
                </Link>
                <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarMenu">
                    <ul className="navbar-nav ms-auto mb-2 mb-xl-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/home">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/courses" className="nav-link">Courses</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/time-table" className="nav-link">Time Table</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/teachers" className={ location.pathname.match('teachers') || location.pathname.match('teacher-info/*') ? 'nav-link active' : 'nav-link'}>Teachers</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/about-us" className="nav-link">About Us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/contact-us" className="nav-link">Contact Us</NavLink>
                        </li>
                        { localStorage.getItem("wt-token") ?
                        <li className="nav-item dropdown">
                            <a href="javascript.void(0)" className="nav-link dropdown-toggle pe-0" data-bs-toggle="dropdown">My Account</a>
                            <ul className="dropdown-menu dropdown-menu-end shadow border-0 p-2">
                                { context && context.auth && (context.auth.role_id === 1 || context.auth.role_id === 2) ?
                                <>
                                    <li><Link to="/dashboard" className="dropdown-item rounded my-1px">Dashboard</Link></li>
                                    <li>
                                        <hr className="dropdown-divider opacity-10"/>
                                    </li>
                                </>
                                : <li><Link to="/my-profile" className="dropdown-item rounded my-1px">My Profile</Link></li> }
                                
                                <li><Link to="/change-password" className="dropdown-item rounded my-1px">Change Password</Link></li>
                                <li>
                                    <hr className="dropdown-divider opacity-10"/>
                                </li>
                                <li><button type="button" className="dropdown-item rounded my-1px" onClick={handleLogout}>Logout</button></li>
                            </ul>
                        </li>
                        : 
                        <li className="nav-item align-self-center ms-xl-3">
                            <NavLink to="/login" className="btn btn-outline-blue px-4 me-1">Login</NavLink>
                            <NavLink to="/sign-up" className="btn btn-orange px-4">Sign Up</NavLink>
                        </li> }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

function BackendHeader(){
    const History = useHistory()
    const [context, setContext] = useContext(Context)

    useEffect(() => {
        document.querySelectorAll("#menuToggle, #menuClose").forEach(function (el) {
            el.addEventListener('click', () => {
                document.body.classList.toggle('menu-open');
            })
        });
    }, [])
    const handleLogout = () => {
        fetchData('logout', 'POST', '', true, false, (res) => {
            if(res.status){
                setContext(prevState => ({
                    ...prevState,
                    auth: ''
                }))
                localStorage.removeItem("wt-token");
                History.push("/")
            }
        }, (err) => {})
    }

    return(
        <header className="admin-header">
            <div className="row m-0 h-100 align-items-center">
                <div className="col-auto">
                    <button type="button" className="btn mmwh-36px bg-grey p-0 d-flex align-items-center justify-content-center" id="menuToggle" title="Menu Toggle">
                        <i className="bi-list bi-8x lh-1"></i>
                    </button>
                </div>
                <div className="col-auto ms-auto">
                    <div className="dropdown dropdown-orange d-inline-block align-middle">
                        <button type="button" className="admin-profile position-relative" data-bs-toggle="dropdown" title="Notification">
                            <i className="bi-bell bi-4x"></i>
                            <span className="position-absolute top-0 mt-3 start-100 translate-middle p-1 bg-danger rounded-circle">
                            </span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-center shadow border-0 p-2">
                            <li><a href="student-profile.php" className="dropdown-item rounded my-1px">Notification #1</a></li>
                            <li><a href="student-profile.php" className="dropdown-item rounded my-1px">Notification #2</a></li>
                            <li><a href="student-profile.php" className="dropdown-item rounded my-1px">Notification #3</a></li>
                            <li><a href="student-profile.php" className="dropdown-item rounded my-1px">Notification #4</a></li>
                            <li><a href="student-profile.php" className="dropdown-item rounded my-1px">Notification #5</a></li>
                        </ul>
                    </div>
                    <div className="vr d-inline-block align-middle mx-3"></div>
                    <div className="dropdown dropdown-orange d-inline-block align-middle">
                        <button type="button" className="admin-profile" data-bs-toggle="dropdown">
                            <img src={context.auth.profile_image} alt='.' />
                            <div className="ms-2">
                                <h5>{ context.auth.name }</h5>
                                <p>{ context.auth.role.name }</p>
                            </div>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end shadow border-0 p-2">
                            <li><Link to="/change-password" className="dropdown-item rounded my-1px" title="Change Password">Change Password</Link></li>
                            <li>
                                <hr className="dropdown-divider opacity-10"/>
                            </li>
                            <li><button type="button" className="dropdown-item rounded my-1px" onClick={handleLogout} title="Logout">Logout</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default FrontendHeader
export { BackendHeader }