import {scrollbarSetToTop} from  "../components/Helper"
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Faq(){
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Faq</title>
        </Helmet>
        <section className="bg-blue py-4 position-relative overflow-hidden">
            <img src="images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="weteach"/>
            <div className="container position-relative zindex-2">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="heading-lines text-white m-0 pt-3">Faq</h2>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-section py-5">
            <div className="container py-sm-4">
                <div className="row g-4">
                <div className="col-sm-12 align-justify">
                            <h5 className="fw-bold">1. What are benefit to join ten courses?</h5>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            
                            <h5 className="fw-bold">2. What are benefit to join ten courses?</h5>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            
                            <h5 className="fw-bold">3. What are benefit to join ten courses?</h5>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            
                            <h5 className="fw-bold">4. What are benefit to join ten courses?</h5>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            
                            <h5 className="fw-bold">5. What are benefit to join ten courses?</h5>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            
                            

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Faq