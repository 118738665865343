import { useHistory, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import Autofi from './Autofi';
import Recipient from './Recipient';
import AutoMessage from './AutoMessage';
import RecipientList from './RecipientList';
import Manufi from './Manufi';

function NotificationList(){
    const history = useHistory()
    const listType = useParams().list_type

    const tabChange = (type) => {
        history.push(`/notification/${type}/list`)
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Notification Center</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Notification Center</h5>
                        </div>
                        <div className="col-auto mb-2 opacity-0">
                            <button type="button" className="btn btn-blue mb-1">&nbsp;</button>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={listType === 'autofi' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#autofibox" type="button" role="tab" data-tablename="autofi" onClick={() => tabChange('autofi')}>Autofi</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={listType === 'manufi' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#manufibox" type="button" role="tab" data-tablename="manufi" onClick={() => tabChange('manufi')}>Manufi</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={listType === 'auto-message' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#auto-messagebox" type="button" role="tab" data-tablename="auto-message" onClick={() => tabChange('auto-message')}>Auto Messages</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={listType === 'recipient-list' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#recipient-listbox" type="button" role="tab" data-tablename="recipient-list" onClick={() => tabChange('recipient-list')}>Recipients Lists</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={listType === 'recipient' ? 'nav-link text-blue fw-medium active' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="#recipientbox" type="button" role="tab" data-tablename="recipient" onClick={() => tabChange('recipient')}>Recipients</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border border-top-0">
                                        <div className={listType === 'autofi' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id='autofibox' role="tabpanel">
                                            <Autofi />
                                        </div>
                                        <div className={listType === 'manufi' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id='manufibox' role="tabpanel">
                                            <Manufi />
                                        </div>
                                        <div className={listType === 'auto-message' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id='auto-messagebox' role="tabpanel">
                                            <AutoMessage />
                                        </div>
                                        <div className={listType === 'recipient-list' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id='recipient-listbox' role="tabpanel">
                                            <RecipientList />
                                        </div>
                                        <div className={listType === 'recipient' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id='recipientbox' role="tabpanel">
                                            <Recipient />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default NotificationList
