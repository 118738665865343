import { Link} from "react-router-dom";
import { useHistory, useParams } from 'react-router';
import { useState, useEffect } from "react";
import { fetchData, validateForm } from "../../components/Helper";
import _ from 'lodash';
import { Helmet } from "react-helmet";

function EduClsEdit(){

    const history = useHistory()
    const id = useParams().id
    const [data, setData] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        fetchData(`educational-classification-update/${id}`, 'GET', '', true, false, (res) => {
                if(res.status){
                    setData(res.data)
                    setName(res.data.name)
                }
            }, (err) => {})
    }, [id])
    const handleUpdateForm = (e) => {
        e.preventDefault();
        if(validateForm(e)) { 
            fetchData(`educational-classification-update/${id}`, 'POST', {name: name}, true, false, (res) => {
                if(res.status){
                    history.push(`/educational-classification/${data.type}/list`)
                }
            }, (err) => {})
        }
    }
    
    

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Edit { _.upperFirst(data.type) }</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Edit { _.upperFirst(data.type) }</h5>
                        </div>
                        <div className="col-auto mb-2">
                        <Link to={`/educational-classification/${data.type}/list`} className="btn btn-blue px-3 mb-1 me-1" title={`${ _.upperFirst(data.type) } List`}> { _.upperFirst(data.type) } List</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body p-sm-4 p-xl-5">
                                    <form action="#" method="post" className=" needs-validation" noValidate>
                                        <div className="mb-4 row mx-0">
                                            <label htmlFor="name" className="col-sm-3 col-xxl-2 col-form-label">{ _.upperFirst(data.type) } Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="name" name="name" defaultValue={data.name} onChange={(e) => setName(e.target.value)} required/>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                                <button type="submit" className="btn btn-blue px-4 me-2 w-120px" onClick={handleUpdateForm} title="Update">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EduClsEdit