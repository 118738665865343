import ReactDOM from 'react-dom'
import { useEffect, useState } from "react"
import Datatables, { reloadDataTable, reloadUrlDataTable } from '../components/Datatables'
import { fetchData, validateForm, initialFormState } from '../components/Helper'
import DateRangeFilter from '../components/Filter';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { times } from 'lodash';
import $ from 'jquery'
import moment from 'moment';

function Payment(){
    const history = useHistory()
    const [paymentCategory, setPaymentCategory] = useState('course')
    const [wallet, setWallet] = useState(0)
    const [courseId, setCourseId] = useState(0)
    const [percentage, setPercentage] = useState({
        class_platform_percent: '',
        class_teacher_percent: '',
        course_platform_percent: '',
        course_teacher_percent: '',
    })
   
    const [payment_dt] = useState({
        dt_url: `payments`,
        dt_name: `payment`,
        dt_column: [
            { data:"transaction_id", name:'transaction_id', title: "Transaction ID"},
            { data:"course_name", name:'courses.name', title: "Course Name"},
            { data:"name", name:'users.name', title: "User Name"},
            { data:"payment_type", name:'payment_type', title: "Transaction Type", class: 'text-capitalize text-nowrap'},
            { data:"payment_method", name:'payment_method', title: "Transaction Method", class: 'text-capitalize text-nowrap'},
            { data:"payment_status", name:'payment_status', title: "Transaction Status", class: 'text-capitalize text-nowrap'},
            { data:"amount", name:'amount', title: "Amount(EGP)"},
            { data:"date", name:'payment_date', title: "Payment Date"},
        ],
        dt_column_defs: [

            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(<> 
                        <span>{rowData.course_name ? rowData.course_name : '-'}</span>
                    </>, td)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(<> 
                    { rowData.payment_status === "pending" ? <span className="text-warning fw-medium">Pending</span> : '' }
                    { rowData.payment_status === "success" ? <span className="text-success fw-medium">Success</span> : '' }
                    { rowData.payment_status === "failed" ? <span className="text-danger fw-medium">Failed</span> : '' }
                    { rowData.payment_status === "refgen" ? <span className="text-info fw-medium">Reference Code Generated</span> : '' }
                    </>, td)
                },
            }
        ],
        dt_order: [[ 7, 'desc' ]],
        dt_filter: () => {
            ReactDOM.render(
                <>
                    <div className="d-inline-block align-top me-2">
                        <DateRangeFilter daterange="All" name="p_daterange" reload={() => refreshDataTable('')} maxDate={new Date()} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getPDateRange} key={times()}/>
                    </div>
                </>, document.querySelector('#wt_datatable_payment_wrapper .dt-custom-filter')
            )
        }
    })
    const [course_payment_dt] = useState({
        dt_url: `payments?category=course`,
        dt_name: `course_payment`,
        dt_column: [
            { data:"id", name:'courses.id', title: "#ID"},
            { data:"name", name:'courses.name', title: "Course Name"},
            { data:"amount", name:'payments.amount', title: "Total Amount (EGP)"},
            { data:"status", name:'courses.status', title: "Course Status"},
            { data:"date", name:'courses.created_at', title: "Course Created Date"},
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    { rowData.status === "draft" ? <span className="text-primary fw-medium">Draft</span> : '' }
                    </>, td)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <button type="button" className="btn btn-dark py-2px m-2px" title="View Transaction" data-bs-target="#viewtransactionModal" data-bs-toggle="modal" onClick={() => showPaymentDetails(rowData.id)}>
                            View Transaction
                        </button>
                        <button type="button" className="btn btn-success py-2px m-2px" title="Set Percentage" data-bs-toggle="modal" data-bs-target="#setpercentage_modal" onClick={() => showModal(rowData.id)}>
                            Set Percentage
                        </button>
                    </>, td)
                },
            },

        ],
        dt_order: [[ 4, 'desc' ]],
        dt_filter: () => {
            ReactDOM.render(
                <>
                    <div className="d-inline-block align-top me-2">
                        <DateRangeFilter daterange="All" name="c_daterange" reload={() => refreshDataTable('course')} maxDate={new Date()} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getCDateRange} key={times()}/>
                    </div>
                </>, document.querySelector('#wt_datatable_course_payment_wrapper .dt-custom-filter')
            )
        }
    })
    const [platform_wallet_payment_dt] = useState({
        dt_url: `payments?category=platform_wallet`,
        dt_name: `platform_wallet_payment`,
        dt_column: [
            { data:"transaction_id", name:'transaction_id', title: "Trans ID", class: 'text-nowrap'},
            { data:"course_name", name:'courses.name', title: "Course Name"},
            { data:"p_amount", name:'p_amount', title: "Amount (EGP)"},
            { data:"date", name:'payment_date', title: "Date/Time"},
        ],
        dt_order: [[ 3, 'desc' ]],
        dt_filter: () => {
            ReactDOM.render(
                <>
                    <div className="d-inline-block align-top me-2">
                        <DateRangeFilter daterange="All" name="pw_daterange" reload={() => refreshDataTable('platform_wallet')} maxDate={new Date()} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getPWDateRange} key={times()}/>
                    </div>
                </>, document.querySelector('#wt_datatable_platform_wallet_payment_wrapper .dt-custom-filter')
            )
        }
    })
    const [wallet_payment_dt] = useState({
        dt_url: `payments?category=wallet`,
        dt_name: `wallet_payment`,
        dt_column: [
            { data:"teacher_id", name:'users.id', title: "#ID"},
            { data:"teacher_name", name:'users.name', title: "Teacher Name"},
            { data:"amount", name:'payments.amount', title: "Total Amount (EGP)"},
            { data:"teacher_status", name:'users.status', title: "Status"},
            { data:"teacher_id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) =>{
                    ReactDOM.render(<> 
                    { rowData.teacher_status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.teacher_status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    { rowData.teacher_status === "draft" ? <span className="text-primary fw-medium">Draft</span> : '' }
                    </>, td)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>
                        <button type="button" className="btn btn-success py-2px m-2px" data-bs-target="#viewCourseModal" data-bs-toggle="modal" title='View' onClick={() => showCoursePayment(rowData.teacher_id)}>
                            View
                        </button>                   
                    </>, td)
                }
            }
        ],
        // dt_order: [[ 4, 'desc' ]],
        dt_filter: () => {
            ReactDOM.render(
                <>
                    <div className="d-inline-block align-top me-2">
                        <DateRangeFilter daterange="All" name="w_daterange" reload={() => refreshDataTable('wallet')} maxDate={new Date()} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getWDateRange} key={times()}/>
                    </div>
                </>, document.querySelector('#wt_datatable_wallet_payment_wrapper .dt-custom-filter')
            )
        }
    })

    useEffect(() => {
        reloadDataTable(course_payment_dt)
    }, [course_payment_dt])

    const handlePaymentCategory = (category) => {
        localStorage.removeItem('start_date')
        localStorage.removeItem('end_date')
        setPaymentCategory(category)
        refreshDataTable(category)
    }

    const showPaymentDetails = (id) => {
        localStorage.setItem('filter_id', id)
        let start_date = localStorage.getItem('start_date')
        let end_date = localStorage.getItem('end_date')
        if(start_date && end_date){
            $('input[name="td_daterange"]').val(`${moment(start_date).format('MMM DD, YYYY')} - ${moment(end_date).format('MMM DD, YYYY')}`)
        }else{
            $('input[name="td_daterange"]').val('All')
        }
        
        let  transaction_details_dt = {
            dt_url: start_date && end_date ? `payments?category=${id}&startDate=${start_date}&endDate=${end_date}` : `payments?category=${id}`,
            dt_name: 'transaction_details',
            
            dt_column: [
                { data:"date", name:'payment_date', title: "Date/Time"},
                { data:"transaction_id", name:'transaction_id', title: "Trans ID", class: 'text-nowrap'},
                { data:"name", name:'users.name', title: "Student Name", class: 'text-nowrap'},
                { data:"payment_type", name:'payment_type', title: "Trans Type", class: 'text-capitalize text-nowrap'},
                { data:"payment_method", name:'payment_method', title: "Method", class: 'text-capitalize'},
                { data:"join_type", name:'student_courses.join_type', title: "Join As", class: 'text-capitalize text-nowrap'},
                { data:"amount", name:'amount', title: "Amount (EGP)", class: 'text-nowrap'},
                { data:"p_amount", name:'p_amount', title: "Platform(%)"},
                { data:"t_amount", name:'t_amount', title: "Teacher(%)"},
                { data:"description", name:'description', title: "Description", class:"mmw-200px"},
            ],
            dt_order: [[ 0, 'desc' ]],
            dt_filter: () => {
                ReactDOM.render(
                    <>
                        <div className="d-inline-block align-top me-2">
                            <DateRangeFilter daterange={start_date && end_date ? `${moment(start_date).format('MMM DD, YYYY')} - ${moment(end_date).format('MMM DD, YYYY')}` : 'All'} name="td_daterange" maxDate={new Date()} reload={refreshDataTable} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getTdDateRange} key={times()}/>
                        </div>
                    </>, document.querySelector('#wt_datatable_transaction_details_wrapper .dt-custom-filter')
                )
            }
        }
        reloadUrlDataTable(transaction_details_dt, start_date && end_date ? `payments?category=${id}&startDate=${start_date}&endDate=${end_date}` : `payments?category=${id}`)


        let getTdDateRange = (startDate, endDate, id) => {
            reloadUrlDataTable(transaction_details_dt, `payments?category=${id}&startDate=${startDate}&endDate=${endDate}`)
        }

        let refreshDataTable = () => {
            $('input[name="td_daterange"]').val('All')
            reloadUrlDataTable(transaction_details_dt, `payments?category=${localStorage.getItem('filter_id')}`)
        }
    }

    const showCoursePayment = (id) => {
        localStorage.setItem('filter_id', id)
        let start_date = localStorage.getItem('start_date')
        let end_date = localStorage.getItem('end_date')
        if(start_date && end_date){
            $('input[name="wd_daterange"]').val(`${moment(start_date).format('MMM DD, YYYY')} - ${moment(end_date).format('MMM DD, YYYY')}`)
        }else{
            $('input[name="wd_daterange"]').val('All')
        }

        let course_transaction_details_dt = {
            dt_url: start_date && end_date ? `course-payment/${id}?startDate=${start_date}&endDate=${end_date}` : `course-payment/${id}`,
            dt_name: 'course_transaction_details',
            
            dt_column: [
                { data:"course_id", name:'payments.course_id', title: "#ID"},
                { data:"name", name:'courses.name', title: "Course Name"},
                { data:"amount", name:'amount', title: "Total Teacher Amount (EGP)"},
                { data:"platform_amount", name:'amount', title: "Total Platform Amount (EGP)"},
                { data:"date", name:'payment_date', title: "Payment Date"},
            ],
            dt_order: [[ 3, 'desc' ]],
            dt_filter: () => {
                ReactDOM.render(
                    <>
                        <div className="d-inline-block align-top me-2">
                            <DateRangeFilter daterange={start_date && end_date ? `${moment(start_date).format('MMM DD, YYYY')} - ${moment(end_date).format('MMM DD, YYYY')}` : 'All'} name="wd_daterange" reload={refreshDataTable} maxDate={new Date()} className="form-control form-control-sm" disableUpdateInput={false} getDateRange={getWdDateRange} key={times()}/>
                        </div>
                    </>, document.querySelector('#wt_datatable_course_transaction_details_wrapper .dt-custom-filter')
                )
            }
        }
        reloadUrlDataTable(course_transaction_details_dt, start_date && end_date ? `course-payment/${id}?startDate=${start_date}&endDate=${end_date}` : `course-payment/${id}`)

        let getWdDateRange = (startDate, endDate, id) => {
            reloadUrlDataTable(course_transaction_details_dt, `course-payment/${id}?startDate=${startDate}&endDate=${endDate}`)
        }

        let refreshDataTable = () => {
            $('input[name="wd_daterange"]').val('All')
            reloadUrlDataTable(course_transaction_details_dt, `course-payment/${localStorage.getItem('filter_id')}`)
        }
    }

    const getPDateRange = (startDate, endDate) => {
        reloadUrlDataTable(payment_dt, `payments?startDate=${startDate}&endDate=${endDate}`)
    }

    const getPWDateRange = (startDate, endDate) => {
        reloadUrlDataTable(platform_wallet_payment_dt, `payments?category=platform_wallet&startDate=${startDate}&endDate=${endDate}`)
    }

    const getCDateRange = (startDate, endDate) => {
        localStorage.setItem('start_date', startDate)
        localStorage.setItem('end_date', endDate)
        reloadUrlDataTable(course_payment_dt, `payments?category=course&startDate=${startDate}&endDate=${endDate}`)
    }

    const getWDateRange = (startDate, endDate) => {
        localStorage.setItem('start_date', startDate)
        localStorage.setItem('end_date', endDate)
        reloadUrlDataTable(wallet_payment_dt, `payments?category=wallet&startDate=${startDate}&endDate=${endDate}`)
    }

    const showModal = (id) => {
        initialFormState("percentForm", percentage)
        setCourseId(id)
        fetchData(`set-percent/${id}`, 'get', '', true, false, (res) => {
            if(res.status){
                setPercentage({
                    class_platform_percent: res.data.class_platform_percent,
                    class_teacher_percent: res.data.class_teacher_percent,
                    course_platform_percent: res.data.course_platform_percent,
                    course_teacher_percent: res.data.course_teacher_percent,
                })
            }
        }, (err) => {})
    }

    const handleInputChange = (e) => {
        document.getElementById(e.target.name).classList.remove('is-invalid');
        setPercentage(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmitForm = (e) => {
        if(validateForm(e)) {
            let formData = {...percentage, 'pathname': history.location.pathname}
            fetchData(`set-percent/${courseId}`, 'POST', formData, true, false, (res) => {
                if(res.status){
                    $("#setpercentage_modal").modal('hide'); 
                }
            }, (err) => {})
        }
    }

    const refreshDataTable = (category) => {
        if(category === 'course') {
            localStorage.removeItem('start_date')
            localStorage.removeItem('end_date')
            $('input[name="c_daterange"]').val('All')
            reloadUrlDataTable(course_payment_dt, `payments?category=course`)
        }else if(category === 'platform_wallet') {
            $('input[name="pw_daterange"]').val('All')
            fetchData(`get-wallet/0`, 'GET', '', true, false, (res) => {
                if(res.status){
                    setWallet(res.data && res.data.wallet_amount > 0 ? res.data.wallet_amount : 0)
                }
            }, (err) => {});
            reloadUrlDataTable(platform_wallet_payment_dt, `payments?category=platform_wallet`)
        }else if(category === 'wallet') {
            localStorage.removeItem('start_date')
            localStorage.removeItem('end_date')
            $('input[name="w_daterange"]').val('All')
            reloadUrlDataTable(wallet_payment_dt, `payments?category=wallet`)
        }else if(category === 'report') {
            setPaymentCategory(category)
        }else{
            $('input[name="p_daterange"]').val('All')
            reloadUrlDataTable(payment_dt, `payments`)
        }
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Payments</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Payments</h5>
                        </div>
                        <div className="col-auto mb-2 opacity-0 pe-none">
                            <button type="button" className="btn btn-blue mb-1">&nbsp;</button>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={paymentCategory === 'course' ? 'nav-link text-blue active fw-medium' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="coursesbox" type="button" role="tab" onClick={() => handlePaymentCategory('course')} title='Courses'>Courses</button>
                                        </li>
                                        
                                        <li className="nav-item" role="presentation">
                                            <button className={paymentCategory === '' ? 'nav-link text-blue active fw-medium' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="alltransactionbox" type="button" role="tab" onClick={() => handlePaymentCategory('')} title='All Transactions'>All Transactions</button>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <button className={paymentCategory === 'platform_wallet' ? 'nav-link text-blue active fw-medium' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="platform_walletbox" type="button" role="tab" onClick={() => handlePaymentCategory('platform_wallet')} title='Platform Wallet'>Platform Wallet</button>
                                        </li>

                                        <li className="nav-item" role="presentation">
                                            <button className={paymentCategory === 'wallet' ? 'nav-link text-blue active fw-medium' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target="walletbox" type="button" role="tab" onClick={() => handlePaymentCategory('wallet')} title='Wallets'>Wallets</button>
                                        </li>
                                    </ul>

                                    <div className="tab-content border border-top-0">
                                        <div className={paymentCategory === 'course' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id="#coursesbox" role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="course_payment"/>
                                            </div>
                                        </div>

                                        <div className={paymentCategory === '' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id="#alltransactionbox" role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="payment"/>
                                            </div>
                                        </div>

                                        <div className={paymentCategory === 'platform_wallet' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id="#platform_walletbox" role="tabpanel">
                                            <div className="row mx-0 bg-dark py-3 mb-3 rounded align-items-center fw-light position-relative overflow-hidden text-white fs-4">
                                                <img src="/images/courses-bg.png" className="position-absolute top-0 opacity-10 zindex-0 pointer-none" alt="Topup" />
                                                <div className="col-sm-12 col-lg-4 zindex-1 position-relative">
                                                    Platform Balance:
                                                </div>
                                                <div className="col-6 col-lg-4 fw-semibold lh-1 position-relative">
                                                    EGP {wallet}
                                                </div>
                                            </div>
                                            
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="platform_wallet_payment"/>
                                            </div>
                                        </div>

                                        <div className={paymentCategory === 'wallet' ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3 show'} id="#walletbox" role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="wallet_payment"/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="viewtransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title"> Transaction Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                    
                        <div className="modal-body p-sm-4">
                            <div className="table-responsive p-1">
                                <Datatables dt_name="transaction_details"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="viewCourseModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">Transaction Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                    
                        <div className="modal-body p-sm-4">
                            <div className="table-responsive p-1">
                                <Datatables dt_name="course_transaction_details"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="setpercentage_modal" tabIndex="-1" data-bs-backdrop="static" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title fw-bold">Set Percentage</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                        </div>
                        <div className="modal-body pt-md-4">
                            <form action="#" method="post" id="percentForm" className="needs-validation" noValidate>
                                <div className="row g-md-5 mx-md-0">
                                    <div className="col-6 border-end">
                                        <h5 className="mb-3 fw-medium">Course</h5>
                                        <label className="mb-3">
                                            <div className="d-block">Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></div>
                                            <div className="input-group">
                                                <input type="text" className="form-control" defaultValue={percentage.course_teacher_percent} id="course_teacher_percent" name="course_teacher_percent" onChange={handleInputChange} placeholder="" required />
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </label>
                                        <label className="mb-1">
                                            <div className="d-block">Platform<sup className='text-danger fw-bold fs-15px'>*</sup></div>
                                            <div className="input-group">
                                                <input type="text" className="form-control" defaultValue={percentage.course_platform_percent} id="course_platform_percent" name="course_platform_percent" onChange={handleInputChange} placeholder="" required />
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <h5 className="mb-3 fw-medium">Class</h5>
                                        <label className="mb-3">
                                            <div className="d-block">Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></div>
                                            <div className="input-group">
                                                <input type="text" className="form-control" defaultValue={percentage.class_teacher_percent} id="class_teacher_percent" name="class_teacher_percent" onChange={handleInputChange} placeholder="" required />
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </label>
                                        <label className="mb-1">
                                            <div className="d-block">Platform<sup className='text-danger fw-bold fs-15px'>*</sup></div>
                                            <div className="input-group">
                                                <input type="text" className="form-control" defaultValue={percentage.class_platform_percent} id="class_platform_percent" name="class_platform_percent" onChange={handleInputChange} placeholder="" required />
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-12 mt-3 py-3 text-end">
                                        <button type="button" className="btn btn-light border mmw-120px me-1" data-bs-dismiss="modal" title='Cancel'>Cancel</button>
                                        <button type="button" className="btn btn-success mmw-120px" onClick={handleSubmitForm} title='Save'>Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payment
