import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import $ from 'jquery';
import { showAlert } from "./Helper";

const initialJoin = (client, meetingSDKElement) => {
  try{
    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: 'en-US',
      customize: {
        meetingInfo: ['topic', 'host', 'participant', 'dc', 'enctype'],
      }
    });
  }catch(e){
    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: 'en-US',
    });   
  }
}
export const Zoom = (zoomData, res) => {
  const client = ZoomMtgEmbedded.createClient();
  const crypto = require('crypto')
  const meeting_number = zoomData.meeting_id
  const generateSignature = (apiKey, apiSecret, meetingNumber, role) => {
    const timestamp = new Date().getTime() - 30000
    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64')
    const hash = crypto.createHmac('sha256', apiSecret).update(msg).digest('base64')
    const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64')
    return signature  
  }

  window.addEventListener('click', ()=> {
    if(document.querySelector('.MuiDialog-root #error-dialog-title')!==null){
      $('.zm-modal').removeClass('active')
      $('body').removeClass('zoom-open')
      client.leaveMeeting()
    }
  })


  let signature = generateSignature(zoomData.api_key, zoomData.api_secret, meeting_number, zoomData.role)
  let meetingSDKElement = document.getElementById('meetingSDKElement');
  
  initialJoin(client, meetingSDKElement)

  $('.zm-modal').addClass('active');
  $('body').addClass('zoom-open');
  return client.join({
    apiKey: zoomData.api_key,
    signature: signature,
    meetingNumber: meeting_number,
    password: zoomData.password,
    userName: zoomData.username,
    userEmail: zoomData.useremail,
  }).then((s) => {
    res(true)
  }, (e) => {
    let closebtn = document.getElementById("closeZoomBtn")
    if(closebtn){
      closebtn.click()
    }
    if(e && e.reason){
      let msg = {message:e.reason, status:false}
      showAlert(msg)
    }else{
      let msg = {message:"Unable to start meeting due to internal error.", status:false}
      showAlert(msg)
    }
    res(false)
  });
}
export const zoomEnd = (role_id) => {
  const client = ZoomMtgEmbedded.createClient();
  $('.zm-modal').removeClass('active')
  $('body').removeClass('zoom-open')
  if((role_id === 4 || role_id === 3)){
    client.endMeeting()
    return true
  }else{
    client.leaveMeeting()
    return false
  }
}
