import { Link } from "react-router-dom";
import { useHistory } from "react-router"
import { useEffect, useState } from "react";
import $ from 'jquery'
import { fetchData } from "../Helper";
window.jQuery = $
require('owl.carousel/dist/assets/owl.carousel.css');
require('owl.carousel');

function CourseCarousel(){ 
    const [course, setCourse] = useState(''); 
    const history = useHistory();

    useEffect(() => {
        $('#courseslides').owlCarousel({
            stagePadding: 0,
            margin: 22,
            items: 1,
            loop: false,
            dots: false,
            nav: true,
            responsive: {
                300: {
                    dots: true,
                    nav: false,
                },
                480: {
                    items: 2,
                    dots: true,
                    nav: false,
                },
                768: {
                    items: 3
                },
                1199: {
                    items: 4
                }
            }
        });

        fetchData('courses/yes', 'GET', '', false, false, (res) => {
            if(res.status && res.data){
                setCourse(res.data)
                $('#courseslides').owlCarousel("destroy")
                $('#courseslides').owlCarousel({
                    stagePadding: 0,
                    margin: 22,
                    items: 1,
                    loop: false,
                    dots: false,
                    nav: true,
                    responsive: {
                        300: {
                            dots: true,
                            nav: false,
                        },
                        480: {
                            items: 2,
                            dots: true,
                            nav: false,
                        },
                        768: {
                            items: 3
                        },
                        1199: {
                            items: 4
                        }
                    }
                });
            }
        }, (err) => {})
    }, [])

    const handleJoinCourse = (id) => {
        history.push(`/course/${id}/join`) 
    }

    return(
        <>
            <div className="owl-carousel owl-arrow-dots" id="courseslides">
            { course && course.length ? course.map((course_details, i) => {
            return(
                    <div className="item"> 
                        <div className="course-item">
                            <div className="ratio ratio-4x3 bg-light">
                                <img src={course_details.course_image} className="w-100 h-100 img-cover" alt="weteach" />
                            </div>
                            <span className="course-tag">{course_details.subject_name}</span>
                            <div className="course-avatar d-flex justify-content-between align-items-center">
                            {course_details.teacher_image ?  <img src={course_details.teacher_image} className="mmw-50px" alt="weteach" /> : ''}
                                <span>{(course_details.class_price ===  'EGP 0.00' || course_details.class_price ===  'EGP 0') ? course_details.course_price : course_details.class_price }</span>
                            </div>
                            <div className="p-3">
                                <h6 className="course-heading">{course_details.name}</h6>
                                <p className="course-description minh-72px">
                                    {course_details.description}
                                </p>
                                <div className="pt-3 d-flex justify-content-between align-items-center">
                                { course_details.teacher_name ? 
                                    <Link to={`/teacher-info/${course_details.teacher_id}`} className="fs-14px mb-0 text-muted text-hoverorange text-truncate" title={`By ${course_details.teacher_name }`}>
                                        By {course_details.teacher_name }
                                    </Link> 
                                    : ''
                                }
                                    <button onClick={() => handleJoinCourse(course_details.id)} className="btn btn-outline-blue btn-sm fw-medium text-nowrap" title="Join Course">Join Course</button>
                                </div>
                            </div>
                        </div> 
                    </div>
                )
            }) : 
            Array.from(Array(4)).map((c, index) => 
                <div className="item"> 
                    <div className="course-item placeholder-wave">
                        <div className="ratio ratio-4x3 bg-light">
                            <div src="" className="w-100 h-100 img-cover placeholder" alt="weteach"></div>
                        </div>
                        <span className="course-tag placeholder w-25"></span>
                        <div className="course-avatar d-flex justify-content-between align-items-center">
                            <div src="" className="mmw-50px placeholder" alt="weteach"></div>
                        </div>
                        <div className="p-3">
                            <h6 className="course-heading placeholder w-50">&nbsp;</h6>
                            <p className="course-description placeholder"></p>
                            <p className="course-description placeholder w-25"></p>
                            <div className="pt-3 d-flex justify-content-between align-items-center ">
                                <span className="fs-14px mb-0 text-muted text-hoverorange text-truncate placeholder w-25"></span> 
                                <button className="btn btn-outline-blue btn-sm fw-medium text-nowrap disable placeholder w-25"></button>
                            </div>
                        </div>
                    </div> 
                </div> ) }
            </div>
        </>
    )
}

export default CourseCarousel
