import { useContext, useEffect, useState } from 'react';
import ChartComponant from '../components/ChartComponant';
import { fetchData } from "../components/Helper"
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import DateRangeFilter from '../components/Filter';
import Context from '../components/Context';

function Dashboard(){
    const [context] = useContext(Context)
    const color = ['success', 'warning', 'primary', 'danger']
    const classStatus = ['Active Classes', 'Pending Classes', 'Draft Classes', 'Over Classes']
    const [dashboardData, setDashboardData] = useState()
    const [bgColor] = useState([
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 205, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(201, 203, 207, 0.7)'
    ])

    const [dateRange, setDateRange] = useState(['', ''])
    const [startDate, endDate] = dateRange

    const studentRecord = {
        labels: dashboardData ? Object.keys(dashboardData.studentRecord) : '',
        datasets: [{
            label: 'Student',
            data: dashboardData ? Object.values(dashboardData.studentRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const parentRecord = {
        labels: dashboardData ? Object.keys(dashboardData.parentRecord) : '',
        datasets: [{
            label: 'Parent',
            data: dashboardData ? Object.values(dashboardData.parentRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };
    
    const teacherRecord = {
        labels: dashboardData ? Object.keys(dashboardData.teacherRecord) : '',
        datasets: [{
            label: 'Teacher',
            data: dashboardData ? Object.values(dashboardData.teacherRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const assistantRecord = {
        labels: dashboardData ? Object.keys(dashboardData.assistantRecord) : '',
        datasets: [{
            label: 'Teacher',
            data: dashboardData ? Object.values(dashboardData.assistantRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const adminRecord = {
        labels: dashboardData ? Object.keys(dashboardData.adminRecord) : '',
        datasets: [{
            label: 'Teacher',
            data: dashboardData ? Object.values(dashboardData.adminRecord) : '',
            backgroundColor: _.shuffle(bgColor),
            hoverOffset: 4
        }]
    };

    const courseRecord = {
        labels: dashboardData ? Object.keys(dashboardData.courseRecord) : '',
        datasets: [{
            label: 'Course',
            data: dashboardData ? Object.values(dashboardData.courseRecord) : '',
            backgroundColor:  _.shuffle(bgColor),
        }]
    }

    const paymentRecord = {
        labels: dashboardData ? Object.keys(dashboardData.paymentRecord.success) : '',
        datasets: [
            {
                label: dashboardData ? Object.values(dashboardData.paymentRecord.success_amount) : '',
                label2: 'Success',
                data: dashboardData ? Object.values(dashboardData.paymentRecord.success)  : '',
                backgroundColor: 'rgba(75, 192, 192, 0.7)',
            },
            {
                label: dashboardData ? Object.values(dashboardData.paymentRecord.pending_amount) : '',
                label2: 'Pending',
                data: dashboardData ? Object.values(dashboardData.paymentRecord.pending) : '',
                backgroundColor: 'rgba(255, 159, 64, 0.7)',
            },
            {
                label: dashboardData ? Object.values(dashboardData.paymentRecord.failed_amount): '',
                label2: 'Failed',
                data: dashboardData ? Object.values(dashboardData.paymentRecord.failed) : '',
                backgroundColor: 'rgba(255, 99, 132, 0.7)',
            }
        ]
    };

    const scales = {
                
        x: {
            grid: {
                color: 'transparent',
                borderColor: 'transparent'
            },
            ticks: {
                display: false,
            }
        },
        y: {
            beginAtZero: true,
            grid: {
                color: 'transparent',
                borderColor: 'transparent'
            },
            ticks: {
                display: false,
                crossAlign: 'far',
            }
        }
    }

    const plugins = {
        legend: {
            display: false,
            position: 'top',
        },
        label: {
            display: false
        },
    }

    const payment_plugins = {
        legend: {
            display: false,
            position: 'top',
        },
        label: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: function(labelContext) {                    
                    let label = labelContext.dataset.label[labelContext.dataIndex]
                    let type = labelContext.dataset.label2
                    let data = labelContext.dataset.data[labelContext.dataIndex]
                    return type +': '+ label +' '+(context && context.site && context.site.currency ? context.site.currency : 'EGP')+' ('+ data +')';
                }
            }
        }
    }

    const getDateRange = (startDate, endDate) => {
        setDashboardData('')
        setDateRange([startDate, endDate])
    }

    useEffect(() => {
            fetchData(`statistics?startDate=${startDate}&endDate=${endDate}`, 'GET', '', true, false, (res) => {
                if(res.status){
                    setDashboardData(res.data)
                }
            }, (err) => {})
    }, [startDate, endDate]);
    
    const refreshData = () => {
        document.querySelector('input[name="d_daterange"]').value = 'All'
        fetchData(`statistics`, 'GET', '', true, false, (res) => {
            if(res.status){
                setDashboardData(res.data)
            }
        }, (err) => {})
    }
    return(
            <section className="admin-wrapper">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>WeTeach | Dashboard</title>
                </Helmet>
            <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
                    <div className="col mb-2">
                        <h5 className="m-0 heading-lines fw-medium pb-2">Dashboard</h5>
                    </div>
                    <div className="col col-auto mb-2">
                        <div className="d-flex mb-3">
                        <DateRangeFilter 
                            daterange="All"

                            className="form-control"
                            maxDate={new Date()}
                            opens="left"
                            name='d_daterange'
                            reload={refreshData}
                            disableUpdateInput={false}
                            getDateRange={getDateRange}
                        />
                        </div>
                    </div>
                </div>

            {
                dashboardData ? 
                    <>
                    <div className="row g-3 g-xl-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5">
                        <div className="col">
                            <div className="card border-0 shadow-sm rounded-10 h-100">
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Students</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalStudent : ''}</h2>
                                        <div className="ratio ratio-1x2 mmw-180px">
                                            <ChartComponant key="1" id="student_chart" plugins={plugins} label={studentRecord.labels} scale ={scales} data={studentRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 shadow-sm rounded-10 h-100">
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Parents</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalParent : ''}</h2>
                                        <div className="ratio ratio-1x2 mmw-180px">
                                            <ChartComponant key="2" id="parent_chart" plugins={plugins} label={parentRecord.labels} scale ={scales} data={parentRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 shadow-sm rounded-10 h-100">
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Teachers</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalTeacher : ''}</h2>
                                        <div className="ratio ratio-1x1 mmw-90px">
                                            <ChartComponant key="3" id="teacher_chart" plugins={plugins} label={teacherRecord.labels} scale ={scales} data={teacherRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 shadow-sm rounded-10 h-100">
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Assistant</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalAssistant : ''}</h2>
                                        <div className="ratio ratio-1x1 mmw-90px">
                                            <ChartComponant key="4" id="assistant_chart" plugins={plugins} label={assistantRecord.labels} scale ={scales} data={assistantRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card border-0 shadow-sm rounded-10 h-100">
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Admin</h6>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                                        <h2 className="m-0">{dashboardData ? dashboardData.totalAdmin : ''}</h2>
                                        <div className="ratio ratio-1x1 mmw-90px">
                                            <ChartComponant key="5" id="admin_chart" plugins={plugins} label={adminRecord.labels} scale ={scales} data={adminRecord} type="pie" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 g-xl-4 mt-1">
                        <div className="col-md-6 col-xl-3">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">Courses</h6>
                                    <h2 className="m-0">{dashboardData ? dashboardData.totalCourse : ''}</h2>
                                    <div className="ratio ratio-1x1">
                                        <ChartComponant key="7" id="chart_doughnut" plugins="" data={courseRecord} scale ={scales} type="doughnut" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-9">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <div className='mb-2'>
                                            <h6 className="text-muted fw-normal mb-1">Total Transactions</h6>
                                            <div>
                                                <b>{dashboardData ? dashboardData.all_transaction.count : ''}</b>
                                                <small> ({dashboardData && dashboardData.all_transaction.amount ? dashboardData.all_transaction.amount : 0 } {context && context.site && context.site.currency ? context.site.currency : 'EGP'})</small>
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <h6 className="text-muted fw-normal mb-1">Success Transactions</h6>
                                            <div>
                                                <b>{dashboardData ? dashboardData.success_transaction.count : ''}</b>
                                                <small> ({dashboardData && dashboardData.success_transaction.amount ? dashboardData.success_transaction.amount : 0 } {context && context.site && context.site.currency ? context.site.currency : 'EGP'})</small>
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <h6 className="text-muted fw-normal mb-1">Pending Transactions</h6>
                                            <div>
                                                <b>{dashboardData ? dashboardData.pending_transaction.count : ''}</b>
                                                <small> ({dashboardData && dashboardData.pending_transaction.amount ? dashboardData.pending_transaction.amount : 0 } {context && context.site && context.site.currency ? context.site.currency : 'EGP'})</small>
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <h6 className="text-muted fw-normal mb-1">Failed Transactions</h6>
                                            <div>
                                                <b>{dashboardData ? dashboardData.failed_transaction.count : ''}</b>
                                                <small> ({dashboardData && dashboardData.failed_transaction.amount ? dashboardData.failed_transaction.amount : 0 } {context && context.site && context.site.currency ? context.site.currency : 'EGP'})</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ratio ratio-1in3">
                                        <ChartComponant key="5" id="chart_bar2" plugins={payment_plugins} data={paymentRecord} scale ={{y:{grid:{color: 'transparent',borderColor:'#f2f2f2'}}}} type="bar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-9">
                            <div className="card border-0 shadow-sm rounded-10 h-100">
                                <div className="card-body">
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <div className='mb-2'>
                                            <h6 className="text-muted fw-normal mb-1">Total Timing</h6>
                                            <div>
                                                <b>{dashboardData ? dashboardData.totalTiming : ''}</b>
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <h6 className="text-muted fw-normal mb-1">Total Classes</h6>
                                            <div>
                                                <b>{dashboardData ? dashboardData.totalClass : ''}</b>
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <h6 className="text-muted fw-normal mb-1">Active Recording</h6>
                                            <div>
                                                <b>{dashboardData ? dashboardData.recordingRecord.Active : ''}</b>
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <h6 className="text-muted fw-normal mb-1">Draft Recording</h6>
                                            <div>
                                                <b>{dashboardData ? dashboardData.recordingRecord.Draft : ''}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="progress my-3 opacity-75" style={{'height': '10px'}}>
                                        {
                                            dashboardData && dashboardData.classRecord ? Object.values(dashboardData.classRecord).map((value, key) => {
                                                return (
                                                    <div key={key} className={`progress-bar bg-${color[key]}`} role="progressbar" style={{'width': value === 0 ? 0 : ((value *100) / dashboardData.totalClass)+'%'}} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100"></div>
                                                )
                                            }) : ''

                                        }
                                    </div>
                                    <div className="list-inline fs-13px fw-bold">
                                        {
                                            dashboardData && dashboardData.classRecord ? Object.values(dashboardData.classRecord).map((value, key) => {
                                                return (
                                                    <div key={key} className={`list-inline-item d-inline-flex align-items-center text-${color[key]}`}>
                                                        <span className={`d-inline-block bg-${color[key]} rounded-circle ratio ratio-1x1 mmw-7px me-1`}></span>
                                                        {classStatus[key]} ( {value} )
                                                    </div>
                                                )
                                            }) : ''

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <div className="card border-0 shadow-sm rounded-10 text-decoration-none h-100">
                                <div className="card-body">
                                    <h6 className="text-muted fw-normal">All Accounts</h6>
                                    <h2 className="m-0 text-body">{dashboardData ? dashboardData.totalAccount : ''}</h2>
                                    <i className="bi-person-circle fs-60px position-absolute opacity-10 top-50 end-0 translate-middle text-body zindex-0" role="img" aria-label="People"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                    </>
                : ''
            }
            
        </div>
    </section>
  )
}

export default Dashboard