import React from 'react'
import { useHistory } from "react-router"
import Context from '../../components/Context';
import { useEffect, useState, useContext } from "react";
import { fetchData, validateForm, initialFormState, downloadFile, showAlert, decryptCode } from '../../components/Helper'
import Pagination from '../../components/Pagination';
import _, { isEmpty } from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import {Zoom, zoomEnd} from '../../components/Zoom';

function MaterialDetails(props) {
    const history = useHistory()
    const userId = props.student_id
    const courseId = props.course_id
    const materialType = props.material
    const [context] = useContext(Context)
    const roleId = context.auth.role_id

    const [materialList, setMaterialList] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [statusType, setStatusType] = useState('active')
    const [reload, setReload] = useState(1)

    const [data, setData] = useState();
    const [scheduleTimeEnable, setscheduleTimeEnable] = useState(false);
    const [docType, setDocType] = useState(true);
    const [opPerform, setOpPerform] = useState('create');
    const [materialId, setMaterialId] = useState('create');
    const [submissionData, setSubmissionData] = useState()
    const [paymentData, setPaymentData] = useState()
    const [classId, setClassId] = useState()


    useEffect(() => {
        if (context && context.auth) {
            fetchData(`subject-type-list?id=${courseId}&type=${materialType}&user_id=${userId}&status=${statusType}&page=${currentPage}`, 'GET', '', true, false, (res) => {
                setMaterialList(res.data)
                document.getElementById('group-title').innerHTML = res.data && res.data[1] ? res.data[1] : '';
            }, (err) => {});
        }
        setInterval(reloadAfterSomeTime, 10000)
    }, [context, currentPage, statusType, reload, props.student_id, courseId, materialType, userId])

    const reloadAfterSomeTime = () => {
        if(history.location.pathname.match('/timing/details')){
            fetchData(`subject-type-list?id=${courseId}&type=${materialType}&user_id=${userId}&status=${statusType}&page=${currentPage}`, 'GET', '', true, false, (res) => { setMaterialList(res.data) }, (err) => {}, false, false);
        }
    }
    const handlePageClick = (data) => {
        setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0)
	}

    const openModal = (modal_name, type) => {
        initialFormState(modal_name, data)
        setOpPerform(type)
        setscheduleTimeEnable(false)
    }

    const handleInputChange = (e, key = false) => {
        if(_.isDate(e)){
            document.getElementById(key).classList.remove('is-invalid'); 
            setData(prevState => ({
                ...prevState,
                [key]: e
            }))
        }else{
            document.getElementById(e.target.name).classList.remove('is-invalid'); 
            setData(prevState => ({
                ...prevState,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }))
            if(e.target.name === 'upload_data'){
                setData(prevState => ({
                    ...prevState,
                    pre_uploaded_task: ''
                }))
            }
        } 
    }

    const clientSideValidation = (e, data, formId ="materialForm") => {
        if(validateForm(e, formId)){
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
                if((key === 'due_date' || key === 'schedule_time') && value){
                    formData.append(key, value.toISOString())
                }else{
                    formData.append(key, value)
                }
            }
            formData.append('pathname', history.location.pathname)
            return formData
        }else{
            return false
        }
    }
    
    const saveData = (e) => {
        let allData = { ...data, 'course_id': courseId, 'module_type': materialType, 'status': 'active' }
        let validData = clientSideValidation(e, allData)
        if(validData){
            fetchData('add-material', 'POST', validData, true, true, (res) => {
                if (res.status) {
                    pageReload('materialForm', data, '#materialModal')
                }
            }, (err) => {})
        }
    }

    const saveAsDraft = (e) => {
        let allData = { ...data, 'course_id': courseId, 'module_type': materialType, 'status': 'draft' }
        let validData = clientSideValidation(e, allData)
        if(validData){
            fetchData('add-material', 'POST', validData, true, true, (res) => {
                if (res.status) {
                    pageReload('materialForm', data, '#materialModal')
                }
            }, (err) => {})
        }
    }

    const savescheduleData = (e) => {
        setscheduleTimeEnable(true)
        let ele = $('#schedule_time').val()
        if(!ele){
            return false
        }
        let allData = { ...data, 'course_id': courseId, 'module_type': materialType }
        let validData = clientSideValidation(e, allData)
        if(validData){
            fetchData('add-material', 'POST', validData, true, true, (res) => {
                if (res.status) {
                    pageReload('materialForm', data, '#materialModal')
                }
            }, (err) => {})
        }
    }

    const editMaterial = (id) => {
        openModal('materialForm', 'edit')
        fetchData(`update-material/${id}`, 'GET', '', true, false, (res) => {
            if (res.status) {
                setData({
                    id: res.data.id,
                    name: res.data.name,
                    material: res.data.type === 'file' ? '' : res.data.notes,
                    total_grade	: res.data.total_grade,
                    grade_boundary: res.data.grade_boundary,
                    schedule_time: res.data.schedule_time ? new Date(res.data.schedule_time) : null,
                    due_date: new Date(res.data.due_date),
                    status: res.data.status,
                })
                setDocType(res.data.type === 'file' ? true : false)
                setscheduleTimeEnable(res.data.schedule_time ? true : false)
            }
        }, (err) => {})
    }

    const updateData = (e) => {
        let allData = { ...data, 'type': docType ? 'file' : 'link', 'status': 'active' }
        let validData = clientSideValidation(e, allData)
        if(validData){
            fetchData(`update-material/${data.id}`, 'POST', validData, true, true, (res) => {
                if (res.status) {
                    pageReload('materialForm', data, '#materialModal')
                }
            }, (err) => {})
        }
    }

    const updateAsDraft = (e) => {
        let allData = { ...data, 'type': docType ? 'file' : 'link', 'status': 'draft' }
        let validData = clientSideValidation(e, allData)
        if(validData){
            fetchData(`update-material/${data.id}`, 'POST', validData, true, true, (res) => {
                if (res.status) {
                    pageReload('materialForm', data, '#materialModal')
                }
            }, (err) => {})
        }
    }

    const updatescheduleData = (e) => {
        setscheduleTimeEnable(true)
        let ele = $('#schedule_time').val()
        if(!ele){
            return false
        }
        let allData = { ...data, 'type': docType ? 'file' : 'link', 'status': 'schedule' }
        let validData = clientSideValidation(e, allData)
        if(validData){
            fetchData(`update-material/${data.id}`, 'POST', validData, true, true, (res) => {
                if (res.status) {
                    pageReload('materialForm', data, '#materialModal')
                }
            }, (err) => {})
        }
    }

    const pageReload = (formId, formData, modalId) => {
        initialFormState(formId, formData)
        setReload(reload+1)
        $(modalId).modal('hide');
    }
    const deleteMaterial = (id) => {
        fetchData(`delete-material/${id}`, 'POST', {'pathname': history.location.pathname}, true, false, (res) => {
            if (res.status) {
                setReload(reload+1)
            }
        }, (err) => {})
    }
    const getMaterial = (id) => {
        fetchData(`get-material/${id}`, 'POST', {'course_id': courseId, 'user_id': userId, 'pathname': history.location.pathname}, true, false, (res) => {
            if(res.status && res.message === ''){
                if(res.data.type === 'link'){
                    window.open(res.data.notes, '_blank');
                }else{
                    fetchData(`download-task/${id}`, 'GET', '', true, false, (file) => {
                        downloadFile(file, `${res.data.module_type}-${res.data.download_name}`)
                    }, (err) => {}, true);
                }
            }
        }, (err) => {});
    }
    const returnMaterial = (e) => {
        let allData = { ...data, 'course_id': courseId, 'module_type': materialType, 'status': 'return' }
        let validData = clientSideValidation(e, allData, 'submissionForm')
        if(validData){
            fetchData(`uplode-assignment/${materialId}`, 'POST', validData, true, true, (res) => {
                if (res.status) {
                    pageReload('submissionForm', data, '#submissionModal')
                }
            }, (err) => {})
        }
    }
    const getReturnMaterial = (id) => {
        openModal('submissionForm', 'return')
        setMaterialId(id)
        fetchData(`uplode-assignment/${id}`, 'GET', '', true, false, (res) => {
        if (res.status && res.data) {
            setData({
                pre_uploaded_task: res.data.submission,
                task_uploaded: true
            })
        }
        }, (err) => {})
    }

    const viewSubmission = (id) => {
        fetchData(`view-submission/${id}`, 'GET', '', true, false, (res) => {
            if(res.status){
                setSubmissionData(res.data)
            }
        }, (err) => {});
    }
    const viewMaterial = (id , view = false) => {
        openModal('submissionForm', 'mark')
        fetchData(`view-return-task/${id}`, 'GET', '', true, false, (res) => {
            if(res.status){
                if(view){
                    fetchData(`download-return-task/${id}`, 'GET', '', true, false, (file) => {
                        downloadFile(file,`${res.data.user_id}|${res.data.material_id}`)
                    }, (err) => {}, true);
                }else{
                    setData({
                        id: res.data.id,
                        marks: res.data.marks,
                    })
                }
            }
        }, (err) => {});
    }
    const updateMark = (e) => {
        let allData = { ...data, 'course_id': courseId, 'module': materialType, 'status': 'marked' }
        let validData = clientSideValidation(e, allData, 'submissionForm')
        if(validData){
            fetchData(`update-mark/${data.id}`, 'POST', validData, true, true, (res) => {
                if(res.status){
                    pageReload('submissionForm', data, '#submissionModal')
                }
            }, (err) => {})
        }
    }
    const removeMaterial = (closeModal) => {
        if(closeModal){
            setData(prevState => ({
                ...prevState,
                pre_uploaded_task: '',
                task_uploaded: false
            }))
        }else{
            let msg = {message: `Please upload ${materialType}.`,status:false}
            showAlert(msg)
        }
    }
    const launchClassOrRecording = (record, type = false) => {
        fetchData(`launch-class-or-recording/${record.class_id}`, 'post', {'user_id': userId, 'type': type, 'pathname': history.location.pathname}, true, false, (res) => {
            if (res.status && res.data === '') {
                // setReload(reload+1)
                roleId === 3 || roleId === 4 ? setClassId(record.class_id) : setClassId('')
                if(type){
                    window.open(record.recording);
                }else{
                    let zoomData = {
                        role: roleId === 3 || roleId === 4 ? 1 : 0,
                        meeting_id: decryptCode(record.meeting_id),
                        password: decryptCode(record.meeting_password),
                        api_key: record.api_key,
                        api_secret: record.api_secret,
                        username: context.auth.name,
                        useremail: context.auth.email
                    }
                    Zoom(zoomData, (res) => {
                        if(res){
                            fetchData(`class-launch-successfull/${record.class_id}`, 'GET', '', true, false, (res) => {}, (err) => {});
                        }
                    })
                }
            }else if(res.status){
                setPaymentData({
                    'join_type': res.data.join_type,
                    'pay_amount': res.data.pay_amount,
                    'type': res.data.type,
                    'meeting_id': record.meeting_id,
                    'password': record.meeting_password,
                    'api_key': record.api_key,
                    'api_secret': record.api_secret,
                    'class_id': res.data.class_id,
                    'recording': record.recording,
                })
                $("#paymentModal").modal('show');
            }else{
                setReload(reload+1)
            }
        }, (err) => {})
    }

    const doPayment = () => {
        let allData = { ...paymentData, 'user_id': userId, 'course_id': courseId, 'pathname': history.location.pathname}
        fetchData(`course-or-class-payment`, 'post', allData, true, false, (res) => {
            if (res.status) {
                setReload(reload+1)
                if(paymentData.type){
                    setTimeout(() => {
                        window.open(paymentData.recording);
                    }, 1500);
                }else{
                    setTimeout(() => {
                        let zoomData = {
                            role: roleId === 3 || roleId === 4 ? 1 : 0,
                            meeting_id: decryptCode(paymentData.meeting_id),
                            password: decryptCode(paymentData.password),
                            api_key: paymentData.api_key,
                            api_secret: paymentData.api_secret,
                            username: context.auth.name,
                            useremail: context.auth.email
                        }
                        Zoom(zoomData, (res) => {
                            if(res){
                                fetchData(`class-launch-successfull/${paymentData.class_id}`, 'GET', '', true, false, (res) => {}, (err) => {});
                            }
                        })
                        // window.open(paymentData.join_link, '_blank');
                    }, 1500);
                }
            }
        }, (err) => {})
        $("#paymentModal").modal('hide');        
        
    }
    const closeMeeting = () => {
        let over = zoomEnd(roleId)
        if(over){
            fetchData(`over-class/${classId}`, 'post', {'pathname': history.location.pathname}, true, false, (res) => {}, (err) => {})
        }
        setReload(reload+1)
    }

    const launchClassByZoomLink = (record) => {
        fetchData(`launch-class-or-recording/${record.class_id}`, 'post', {'user_id': userId, 'pathname': history.location.pathname}, true, false, (res) => {
            if (res.status && res.data === '') {
                    window.open(record.zoom_link);
            }else{
                setReload(reload+1)
            }
        }, (err) => {})
    }

    const returnMaterialByTeacher = (id, method, e = false) => {

        if(method === "POST"){
            let allData = { ...data, 'course_id': courseId, 'module_type': materialType }
            let validData = clientSideValidation(e, allData, 'submissionForm')
            if(validData){
                fetchData(`return-material-by-teacher/${id}`, 'POST', validData, true, true, (res) => {
                    if (res.status) {
                        pageReload('submissionForm', data, '#submissionModal')
                    }
                }, (err) => {})
            }
        }else{
            openModal('submissionForm', 'return')
            setMaterialId(id)
            fetchData(`return-material-by-teacher/${id}`, method, '', true, false, (res) => {
            if (res.status && res.data) {
                setData({
                    pre_return_task: res.data.return,
                    returned: true
                })
            }
            }, (err) => {})
        }   
    }
    
    const downloadReturnTask = (id) => {
        fetchData(`download-teacher-return-task/${id}`, 'GET', '', true, false, (res) => {
            downloadFile(res,`return|${materialType}`)
        }, (err) => {}, true); 
    }
     
    return (
        <>
        {context.auth ?
            <div className="card-body">
                <div className="card-body pb-0">
                    <div className="row mb-2">
                        <div className="col-sm-7">
                            <button type="button" className="btn btn-orange btn-sm" onClick={history.goBack} title='Back'><i className="bi-arrow-left-circle-fill bi-3x ms-auto"></i> Back</button>
                        </div>
                        <div className="col-sm-5 text-end">
                            { ((roleId === 4 || roleId === 3) && ['assignment', 'quizz', 'note', 'library'].includes(materialType)) ? <>
                                <button type="button" className="btn btn-blue btn-sm me-2 minw-120px" data-bs-toggle="modal" data-bs-target='#materialModal' onClick={() => openModal('materialForm', 'create')} title={`Create ${_.capitalize(materialType)}`}>Create {_.capitalize(materialType)}</button>
                                <button type="button" className="btn btn-warning btn-sm minw-120px" onClick={() => {setStatusType(statusType === 'active' ? 'draft' : 'active'); setCurrentPage(1)}} title={statusType === 'active' ? `Draft ${_.capitalize(materialType)}` : `${_.capitalize(materialType)} List`}>{statusType === 'active' ? `Draft ${_.capitalize(materialType)}` : `${_.capitalize(materialType)} List`}</button>
                            </> : ''}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table align-middle table-lasttd-end">
                            <thead className="bg-light">
                                <tr>
                                    { materialType === 'recording' ? <>
                                        <th>Date</th>
                                        <th>Recording Name</th>
                                        <th>Attended</th>
                                    </> : '' }

                                    { materialType === 'timing' ? <>
                                        <th>Class Day</th>
                                        <th>Class Timing</th>
                                    </> : '' }

                                    { ['note', 'library', 'performance', 'assignment', 'quizz'].includes(materialType) ? <>
                                        <th>Date</th>
                                        <th>Name</th>
                                        { ['assignment', 'quizz'].includes(materialType) ? <th>Due Date</th> : '' }
                                        { materialType === 'performance' ? <th>Type</th> : '' }
                                        <th>Status</th>
                                    </> : '' }

                                    { materialType === 'my-student' ? <>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Joining Date</th>
                                        <th>Withdrawal Date</th>
                                    </> : '' }
                                    { materialType === 'performance' ||  materialType === 'my-student' ? '' : <th className="text-center">Action</th> }
                                </tr>
                            </thead>
                            <tbody>
                                { materialList && materialList[0].data && !isEmpty(materialList[0].data) ? _.toArray(materialList[0].data).map((record, key) => {
                                    return (
                                        <>
                                        <tr key={key}>
                                            { materialType === 'recording'  ?
                                                    <>
                                                        <td>{record.date}</td>
                                                        <td>{record.name}</td>
                                                        <td>{record.attended}</td>
                                                        <td><button className={`btn btn-${record.course_payment || record.class_payment ? 'blue' : 'orange'} btn-sm px-4 minw-120px`} disabled={roleId === 5 ? true : false} onClick={() => launchClassOrRecording(record, 'recording')} title={record.course_payment || record.class_payment ? 'Watch' : 'Purchase'}>{record.course_payment || record.class_payment ? 'Watch' : 'Purchase'}</button></td>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                materialType === 'timing' ?
                                                    <>
                                                        <td>{record.day}</td>
                                                        <td>{record.start_class_time} - {record.end_class_time}</td>
                                                        <td className="text-center">
                                                            {roleId === 3 || roleId === 4 ?
                                                                <button className="btn btn-blue btn-sm px-4 minw-120px m-1" disabled={record.status === 'active' ? false : true} onClick={() => launchClassByZoomLink(record)} title='Get Class Link'>Get Class Link</button>
                                                            : <button className="btn btn-blue btn-sm px-4 minw-120px m-1" disabled={record.status === 'active' && roleId !== 5 ? false : true} onClick={() => launchClassOrRecording(record)} title='Launch'>Launch</button>}
                                                        </td>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                materialType === 'assignment' || materialType === 'quizz'?
                                                    <>
                                                        <td>{record.date}</td>
                                                        <td>{record.name}</td>
                                                        <td>{record.due_date}</td>
                                                        <td>{(roleId === 5 || roleId === 6) && record.status === 'Over' ? 'Missed' : _.startCase(_.replace(record.status, '-', ' '))}</td>
                                                        <td className='text-center d-xl-flex'>
                                                        { roleId === 3 || roleId === 4 ? <>
                                                            <button type="button" className="btn btn-dark btn-sm px-3 m-1" data-bs-toggle="modal" data-bs-target='#materialModal' onClick={() => editMaterial(record.id)} title='Edit'>Edit</button>
                                                            {statusType === 'draft' ? <button type="button" className="btn btn-danger btn-sm px-3 m-1" onClick={() => deleteMaterial(record.id)} title='Delete'>Delete</button> : 
                                                            <button type="button" className="m-1 btn btn-success btn-sm minw-120px" data-bs-toggle="modal" data-bs-target={`#viewModal`} onClick={() => viewSubmission(record.id)} title='Submission'>Submission</button>}
                                                        </> : '' }
                                                        { roleId === 5 || roleId === 6 ?
                                                        <>   <button type="button" className="btn btn-success btn-sm px-3 m-1" disabled={record.disabled} onClick={() => getMaterial(record.id)} title='Get'>Get</button>
                                                            {roleId === 6 ? <button type="button" className={`m-1 btn btn-${record.status === 'Editted-submission' || record.status === 'Submitted' ? 'dark' : 'danger'} btn-sm minw-120px`} disabled={record.disabled || record.status === 'Marked' ? true : false} data-bs-toggle="modal" data-bs-target='#submissionModal' onClick={() => getReturnMaterial(record.id)} title={record.status === 'Editted-submission' || record.status === 'Submitted' || record.status === 'Marked' ? 'Edit' : 'Return'}>{record.status === 'Editted-submission' || record.status === 'Submitted' || record.status === 'Marked' ? 'Edit' : 'Return'}</button> : '' }
                                                            <button type="button" className="m-1 btn btn-blue btn-sm px-3"  disabled={! record.return_task} onClick={() => downloadReturnTask(record.return_submisstion_id)} title='Returned'>Returned</button>
                                                        </>
                                                        : '' }
                                                        </td>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                materialType === 'note' || materialType === 'library' ?
                                                    <>
                                                        <td>{record.date}</td>
                                                        <td>{record.name}</td>
                                                        <td>{record.status}</td>
                                                        <td className='text-center'>
                                                            { roleId === 3 || roleId === 4 ? <>
                                                                <button type="button" className="btn btn-dark btn-sm px-3 me-2" data-bs-toggle="modal" data-bs-target='#materialModal' onClick={() => editMaterial(record.id)} title='Edit'>Edit</button>
                                                                {statusType === 'draft' ?  <button type="button" className="btn btn-danger btn-sm px-3 me-2" onClick={() => deleteMaterial(record.id)} title='Delete'>Delete</button> : 
                                                                <button type="button" className="btn btn-blue btn-sm minw-120px" data-bs-toggle="modal" data-bs-target={`#viewModal`} onClick={() => viewSubmission(record.id)} title='View'>View</button>}
                                                            </> : '' }
                                                            { roleId === 5 || roleId === 6 ?
                                                                <button type="button" className="btn btn-success btn-sm px-3 me-2" onClick={() => getMaterial(record.id)} title='Get'>Get</button>
                                                            : '' }
                                                        </td>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                materialType === 'performance' ?
                                                    <>
                                                        <td>{record.date}</td>
                                                        <td>{record.name}</td>
                                                        <td>{record.module_type}</td>
                                                        <td>
                                                            { roleId === 3 || roleId === 4 ? <button type="button" className="btn btn-blue btn-sm minw-120px" data-bs-toggle="modal" data-bs-target={`#viewModal`} onClick={() => viewSubmission(record.id)} title='View'>View</button> : 
                                                            <div className="ratio ratio-1x1 mmw-50px text-blue d-inline-block">
                                                                <svg viewBox="0 0 36 36">
                                                                    <path stroke-width="3" fill="none" stroke={record.percent === 0 ? '#f66969' : '#eee'} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                    <path stroke-linecap="round" fill="none" stroke={record.color} stroke-width="3" stroke-dasharray={`${record.percent}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                    <text x="18" y="21.5" className="percentage fw-medium" fill={record.color} font-size={record.percent === 0 ? '1rem' : '0.55rem'} font-family="" text-align="center" text-anchor="middle">{record.percent === 0 ? '!' : _.isInteger(record.percent) ? record.percent + '%' : record.percent.toFixed(2)+ '%'}</text>
                                                                </svg>
                                                            </div> }
                                                        </td>
                                                    </>
                                                    : ''
                                            }
                                            {
                                                materialType === 'my-student' ?
                                                    <>
                                                        <td>{record.name}</td>
                                                        <td>{record.email}</td>
                                                        <td>{record.date}</td>
                                                        <td>{record.withdrawDate}</td>
                                                    </>
                                                    : ''
                                            }


                                        </tr>
                                        </>
                                    )
                                }) : <tr><td colSpan={materialType === 'note' ? 4 : 5} className="text-danger text-center">No data available</td></tr> }
                            </tbody>
                        </table>
                            {materialType === 'timing' ? '' : <Pagination paginate_data={materialList ? materialList[0] : ''} side='end' onPageChange={handlePageClick}/> }
                    </div>
                </div>
                </div>
            : ''
        }


        {/** Materials Models */}
        <div className="modal fade" id="materialModal" tabIndex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{_.capitalize(opPerform)} {_.capitalize(materialType)}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                    </div>
                    <div className="modal-body">
                        <form className="row needs-validation" method="post" id="materialForm" noValidate>
                            <div className="row mx-0 mb-3">
                                <label htmlFor='name' className="col-sm-4 col-form-label">Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                <div className="col-sm-8 col-xxl-7">
                                    <input type="text" className="form-control" placeholder="Name" id='name' name='name' defaultValue={data && data.name ? data.name : ''} onChange={handleInputChange} required autoComplete='off' />
                                </div>
                            </div>
                            <div className="row mx-0 mb-2">
                                <label htmlFor='material' className="col-sm-4 col-form-label">File or Link<sup className='text-danger fw-bold fs-15px'>{opPerform === 'edit' && docType && data && data.material === '' ? '' : '*'}</sup></label>
                                <div className="col-sm-8 col-xxl-7">
                                    <label className="p-1 me-3"><input type="radio" checked={docType} onClick={() => setDocType(true)} /> File</label>
                                    <label><input type="radio" checked={!docType} onClick={() => setDocType(false)} /> Link</label>
                                </div>
                            </div>
                            <div className="row mx-0 mb-4">
                                <label className="col-sm-4 col-form-label"></label>
                                <div className="col-sm-8 col-xxl-7">
                                    <input type={docType ? 'file' : 'url'} className="form-control" defaultValue={data && data.material ? data.material  : ''} placeholder="Link" name='material' id='material' onChange={handleInputChange} required={opPerform === 'create' ? true : (data && data.material ? true : false)}  autoComplete='off' />
                                </div>
                            </div>
                            {materialType === 'quizz' || materialType === 'assignment' ? <>
                                <div className="row mx-0 mb-4">
                                    <label className="col-sm-4 col-form-label">Due Date<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <DatePicker
                                            selected={data && data.due_date ? data.due_date : new Date()}
                                            onChange={(e) => handleInputChange(e, 'due_date')}
                                            className="form-control"
                                            minDate={new Date()}
                                            timeInputLabel="Time:"
                                            showTimeSelect
                                            dateFormat="MMM dd, yyyy h:mm aa"
                                            onChangeRaw={(e) => e.preventDefault()}
                                            id="due_date"
                                            name="due_date"
                                        />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-4">
                                    <label htmlFor='total_grade' className="col-sm-4 col-form-label">Total Grade<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="text" className="form-control" placeholder="Total Grade" id='total_grade' name='total_grade' defaultValue={data && data.total_grade ? data.total_grade : ''} onChange={handleInputChange} required autoComplete='off' />
                                    </div>
                                </div>
                                <div className="row mx-0 mb-4">
                                    <label htmlFor='grade_boundary' className="col-sm-4 col-form-label">Grade Boundary<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <input type="text" className="form-control" placeholder="Grade Boundary" id='grade_boundary' name='grade_boundary' defaultValue={data && data.grade_boundary ? data.grade_boundary : ''} onChange={handleInputChange} required autoComplete='off' />
                                    </div>
                                </div>
                                </>
                            : ''}
                            {scheduleTimeEnable ? 
                                <div className="row mx-0 mb-4">
                                    <label className="col-sm-4 col-form-label">Schedule Time<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <DatePicker
                                            selected={data && data.schedule_time ? data.schedule_time : null}
                                            onChange={(e) => handleInputChange(e, 'schedule_time')}
                                            className="form-control"
                                            minDate={new Date()}
                                            timeInputLabel="Time:"
                                            showTimeSelect
                                            dateFormat="MMM dd, yyyy h:mm aa"
                                            name="schedule_time"
                                            id="schedule_time"
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                        <div className={opPerform === 'edit' ? 'd-none' : 'text-success'}>Please enter schedule date and time.</div>
                                    </div>
                                </div>
                            : ''}
                            <div className="row mx-0">
                                <div className="col-sm-8 offset-sm-4">
                                    {opPerform === 'create' ? <>
                                        <button type="button" className="btn btn-orange minw-120px me-2" onClick={saveData} title='Submit'>Submit</button>
                                        <button type="button" className="btn btn-blue minw-120px me-2" onClick={savescheduleData} title='Schedule'>Schedule</button>
                                        <button type="button" className="btn btn-warning minw-120px" onClick={saveAsDraft} title='Draft'>Draft</button>
                                    </> : <>
                                        <button type="button" className="btn btn-orange minw-120px me-2" onClick={updateData} title={data && data.status === 'draft' ? 'Publish' : 'Update'}>{data && data.status === 'draft' ? 'Publish' : 'Update'}</button>
                                        <button type="button" className="btn btn-blue minw-120px me-2" onClick={updatescheduleData} title='Schedule'>Schedule</button>
                                        {data && data.status === 'draft' ? <button type="button" className="btn btn-warning minw-120px" onClick={updateAsDraft} title='Draft'>Draft</button> : ''}
                                    </>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        {/** Submission Models */}
        <div className="modal fade" id='submissionModal' data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{_.capitalize(opPerform)} {_.capitalize(materialType)}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss={data && !data.task_uploaded && (roleId ===5 || roleId ===6) ? '' : 'modal'} aria-label="Close" onClick={() => data && !data.task_uploaded && (roleId ===5 || roleId ===6) ? removeMaterial(false) : ''} title='Close'></button>
                    </div>
                    <div className="modal-body py-5">
                        <form className="row needs-validation" method="post" id='submissionForm' noValidate>
                            <div className="row mx-0 mb-4">
                                <label className="col-md-4 col-form-label text-md-end">
                                    { (roleId === 3 || roleId === 4) && opPerform === 'mark' && 'Mark Update'}
                                    { (roleId === 3 || roleId === 4) && opPerform === 'return' && `Return ${_.capitalize(materialType)}`}
                                    { roleId === 6 && `Upload ${_.capitalize(materialType)}`}
                                    <sup className='text-danger fw-bold fs-15px'>*</sup>
                                </label>
                                <div className="col-md-8 col-xxl-7">

                                    { (roleId === 3 || roleId === 4) && opPerform === 'mark' && <input type='text' className="form-control" defaultValue={data && data.marks ? data.marks : ''} placeholder="Marks" name='marks' id='marks' onChange={handleInputChange} required />}

                                    { (roleId === 3 || roleId === 4) && opPerform === 'return' && <input type='file' className="form-control" name='upload_data' id='upload_data' onChange={handleInputChange} required /> }

                                    { roleId === 6 &&
                                    <> <input type='file' className="form-control" name='upload_data' id='upload_data' onChange={handleInputChange} required />
                                    {
                                        data && data.pre_uploaded_task ? 
                                        <div className='d-flex pt-2'> 
                                            <span className='text-success text-nowrap1 text-truncate'>{data.pre_uploaded_task}</span> 
                                            <button type='button' className='btn py-0 ms-2' title='Remove' onClick={() => removeMaterial(true)}>
                                                <i className="bi-x-lg ms-auto text-danger"></i>
                                            </button>
                                            
                                        </div>
                                        : 
                                        ''
                                    } </>}
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-sm-8 offset-sm-4">  
                                    { (roleId === 3 || roleId === 4) && opPerform === 'mark' && <button type="button" className="btn btn-blue minw-120px" onClick={updateMark} title='Submit'>Submit</button>}
                                    { (roleId === 3 || roleId === 4) && opPerform === 'return' && 
                                    <>
                                    {data && data.pre_return_task && <button type="button" className="btn btn-orange minw-120px me-1" onClick={() => downloadReturnTask(materialId)} title='Submit'>Download Return Task</button>}
                                    <button type="button" className="btn btn-blue minw-120px" onClick={() => returnMaterialByTeacher(materialId, 'POST')} title='Submit'>Submit</button>
                                    </>
                                    }
                                    { roleId === 6 && <button type="button" className="btn btn-blue minw-120px" onClick={returnMaterial} title='Submit'>Submit</button>}                                      
                                    {/* <button type="button" className="btn btn-blue minw-120px" onClick={roleId === 3 || roleId === 4 ? updateMark : returnMaterial} title='Submit'>Submit</button> */}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        {/** View Models */}
        <div className="modal fade" id='viewModal' tabIndex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{_.capitalize(materialType)} View</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive">
                            <table className="table align-middle table-lasttd-end mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th className="border-bottom">#ID</th>
                                        <th className="border-bottom">Name</th>
                                        <th className="border-bottom">Email</th>
                                        {materialType === 'quizz' || materialType === 'assignment' ? <>
                                            <th className="border-bottom">Status</th>
                                            <th className="border-bottom">Submission</th>
                                            <th className="border-bottom">Action</th>
                                        </> : ''}
                                        {materialType === 'performance' ? <>
                                            <th className="border-bottom">Status</th>
                                        </> : ''}
                                    </tr>
                                </thead>
                                <tbody>
                                    { submissionData && !_.isEmpty(submissionData) ? submissionData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td>{_.capitalize(item.name)}</td>
                                                <td>{item.email}</td>
                                                {materialType === 'quizz' || materialType === 'assignment' ? <>
                                                    <td>{_.startCase(_.replace(item.status, '-', ' '))}</td>
                                                    <td>{item.date}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-blue px-4" disabled={item.status === 'Pending' ? true : false} onClick={() => viewMaterial(item.id, 'view')} title='View'>View</button>
                                                        <button type="button" className="btn btn-orange px-4 mx-1" data-bs-toggle="modal" data-bs-target='#submissionModal' disabled={item.status === 'Pending' ? true : false} onClick={() => viewMaterial(item.id)} title='Mark'>Mark</button>
                                                        <button type="button" className="btn btn-success px-4" data-bs-toggle="modal" data-bs-target='#submissionModal' disabled={item.status === 'Pending' ? true : false} onClick={(e) => returnMaterialByTeacher(item.id, 'GET', e)} title='Return'>Return</button>
                                                    </td>
                                                </> : ''}
                                                {materialType === 'performance' ? 
                                                    <td>
                                                        <div className="ratio ratio-1x1 mmw-50px text-blue d-inline-block">
                                                            <svg viewBox="0 0 36 36">
                                                                <path stroke-width="3" fill="none" stroke={item.percent === 0 ? '#f66969' : '#eee'} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                <path stroke-linecap="round" fill="none" stroke={item.color} stroke-width="3" stroke-dasharray={`${item.percent}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                <text x="18" y="21.5" className="percentage fw-medium" fill={item.color} font-size={item.percent === 0 ? '1rem' : '0.55rem'} font-family="" text-align="center" text-anchor="middle">{item.percent === 0 ? '!' : _.isInteger(item.percent) ? item.percent + '%' : item.percent.toFixed(2)+ '%'}</text>
                                                            </svg>
                                                        </div>
                                                    </td>
                                                : ''}
                                            </tr>
                                        )
                                    }) : <tr><td colSpan="5" className="text-danger text-center">No data available</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                
        {/** Payment Confermation */}
        {paymentData ?
        <div className="modal fade" id="paymentModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-2">
                        <div className="row">
                            <div className="col-sm-12 text-center pb-2">
                                <p className="text-muted px-4 py-3 lh-base fs-6 fs-17px mb-1">Dear Student! <strong> EGP {paymentData.pay_amount} </strong> will be debited from your wallet. Do you want to <strong>{paymentData.type === 'recording' ? 'purchase' : 'attend'}</strong> the {paymentData.type === 'recording' ? 'recording?' : 'class?'} </p>  
                                <div className="col text-center pb-2"> 
                                </div>  
                                <button type="button" data-bs-dismiss="modal" className="btn btn-outline-orange me-2 minw-100px" title='NO'>No</button> 
                                <button type="button" onClick={doPayment} className="btn btn-blue ms-2 minw-100px" title='Yes'>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : ''}

        <div className="zm-modal">
            <button type="button" className="btn btn-danger" id="closeZoomBtn" onClick={closeMeeting} title='Close'>Close</button>
            <div id="meetingSDKElement">
                Zoom Meeting SDK Rendered Here
            </div>
        </div>
    </>
    )
}

export default MaterialDetails
