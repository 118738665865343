import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link} from "react-router-dom";
import Datatables, { initDataTable, reloadDataTable } from '../../components/Datatables';
import { fetchData} from "../../components/Helper";
import { useHistory} from 'react-router';
import { Helmet } from 'react-helmet';

function ScheduleList() {
    const history = useHistory();
    const [dt] = useState({
        dt_url: 'get-schedule',
        dt_name: 'schedule',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"event_name", name:'event_name', title:"Course/Event Name"},
            { data:"event_description", name:'event_description', title:"Course/Event Description"},
            { data:"schedule_time", name:'schedule_time', title:"Schedule Time"},
            { data: 'type', name: "courses.name",title:"Type" },
            { data:"status", name:'status',  title:"Status" }, 
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}

        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<> 
                           { rowData.event_name ? rowData.event_name : rowData.course_name}                    
                    </>, td)
                },  
            }, 
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<> 
                           { rowData.event_description ? rowData.event_description : rowData.course_description}                    
                    </>, td)
                },  
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<> 
                         { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                         { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    </>, td)
                },  
            }, 
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<> 
                           { rowData.event_name ? <span className="fw-medium">Event</span> : <span className="fw-medium">Course</span>}                    
                    </>, td)
                },  
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<> 
                        
                        { rowData.status === 'inactive' ? 
                        <button type="button" className="btn btn-success py-2px m-2px w-100px" title="Activate" onClick={ () => handleScheduleStatus('active', rowData.id)} >
                                Activate
                        </button>
                        : '' }

                        { rowData.status === 'active' ?
                        <button type="button" className="btn btn-danger py-2px m-2px w-100px" title="Deactivate" onClick={ () => handleScheduleStatus('inactive', rowData.id)} >
                            Deactivate
                        </button>
                        : '' } 

                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" onClick={() => editSchedule(rowData.id)}>
                            Edit
                        </button>

                        <button type="button" className="btn btn-danger py-2px m-2px" title="Delete" onClick={() => handleScheduleStatus('delete',rowData.id)}>
                            Delete
                        </button>
                        
                                                    
                    </>, td)
                },  
            },
        ]
    }) 

    const handleScheduleStatus = (status, id) => { 
        fetchData('schedule/change-status', 'POST', {'status':status,'id':id}, true, false, (res) => {  
           reloadDataTable(dt)
        }, (err) => {}) 
    }

    const editSchedule = (schedule_id) => {
        history.push(`/schedule/edit/${schedule_id}`)
    }

    useEffect(() => {
        initDataTable(dt)
    }, [dt])

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Schedule List</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Schedule List</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to="/schedule/create" className="btn btn-blue mb-1" title='Create Schedule'>Create Schedule</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                <Datatables dt_name="schedule"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </>
    )
}

export default ScheduleList
