import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData, validateForm, showAlert } from "../../components/Helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router';
import $ from 'jquery'
import _ from 'lodash';
import { Helmet } from "react-helmet";

function ScheduleCreate() {
    const history = useHistory()
    const [course, setCourse] = useState([]);
    const [teacher, setTeacher] = useState('')
    const [dateTime, setDateTime] = useState(new Date());
    const [courseId, setCourseId] = useState('')
    const [eventName, setEventName] = useState('')
    const [eventDescription, setEventDescription] = useState('')
    const [timing, setTiming] = useState('');
    const [timingday, setTimingday] = useState('');

    useEffect(() => {
        fetchData('get-courses', 'GET', '', true, false, (res) => {
            setCourse(res.data)
        }, (err) => { })
    }, []);

    $('#courseBtn').on('click', function () {
        $("#courseForm").addClass("needs-validation");
        $("#eventForm").removeClass("needs-validation");
    });

    $('#eventBtn').on('click', function () {
        $("#courseForm").removeClass("needs-validation");
        $("#eventForm").addClass("needs-validation");
    });


    const courseSubmitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            let schedule_data = {
                scheduleType: 'course',
                course_id: courseId,
                schedule_time: dateTime,
                timing_day: timingday,
            }
            saveData(schedule_data)
        }
    }

    const eventSubmitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            let schedule_data = {
                scheduleType: 'event',
                event_name: eventName,
                event_description: eventDescription,
                schedule_time: dateTime
            }
            saveData(schedule_data)
        }
    }

    const saveData = (schedule_data) => {
        console.log(schedule_data);
        fetchData('schedule-create', 'POST', schedule_data, true, false, (res) => {
            if (res.status) {
                history.push('list')
            }
        }, (err) => {})
    }

    const getCourseId = (e) => {
        let id = e.target.value;
        if (id) {
            fetchData(`get-teacher/${id}`, 'GET', '', true, false, (res) => {
                setTeacher(res.data.name)
            }, (err) => {})

            fetchData(`get-timetable_timing/${id}`, 'GET', '', true, false, (res) => {
                setTiming(res.data)
                if(res.data.length > 0){
                    let selected_day = `${res.data[0].slug}-${res.data[0].day}-${res.data[0].start_time}`
                    setTimingday(selected_day)
                }else{
                    let msg = {message:'No timing available for this course. Please select any other course.', status:false}
                    showAlert(msg)
                    setTimingday('')
                }
            }, (err) => {})

        } else {
            setTeacher('')
            setTiming('')
        }
        setCourseId(e.target.value)
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Add Schedule</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Add Schedule</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to="/schedule/list" className="btn btn-orange mb-1" title="Schedule List">Schedule List</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue active fw-medium" data-bs-toggle="tab" data-bs-target="#existing" type="button" role="tab" title='Existing Course'>Existing Course</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" data-bs-toggle="tab" data-bs-target="#event" type="button" role="tab" title='Event'>Event</button>
                                        </li>
                                    </ul>

                                    <div className="tab-content border border-top-0">
                                        <div className="tab-pane fade p-3 show active" id="existing" role="tabpanel">
                                            <form id="courseForm" noValidate>
                                                <div className="mb-4 row mx-0">
                                                    <input type="hidden" />
                                                    <label htmlFor="courselevel" className="col-sm-3 col-xxl-2 col-form-label">Course<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                        <select className="form-select" name="course_id" id="courselevel" value={courseId} onChange={getCourseId} required >
                                                            <option value="">--- Select Course---</option>
                                                            {course ? course.map(item => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            )) : ''}
                                                        </select>
                                                    </div>
                                                    <div className="error course_id-error small text-danger px-2 py-1 d-none"></div>
                                                </div>

                                                <div className="mb-4 row mx-0">
                                                    <label htmlFor="courselevel" className="col-sm-3 col-xxl-2 col-form-label">Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                        <input type="text" className="form-control" placeholder="Teacher Name" value={teacher ? teacher : ''} readOnly />
                                                    </div>

                                                </div>

                                                <div className="mb-4 row mx-0">
                                                    <label htmlFor="adminemail" className="col-sm-3 col-xxl-2 col-form-label" >Course Timing<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                        <select className="form-select" name="course_id" id="courselevel" value={timingday} onChange={(e) => setTimingday(e.target.value)} required>
                                                            {timing && timing.length > 0 ? timing.map((time_detail, i) => {
                                                                return (<option key={i} value={`${time_detail.slug}-${time_detail.day}-${time_detail.start_time}`}>{`${time_detail.name} - ${_.capitalize(time_detail.day)} - ${time_detail.start_time}`}</option>)
                                                                }) : <option value=''>-</option>
                                                            }
                                                        </select>
                                                        {/* <ul className="ps-18px mb-0 set_coursetiming">
                                                            {timing && timing.length > 0 ? timing.map((time_detail, i) => {
                                                                return (
                                                                    <li key={i}>{time_detail.start_time} To {time_detail.end_time}</li>
                                                                )
                                                            })
                                                                : 'No Timing'
                                                            }
                                                        </ul> */}
                                                    </div>
                                                </div>

                                                <div className="mb-4 row mx-0">
                                                    <label htmlFor="adminemail" className="col-sm-3 col-xxl-2 col-form-label">Event Timing<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                        <DatePicker
                                                            selected={dateTime}
                                                            onChange={(date) => setDateTime(date)}
                                                            className="form-control"
                                                            minDate={new Date()}
                                                            timeInputLabel="Time:"
                                                            dateFormat="MMM dd, yyyy"
                                                            onChangeRaw={(e) => e.preventDefault()}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mx-0">
                                                    <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                                        <button type="button" id="courseBtn" onClick={courseSubmitForm} className="btn btn-blue w-150px px-4 me-2" title="Create Schedule">Create Schedule</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="tab-pane fade p-3 show" id="event" role="tabpanel">
                                            <form id="eventForm" className="" noValidate>
                                                <div className="mb-4 row mx-0">
                                                    <label htmlFor="event_name" className="col-sm-3 col-xxl-2 col-form-label">Event Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                        <input type="text" className="form-control" id="event_name" name="event_name" onChange={e => setEventName(e.target.value)} value={eventName} required />
                                                    </div>
                                                    <div className="error event_name-error small text-danger px-2 py-1 d-none"></div>
                                                </div>
                                                <div className="mb-4 row mx-0">
                                                    <label htmlFor="event_description" className="col-sm-3 col-xxl-2 col-form-label">Event Description<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                        <textarea className="form-control" rows="4" name="event_description" onChange={e => setEventDescription(e.target.value)} value={eventDescription} required></textarea>
                                                    </div>
                                                    <div className="error event_description-error small text-danger px-2 py-1 d-none"></div>
                                                </div>

                                                <div className="mb-4 row mx-0">
                                                    <label htmlFor="adminemail" className="col-sm-3 col-xxl-2 col-form-label">Event Timing<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                        <DatePicker
                                                            selected={dateTime}
                                                            onChange={(date) => setDateTime(date)}
                                                            className="form-control"
                                                            minDate={new Date()}
                                                            timeInputLabel="Time:"
                                                            dateFormat="MMM dd, yyyy h:mm aa"
                                                            showTimeInput
                                                            onChangeRaw={(e) => e.preventDefault()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mx-0">
                                                    <div className="col-sm-10 offset-sm-3 offset-xxl-2">
                                                        <button type="button" id="eventBtn" onClick={eventSubmitForm} className="btn btn-blue w-150px px-4 me-2" title="Create Shedule">Create Shedule</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ScheduleCreate
